import React, { useState, useRef, useEffect, useContext } from 'react';
import { withRouter, useHistory, Link } from "react-router-dom";
import SimpleReactValidator from 'simple-react-validator';
import { GET_PROFILE_DETAILES, GET_PRICING, ORDER_PLEASE_CARD } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import { get, post } from '../../helpers/api_helper';
import StripeCheckout from 'react-stripe-checkout';
import { UserInfoContext } from "../../layouts/App_route";
import Loader from "../../component/Loader";

import Logo from './../../assets/images/logo.svg';

const BuyCards = props => {
    let history = useHistory();
    const { userSubscription, TotalCard, setTotalCard } = useContext(UserInfoContext);
    const [isLoader, setLoader] = useState(0);
    const [MaxCardBuy] = useState(25);

    const simpleValidator = useRef(new SimpleReactValidator());
    const [form_inputs, setInputs] = useState({
        name: '',
        email: '',
        phoneNumber: '',

        address1: '',
        address2: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',

        deliveryAddress1: '',
        deliveryAddress2: '',
        deliveryCity: '',
        deliveryState: '',
        deliveryCountry: '',
        deliveryZipCode: '',


        qty: '0',
        price: '0.0',
        amount: '0.0',
        shippingId: '',
        shippingCharge: '0.00',
        vat: '0.00',
        totalAmount: '0.00',
        vatPercentage: '0',

        planName: '',
        userCardInfo: []

    });
    const [, forceUpdate] = useState();
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [shippingTypeList, SetshippingTypeList] = useState([]);
    const [cardId, SetcardId] = useState(0);

    // const [FreeQty, SetFreeQty] = useState(0);

    useEffect(() => {
        async function get_detaile() {
            const params = new URLSearchParams(props.location.search)
            SetcardId(params.get('id'));
            
            if (userSubscription) {
                var res_price = await get(GET_PRICING, {});

                var res_data = await post(GET_PROFILE_DETAILES, {});
                if (res_data.status) {
                    form_inputs.name = res_data.data.detail.firstName + ' ' + res_data.data.detail.lastName;
                    form_inputs.email = res_data.data.detail.email;
                    var plan = userSubscription.code;

                    if (plan && TotalCard < MaxCardBuy) {

                        form_inputs.price = (res_price.data[plan].cardPrice) ? res_price.data[plan].cardPrice : 0;
                        form_inputs.planName = 'Card Price';
                        // var free_qty = ((res_price.data[plan].FreeCard - TotalCard) > 0) ? res_price.data[plan].FreeCard - TotalCard : 0;
                        // SetFreeQty(free_qty);
                        // form_inputs.qty = ((form_inputs.userCardInfo.length - free_qty) > 0) ? form_inputs.userCardInfo.length - free_qty : 0;
                        form_inputs.userCardInfo = [{'cardId':params.get('id'), 'firstName': params.get('first_name'), 'lastName': params.get('last_name') }];
                        form_inputs.qty = form_inputs.userCardInfo.length;
                        form_inputs.amount = (form_inputs.qty * form_inputs.price);

                        form_inputs.vatPercentage = res_price.data.vatPercentage;
                        form_inputs.shippingId = (res_price.data.shipping_type.length > 0) ? res_price.data.shipping_type[0].shippingId : '';
                        form_inputs.shippingCharge = (res_price.data.shipping_type.length > 0) ? res_price.data.shipping_type[0].charge : '';
                        form_inputs.vat = (((parseFloat(form_inputs.amount)) * parseFloat(form_inputs.vatPercentage)) / 100).toFixed(2);
                        form_inputs.totalAmount = (parseFloat(form_inputs.amount) + parseFloat(form_inputs.vat) + parseFloat(form_inputs.shippingCharge)).toFixed(2);

                    }
                    SetshippingTypeList(res_price.data.shipping_type)

                    setInputs(inputs => ({ ...form_inputs }));
                } else {
                    toast.error(res_data.message);
                }
                setLoader(1);
            } else {
                // history.push("/cards");
            }

        }
        get_detaile();
    }, [userSubscription])

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'shippingId') {
            for (var shippingInfo of shippingTypeList) {
                if (shippingInfo.shippingId == event.target.value) {
                    form_inputs.shippingCharge = shippingInfo.charge;
                }
            }

            form_inputs.vat = (((parseFloat(form_inputs.amount)) * parseFloat(form_inputs.vatPercentage)) / 100).toFixed(2);
            form_inputs.totalAmount = (parseFloat(form_inputs.amount) + parseFloat(form_inputs.vat) + parseFloat(form_inputs.shippingCharge)).toFixed(2);
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            window.$('.StripeCheckout').click();
            SetButtonDisabled(true);

        }
    }


    const handleToken = async (token) => {
        SetButtonDisabled(true);

        form_inputs.token = token
        const response = await post(ORDER_PLEASE_CARD, form_inputs);
        if (response.status) {
            toast(response.message);
            setTotalCard(response.data.total_card);
            history.push("/cards");
        } else {
            toast.error(response.message);
        }
        SetButtonDisabled(false);
    }

    //addRow
    const addRow = () => {
        var data = (form_inputs.userCardInfo) ? form_inputs.userCardInfo : [];
        data.push({cardId : '', firstName: '', lastName: '' });
        // var qty = ((data.length - FreeQty) > 0) ? data.length - FreeQty : 0;
        var qty = data.length;

        setInputs(inputs => ({ ...form_inputs, ['userCardInfo']: data }));
        setInputs(inputs => ({ ...form_inputs, ['qty']: qty }));
        form_inputs.amount = (qty * form_inputs.price);
        form_inputs.vat = (((parseFloat(form_inputs.amount) + parseFloat(form_inputs.shippingCharge)) * parseFloat(form_inputs.vatPercentage)) / 100).toFixed(2);
        form_inputs.totalAmount = (parseFloat(form_inputs.amount) + parseFloat(form_inputs.vat) + parseFloat(form_inputs.shippingCharge)).toFixed(2);

    }
    //handleInputChangeMultiple
    const handleInputChangeMultiple = (index, event) => {
        var name = event.target.name;
        var value = event.target.value;
        form_inputs.userCardInfo[index][name] = value;
        setInputs(inputs => ({ ...form_inputs, ['userCardInfo']: form_inputs.userCardInfo }));
    }
    const removeRow = (index) => {
        var data = form_inputs.userCardInfo;
        data.splice(index, 1);
        // var qty = ((data.length - FreeQty) > 0) ? data.length - FreeQty : 0;
        var qty = data.length;
        setInputs(inputs => ({ ...form_inputs, ['userCardInfo']: data }));
        setInputs(inputs => ({ ...form_inputs, ['qty']: qty }));
        form_inputs.amount = (qty * form_inputs.price);
        form_inputs.vat = (((parseFloat(form_inputs.amount)) * parseFloat(form_inputs.vatPercentage)) / 100).toFixed(2);
        form_inputs.totalAmount = (parseFloat(form_inputs.amount) + parseFloat(form_inputs.vat) + parseFloat(form_inputs.shippingCharge)).toFixed(2);

    }
    return (
        <>
            {!isLoader && <Loader />}
            <section className='section pricing'>
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-sm-6">
                            <h3 className="mb-0">Card Order</h3>
                        </div>
                        <div className="col-sm-6">
                            <p className="p-breadcrumb text-right mb-0 mt-2">
                                <Link className="text-sky" to="/dashboard">Dashboard</Link>
                                <i className="fa fa-angle-right mr-2 ml-2" aria-hidden="true"></i>
                                <Link className="text-sky" to="/cards">Cards</Link>
                                <i className="fa fa-angle-right mr-2 ml-2" aria-hidden="true"></i>
                                Card Order
                            </p>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="row mb-5">

                            <div className='col-md-12 col-lg-7 mb-3'>
                                <div className='bg_white p-4'>
                                    <div className='row'>
                                        <div className='col-sm-6 mb-3'>
                                            <h5>User Card Details</h5>
                                        </div>
                                        <div className='col-sm-6 mb-3 text-right'>

                                            {((form_inputs.userCardInfo.length + TotalCard) < MaxCardBuy && !cardId) && <button type='button' onClick={addRow.bind(this)} className='btn btn-secondary btn-block1 btn-sm'>+ Add User</button>}
                                        </div>
                                        <div className='col-sm-12 mb-3'>
                                            {form_inputs.userCardInfo && Object.entries(form_inputs.userCardInfo).map(([key, card]) => (
                                                <div className='row mb-3' key={key}>
                                                    <div className='col-sm-5'>
                                                        <input type='text' name="firstName" onChange={handleInputChangeMultiple.bind(this, key)} value={card.firstName} placeholder='First Name' className="form-control" />
                                                        {simpleValidator.current.message('first name', card.firstName, 'required')}
                                                    </div>
                                                    <div className='col-sm-5'>
                                                        <input type='text' name="lastName" onChange={handleInputChangeMultiple.bind(this, key)} value={card.lastName} placeholder='Last Name' className="form-control" />
                                                        {simpleValidator.current.message('last name', card.lastName, 'required')}
                                                    </div>
                                                    <div className='col-sm-2'>
                                                        {key > 0 && <button type="button" onClick={removeRow.bind(this, key)} className="btn btn-sm btn-secondary mt-2"><i className="fa fa-trash-o text-white" aria-hidden="true"></i></button>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        <div className='col-sm-12 mb-3'>
                                            <h5>Contact Information</h5>
                                        </div>
                                        <hr />
                                        <div className='col-sm-12'>
                                            <div className='form-group mb-2'>
                                                <label>Full name</label>
                                                <input type='text' name="name" value={form_inputs.name} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('name', form_inputs.name, 'required')}
                                            </div>
                                            <div className='form-group mb-2'>
                                                <label>Email</label>
                                                <input type='text' name="email" value={form_inputs.email} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('email', form_inputs.email, 'required|email')}
                                            </div>
                                            <div className='form-group mb-2'>
                                                <label>Phone number</label>
                                                <input type='text' name="phoneNumber" value={form_inputs.phoneNumber} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('phone number', form_inputs.phoneNumber, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-sm-12 mb-3 mt-4'>
                                            <h5>Billing Details</h5>
                                        </div>
                                        <hr />
                                        <div className='col-sm-6'>
                                            <div className='form-group mb-2'>
                                                <label>Address line 1</label>
                                                <input type='text' name="address1" value={form_inputs.address1} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('address', form_inputs.address1, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group mb-2'>
                                                <label>Address line 2</label>
                                                <input type='text' name="address2" value={form_inputs.address2} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('address', form_inputs.address2, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group mb-2'>
                                                <label>City</label>
                                                <input type='text' name="city" value={form_inputs.city} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('city', form_inputs.city, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group mb-2'>
                                                <label>State</label>
                                                <input type='text' name="state" value={form_inputs.state} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('state', form_inputs.state, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group mb-2'>
                                                <label>Country</label>
                                                <input type='text' name="country" value={form_inputs.country} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('country', form_inputs.country, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group mb-2'>
                                                <label>Zip Code</label>
                                                <input type='text' name="zipCode" value={form_inputs.zipCode} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('zip code', form_inputs.zipCode, 'required')}
                                            </div>
                                        </div>

                                        <div className='col-sm-12 mb-3 mt-4'>
                                            <h5>Delivery Addresses</h5>
                                        </div>
                                        <hr />
                                        <div className='col-sm-6'>
                                            <div className='form-group mb-2'>
                                                <label>Address line 1</label>
                                                <input type='text' name="deliveryAddress1" value={form_inputs.deliveryAddress1} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('address', form_inputs.deliveryAddress1, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group mb-2'>
                                                <label>Address line 2</label>
                                                <input type='text' name="deliveryAddress2" value={form_inputs.deliveryAddress2} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('address', form_inputs.deliveryAddress2, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group mb-2'>
                                                <label>City</label>
                                                <input type='text' name="deliveryCity" value={form_inputs.deliveryCity} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('city', form_inputs.deliveryCity, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group mb-2'>
                                                <label>State</label>
                                                <input type='text' name="deliveryState" value={form_inputs.deliveryState} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('state', form_inputs.deliveryState, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group mb-2'>
                                                <label>Country</label>
                                                <input type='text' name="deliveryCountry" value={form_inputs.deliveryCountry} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('country', form_inputs.deliveryCountry, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group mb-2'>
                                                <label>Zip Code</label>
                                                <input type='text' name="deliveryZipCode" value={form_inputs.deliveryZipCode} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('zip code', form_inputs.deliveryZipCode, 'required')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-12 col-lg-5 mb-3'>
                                <div className="bg_white p-4">
                                    <div>
                                        <h4 className='text-light-blue'>Summary</h4>
                                    </div>
                                    <div className="mt-4">
                                        <div className='row'>
                                            <div className='col-8'>
                                                <p className='mb-2'>{form_inputs.planName} ({form_inputs.qty} Qty * £{form_inputs.price})</p>
                                                <p className='mb-0'><small>One Time Payment</small></p>
                                            </div>
                                            <div className='col-4 text-right'>£{form_inputs.amount}</div>
                                        </div>
                                        <hr className='' />
                                        <div className='row'>
                                            <div className='col-8'><p><b>Sub Total</b></p></div>
                                            <div className='col-4 text-right'><b>£{form_inputs.amount}</b></div>
                                        </div>
                                        <div className='row mb-3'>
                                            <div className='col-8'>
                                                <p className='mb-2'><b>Shipping  Type</b></p>
                                                <select className='form-control pull-left' name='shippingId' onChange={handleInputChange} value={form_inputs.shippingId}>
                                                    {shippingTypeList && Object.entries(shippingTypeList).map(([key, shipping]) => (<>
                                                        <option key={key} value={shipping.shippingId}>{shipping.name}</option>
                                                    </>))}
                                                </select>
                                            </div>
                                            <div className='col-4 text-right pt-4'><b>£{form_inputs.shippingCharge}</b></div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-8'><p><b>VAT</b></p></div>
                                            <div className='col-4 text-right'><b>£{form_inputs.vat}</b></div>
                                        </div>
                                        <hr className='' />
                                        <div className='row'>
                                            <div className='col-8'><p><b>Total Amount</b></p></div>
                                            <div className='col-4 text-right'><b>£{form_inputs.totalAmount}</b></div>
                                        </div>
                                    </div>
                                    <div className='text-center mt-4'>
                                        {TotalCard < MaxCardBuy && <button disabled={ButtonDisabled} className="c-btn-light mb-3" >{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin mr-1"></i>} Place Order </button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section >

            <StripeCheckout
                className="btn btn-success StripeCheckout d-none"
                image={Logo}
                stripeKey={process.env.REACT_APP_STRIPE_KEY}
                token={handleToken}
                email={form_inputs.email}
                currency={process.env.REACT_APP_CURRENCY}
                amount={parseFloat(form_inputs.totalAmount) * 100}
                name={"TAP Review"}
                billingAddress={false}
                alipay={false}
            />
        </>
    );
}
export default withRouter(BuyCards)