import React, { useState, useEffect } from 'react';
import { useHistory, Link, withRouter } from "react-router-dom";

import { post } from '../../helpers/api_helper';
import { GET_All_REVIEWS, POST_REVIEWS_DETAIL, POST_REVIEWS_REPLY } from '../../helpers/url_helper';
import { toast } from 'react-toastify';

import Loader from "../../component/Loader";

const Review = props => {
    let history = useHistory();
    const [isLoader, setLoader] = useState(0);

    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [FilterData, SetFilterData] = useState({ limit: 20, page: 0, search: '' });
    const [ShowLoadMore, seShowLoadMore] = useState(false);
    const [ReviewList, setReviewList] = useState([]);
    const [ReviewInfo, SetReviewInfo] = useState({});
    const [DetailLoader, SetDetailLoader] = useState(0);
    const [IsReplay, SetIsReplay] = useState(false);

    useEffect(() => {
        async function get_detaile() {
            await get_revies();
            setLoader(1);
        }
        get_detaile();
    }, [])

    const get_revies = async (load_more = 0) => {
        SetButtonDisabled(true);
        FilterData.page = (load_more > 0) ? FilterData.page : 0;
        var res_data = await post(GET_All_REVIEWS, FilterData);
        if (res_data.status) {
            if (load_more > 0) {
                setReviewList([...ReviewList, ...res_data.data.review_list]);
            } else {
                setReviewList(res_data.data.review_list);
            }
            seShowLoadMore(res_data.data.load_more);
            SetFilterData(inputs => ({ ...FilterData, ['page']: res_data.data.page }));
            SetButtonDisabled(false);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    // remove user role
    const get_info = async (id) => {
        SetDetailLoader(1)
        var res_data = await post(POST_REVIEWS_DETAIL, { reviewId: id });
        if (res_data.status) {
            SetReviewInfo(res_data.data.review_detail);
        } else {
            toast.error(res_data.message);
        }
        SetDetailLoader(0)
    }

    const handleInputChange = (event) => {
        event.persist();
        SetReviewInfo(inputs => ({ ...ReviewInfo, [event.target.name]: event.target.value }));
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        SetButtonDisabled(true);
        var res_data = await post(POST_REVIEWS_REPLY, ReviewInfo, {});
        if (res_data.status) {
            toast(res_data.message);
            window.$("#exampleModal").modal("hide");
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    const load_more = async () => {
        get_revies(1);
    }

    return (
        <>
            {!isLoader && <Loader />}
            <section className='section'>
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-sm-6">
                            <h3 className="mb-0">Reviews</h3>
                        </div>
                        <div className="col-sm-6 text-right">
                            <Link className="btn c-btn-blue" to="/dashboard"><i className="fa fa-long-arrow-left mr-2 text-white" aria-hidden="true"></i> Back To Dashboard</Link>

                            {/* <p className="p-breadcrumb text-right mb-0 mt-2">
                                <Link className="text-sky" to="/dashboard">Dashboard</Link>
                                <i className="fa fa-angle-right mr-2 ml-2" aria-hidden="true"></i>
                                Reviews
                            </p> */}
                        </div>
                    </div>

                    <div className="row ">
                        <div className='col-sm-12 col-lg-12 mb-4' style={{ 'minHeight': '30vh' }}>
                            <div className='table-responsive'>
                                <table className='table table-striped review'>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Rating</th>
                                            <th>Review</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ReviewList.length > 0 && ReviewList.map((value, index) => {
                                            return (<tr key={index}>
                                                <td>{value.date}</td>
                                                <td>{value.name}</td>
                                                <td>{value.email}</td>
                                                <td>{value.rating}</td>
                                                <td>{value.review}</td>
                                                <td>
                                                    <button onClick={() => { get_info(value.reviewId); SetIsReplay(false); }} data-toggle="modal" data-target="#exampleModal" type='button' className='btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1'><i className="fa fa-eye text-white" aria-hidden="true"></i></button>
                                                    <button className="btn btn-sm btn-info pt-0 pb-0 pl-1 pr-1 ml-2 mr-2" onClick={() => { get_info(value.reviewId); SetIsReplay(true); }} data-toggle="modal" data-target="#exampleModal" ><i className="fa fa-reply text-white" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                                                </td>
                                            </tr>)
                                        })}
                                        {ReviewList.length <= 0 && <tr>
                                            <td colSpan={6} className='align-middle'>
                                                <p className='mb-0 text-center'>No any review</p>
                                            </td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                            {ShowLoadMore && <div className='row'>
                                <div className="col-sm-12 text-center mt-3">
                                    <button onClick={load_more} disabled={ButtonDisabled} className="btn btn-primary btn-sm load_more_btn pl-4 pr-4">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>}Load More...</button>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </section >

            {/* <!-- Modal --> */}
            <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Review Detail</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {DetailLoader > 0 && <div role="status" className="ms-2 spinner-border text-dark">
                                <span className="sr-only">Loading...</span>
                            </div>}
                            {!DetailLoader && <>
                                <form onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className='col-sm-4 mb-2'>
                                            <h6 className='mb-0'>Date</h6>
                                            <p className='mb-2'>{ReviewInfo.date}</p>
                                        </div>
                                        <div className='col-sm-5 mb-2'>
                                            <h6 className='mb-0'>Name</h6>
                                            <p className='mb-2'>{ReviewInfo.name}</p>
                                        </div>
                                        <div className='col-sm-3 mb-2'>
                                            <h6 className='mb-0'>Rating</h6>
                                            <p className='mb-2'>{ReviewInfo.rating}</p>
                                        </div>
                                        <div className='col-sm-3 mb-2'>
                                            <h6 className='mb-0'>Email</h6>
                                            <p className='mb-2'>{ReviewInfo.email}</p>
                                        </div>
                                        <div className='col-sm-12 mb-2'>
                                            <h6 className='mb-0'>Review</h6>
                                            <p className='mb-2' style={{ 'whiteSpace': 'pre-line' }}>{ReviewInfo.review}</p>
                                        </div>
                                        {!IsReplay && <div className='col-sm-12 mb-2'>
                                            <h6 className='mb-0'>Reply</h6>
                                            <p className='mb-2' style={{ 'whiteSpace': 'pre-line' }}>{ReviewInfo.reply}</p>
                                        </div>}
                                        {IsReplay && <div className='col-sm-12 mb-2'>
                                            <div className='form-group'>
                                                <label className='w-100'><b>Reply</b></label>
                                                <textarea rows={5} name="reply" value={ReviewInfo.reply} onChange={handleInputChange} className="form-control" />
                                            </div>
                                            <div className='col-sm-12 text-center'>
                                                <button disabled={ButtonDisabled} className="c-btn-light btn-md pt-2 pb-2 pl-3 pr-3 radius-10 mb-3">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin mr-1"></i>} Save</button>
                                            </div>
                                        </div>}
                                    </div>
                                </form>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(Review)

