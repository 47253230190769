import React, { useState, useEffect, useRef } from 'react';
import { useHistory, withRouter, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { post } from '../helpers/api_helper';
import { img_view } from '../helpers/Helper';
import { POST_GET_PROFILE, POST_SEND_INQUIRY, GET_REVIEW } from '../helpers/url_helper';
import SimpleReactValidator from 'simple-react-validator';
import ReCAPTCHA from "react-google-recaptcha";

import Logo from '../assets/images/logo.svg';
import Facebook_Icon from '../assets/images/Facebook.png';
import Instagram_Icon from '../assets/images/Instagram.png';
import Twitter_Icon from '../assets/images/Twitter.png';
import Linkedin_Icon from '../assets/images/Linkedin.svg';
import Loader from "../component/Loader";

import ImageGallery from "react-image-gallery";
import ComingSoonImage from './../assets/images/coming_soon.png';
 
const Services = props => {
    let history = useHistory();

    const [UserInfo, SetUserInfo] = useState({});
    const [InquiryForm, SetInquiryForm] = useState({ profileId: 0, full_name: '', phone: '', email: '', message: '', captchaValid: '' });
    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [InquirySendSuccess, SetInquirySendSuccess] = useState(false);

    const [ReviewList, setReviewList] = useState([]);
    const [TotalReview, setTotalReview] = useState(0);
    const [isLoader, setLoader] = useState(false);

    const [faqsOpen, setFaqsOpen] = useState();
    const [GallaryImage, setGallaryImage] = useState([]);

    const [scroll, setScroll] = useState(false)

    useEffect(() => {
        async function get_detaile() {
            window.addEventListener("scroll", () => {
                setScroll(window.scrollY > 100)
            })

            var res_data = await post(POST_GET_PROFILE, { 'slug': props.match.params.slug });
            if (res_data.status) {
                SetUserInfo(res_data.data.user_info);
                SetInquiryForm({ profileId: res_data.data.user_info.profileId });

                var image_array = [];
                for (var image of res_data.data.user_info.gallary_image) {
                    image_array.push({
                        original: img_view(image.image_url),
                        thumbnail: img_view(image.image_url)
                    })
                }
                setGallaryImage(image_array);

                var res_data = await post(GET_REVIEW, { 'profileId': res_data.data.user_info.profileId, limit: 3 });
                if (res_data.status) {
                    setReviewList(res_data.data.review_list);
                    setTotalReview(res_data.data.total_review);
                }
                setLoader(true);
            } else {
                toast.error(res_data.message);
                history.push("/");
            }
        }
        get_detaile();
    }, [props.match.params.slug])

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        SetInquiryForm(inputs => ({ ...InquiryForm, [event.target.name]: event.target.value }));
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_SEND_INQUIRY, InquiryForm, {});
            if (res_data.status) {
                SetInquiryForm({ profileId: UserInfo.profileId, full_name: '', phone: '', email: '', message: '', captchaValid: '' });
                SetInquirySendSuccess(true);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    const handleClickScroll = (id, event) => {
        event.preventDefault()
        const element = document.getElementById(id);
        if (element) {

            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setTimeout(() => {
                window.scrollBy({
                    top: -50,
                    left: 0,
                    behavior: "smooth"
                });
            }, 500);
        }
    };
    const faqOpn = (index) => {
        setFaqsOpen(index);
    };

    function onChangeRecaptcha(value) {
        SetInquiryForm(inputs => ({ ...InquiryForm, ['captchaValid']: true }));
    }

    return (
        <>
            {!isLoader && <Loader />}
            {(isLoader && (!UserInfo.subscription_detail || !UserInfo.subscription_detail.isWebsiteAccess)) && <div className='section'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-sm-12 text-center" style={{ 'height': '100vh', 'paddingTop': '32vh' }}>
                            <h2 className='text-black mb-4'>Your subscription has expired.</h2>
                            <p>What now?</p>
                            <p>Renew now to continue website.</p>
                            <Link to="/pricing" className="c-btn-dark mt-4">Go Premium <i className="fa fa-long-arrow-right ml-2" aria-hidden="true"></i></Link>
                        </div>
                    </div>
                </div>
            </div>}
            {(isLoader && UserInfo.subscription_detail && UserInfo.subscription_detail.isWebsiteAccess) && <>

                <header className={`miniwebsite-header ${scroll ? "fix-header" : ""}`} >
                    <div className="heade">
                        <div className="container">
                            <nav className="navbar navbar-expand-lg navbar-light p-0">
                                <button className="navbar-toggler navbar-toggler-right collapsed mr-3" type="button" data-toggle="collapse"
                                    data-target="#Navigation" aria-controls="Navigation" aria-expanded="false"
                                    aria-label="Toggle navigation"><i className="fa fa-bars" aria-hidden="true"></i></button>
                                <div className='mobile-logo'>
                                    <Link className="navbar-brand" to={`/${UserInfo.url_slug}`}>
                                        {!UserInfo.logo && <img src={Logo} alt="Tap Review" className='logo' />}
                                        {UserInfo.logo && <img src={img_view(UserInfo.logo)} alt="Tap Review" className='logo' />}
                                    </Link>
                                </div>
                                <div className="form-inline my-2 my-lg-0">
                                    <a href={`/${UserInfo.url_slug}/review`} target="_blank" className='mobile-btn c-btn-blue pt-2 pb-2 radius-5 pointer btn-block pr-3 pl-3 btn-sm'>Leave a Review</a>
                                </div>
                                <div className="collapse navbar-collapse" id="Navigation">
                                    <ul className="navbar-nav pt-3 pt-lg-0 ml-auto">
                                        {(UserInfo.services && UserInfo.services?.length > 0) && <li className={`nav-item`}><a href="#" onClick={handleClickScroll.bind(this, 'Services')} className="text-black">Services</a></li>}
                                        {ReviewList.length > 0 && <li className={`nav-item`}><a href="#" onClick={handleClickScroll.bind(this, 'Reviews')} className="text-black">Reviews</a></li>}
                                        {(UserInfo.faq && UserInfo.faq?.length > 0) && <li className={`nav-item`}><a href="#" onClick={handleClickScroll.bind(this, 'FAQS')} className="text-black">FAQ's</a></li>}
                                        {UserInfo.ShowContactForm > 0 && <li className={`nav-item`}><a href="#" onClick={handleClickScroll.bind(this, 'Contact')} className="text-black">Contact Us</a></li>}
                                        <li><a href={`/${UserInfo.url_slug}/review`} target="_blank" className='c-btn-blue'>Leave a Review</a></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </header>

                <section className='section pb-0' id="Home">
                    <div className='container'>
                        <div className='row'>
                            <div className="col-sm-6">
                                <h2 className='text-black font-w-700'>{UserInfo.headline}</h2>
                            </div>
                            <div className="col-sm-6 text-sm-right">
                                <a href={`/${UserInfo.url_slug}/review`} target="_blank" className='btn c-btn-blue-outline mt-sm-3'>Leave a Review</a>
                            </div>
                            <div className="col-sm-12 mt-2">
                                {UserInfo.aboutUsImage && <img src={img_view(UserInfo.aboutUsImage)} className='w-100' style={{'borderRadius' : '20px'}} />}
                            </div>
                            <div className="col-lg-9">
                                <p className='mt-3 text-justify' style={{ whiteSpace: "pre-wrap" }}>{UserInfo.aboutUs}</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Review */}
                {ReviewList.length > 0 && <section className='section review pb-0' id="Reviews">
                    <div className="container">
                        <div className='row'>
                            <div className='col-6 col-sm-6 mb-5'>
                                <h3 className='font-w-700'>Reviews</h3>
                            </div>
                            <div className='col-6 col-sm-6 mb-5 text-right'>
                                {TotalReview > 2 && <a href={`/${UserInfo.url_slug}/all-review`} target="_blank" className='btn c-btn-blue-outline'>View all</a>}
                            </div>
                        </div>
                        <div className='row justify-content-md-center'>
                            <div className='col-md-12 col-lg-3 mb-3'>
                                <div className='row'>
                                    <div className='col-7'>
                                        <div>
                                            <h4 className='pull-left font-w-700'><b>{UserInfo.average_rating}</b></h4>
                                            <h6 className='pull-left mt-3 ml-2 font-w-400'> / 5 star</h6>
                                        </div>
                                    </div>
                                    <div className='col-5 text-right pt-2 mb-0'>
                                        <p>{TotalReview} Reviews</p>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                </div>
                                {UserInfo.all_over_review && Object.entries(UserInfo.all_over_review).map(([key, review]) => (
                                    <div className='row mb-1' key={key}>
                                        <div className='col-sm-12'>
                                            <div className='progress-line'>
                                                <div className='title'>
                                                    {review.star} <i className='fa fa-star' style={{ 'color': '#F7B405' }}></i>
                                                </div>
                                                <div class="progress" style={{ 'height': '12px' }}>
                                                    <div class="progress-bar bg-warning" role="progressbar" style={{ 'width': `${review.pre}%` }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                                <div className='count'><b>{review.review_count}</b></div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {ReviewList && Object.entries(ReviewList).map(([key, review]) => (
                                <>{key < 3 && <div className='col-md-12 col-lg-3 mb-3' key={key}>
                                    <div className='review_box'>
                                        <div className="d-flex align-items-center mb-2">
                                            <div className="">
                                                <h5 className="mb-0">{review.name}</h5>
                                            </div>
                                        </div>
                                        <div className='star mb-2'>
                                            {[...Array(review.rating)].map((x, i) =>
                                                <i className="fa fa-star mr-2" style={{ 'fontSize': '20px', 'color': 'rgb(255, 215, 0)' }} aria-hidden="true"></i>
                                            )}
                                            {[...Array(5 - review.rating)].map((x, i) =>
                                                <i className="fa fa-star mr-2" style={{ 'fontSize': '20px', 'color': '#808080' }} aria-hidden="true"></i>
                                            )}

                                            {/* <small className='pull-right'>{review.date}</small> */}
                                        </div>
                                        <div>
                                            <p style={{ whiteSpace: "pre-wrap", 'fontSize': '14px' }}>{review.review}</p>
                                        </div>
                                    </div>
                                </div>}</>
                            ))}
                        </div>
                    </div>
                </section>}

                {(UserInfo.services && UserInfo.services?.length > 0) && <section className='section' id="Services">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <h3 className='text-black font-w-700'>Our Services</h3>
                            </div>
                        </div>
                        <div className="row mt-3 justify-content-md-center">
                            {UserInfo.services && Object.entries(UserInfo.services).map(([key, services]) => (
                                <div className='col-md-12 col-lg-4 mb-4' key={key}>
                                    <div className='services_box'>
                                        <div className="d-flex align-items-center mb-2">
                                            <h5 className="mb-0 text-blue font-w-700" style={{'fontSize' : '24px !important'}}>{services.title}</h5>
                                        </div>
                                        <div>
                                            <p style={{ whiteSpace: "pre-wrap" }}>{services.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>}

                {/* FAQ */}
                {(UserInfo.faq && UserInfo.faq?.length > 0) && <section className='section gb_blue' id="FAQS">
                    <div className="container">
                        <div className='row mb-5'>
                            <div className='col-sm-12 text-center'>
                                <h3 className='text-white font-w-700'>Questions we get asked a lot.</h3>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-2'></div>
                            <div className='col-sm-8'>
                                {UserInfo.faq && Object.entries(UserInfo.faq).map(([key, faq]) => (
                                    <div className='faqs mb-3' key={++key}>
                                        <div className={"faq " + (faqsOpen === key ? "open" : "")}>
                                            <div className='faq-question' onClick={faqOpn.bind(this, key)}><h5 className='text-white'>{faq.question}</h5></div>
                                            <div className='faq-answer'>
                                                <p className='text-white'>{faq.answer}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </section>}


                {(UserInfo.accreditation_logos && UserInfo.accreditation_logos.length > 0) && <section className='section pb-0'>
                    <div className='container'>
                        <div className='row mb-4'>
                            <div className='col-sm-12'>
                                <h3 className='font-w-700'>Marketing Accreditations</h3>
                            </div>
                        </div>
                        <div className='row justify-content-md-center1'>
                            {Object.entries(UserInfo.accreditation_logos).map(([key, image]) => (
                                <div className='col-6 col-md-4 col-lg-2 mb-3 text-center align-self-center' key={key}>
                                    <img src={img_view(image.image_url)} alt='logo' style={{ 'maxWidth': '100%' }} />
                                </div>
                            ))}
                        </div>
                    </div>
                </section>}

                {GallaryImage.length > 0 && <section className='section  gallery'>
                    <div className='container'>
                        <div className='row mb-3'>
                            <div className='col-sm-12'>
                                <h3 className='mb-3 font-w-700'>Our Gallery</h3>
                            </div>
                        </div>
                        <div className='row'>
                            {/* <ImageGallery items={GallaryImage} /> */}
                            {Object.entries(GallaryImage).map(([key, image]) => (
                                <div className='col-6 col-sm-6 col-lg-4 mb-3' key={key}>
                                    <div className='img_box'>
                                        <img src={image.original} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>}

                {/* Get in touch */}
                {UserInfo.ShowContactForm > 0 && <section className='section contact-us pt-0' id="Contact">
                    <div className="container">
                        <div className='row mb-3'>
                            <div className='col-sm-12'>
                                <h3 className='mb-3 font-w-700'>Contact Information</h3>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-6'>
                                <div className='row'>
                                    {UserInfo.email &&
                                        <div className='col-sm-6 contact_box mb-3'>
                                            <div className='icon'>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="#202044" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="#202044" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                            <div className='text'>
                                                <p className='mb-0'><b>Email Us</b></p>
                                                <a href={`mailto:${UserInfo.email}`} className='mr-2 text-black'><small>{UserInfo.email}</small></a> <br />
                                            </div>

                                        </div>}
                                    {UserInfo.showFooterAddress <= 0 &&
                                        <div className='col-sm-6 contact_box mb-3'>
                                            <div className='icon'>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.0009 13.4299C13.724 13.4299 15.1209 12.0331 15.1209 10.3099C15.1209 8.58681 13.724 7.18994 12.0009 7.18994C10.2777 7.18994 8.88086 8.58681 8.88086 10.3099C8.88086 12.0331 10.2777 13.4299 12.0009 13.4299Z" stroke="#202044" stroke-width="1.5" />
                                                    <path d="M3.61971 8.49C5.58971 -0.169998 18.4197 -0.159997 20.3797 8.5C21.5297 13.58 18.3697 17.88 15.5997 20.54C13.5897 22.48 10.4097 22.48 8.38971 20.54C5.62971 17.88 2.46971 13.57 3.61971 8.49Z" stroke="#202044" stroke-width="1.5" />
                                                </svg>
                                            </div>
                                            <div className='text'>
                                                <p className='mb-0'><b>Location</b></p>
                                                {UserInfo.addressLine1 && <p className='mb-0' style={{ whiteSpace: "pre-wrap" }} ><small>{UserInfo.addressLine1}</small></p>}
                                                {UserInfo.addressLine2 && <p className='mb-0' style={{ whiteSpace: "pre-wrap" }} ><small>{UserInfo.addressLine2}</small></p>}
                                                {(UserInfo.town || UserInfo.country || UserInfo.postCode) && <p className='mb-0'><span className='mr-2'><small>{UserInfo.town}</small></span> <span className='mr-2'><small>{UserInfo.country}</small></span> <span className='mr-2'><small>{UserInfo.postCode}</small></span></p>}
                                            </div>
                                        </div>}
                                    {UserInfo.showMobileNumber <= 0 &&
                                        <div className='col-sm-6 contact_box mb-3'>
                                            <div className='icon'>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z" stroke="#202044" stroke-width="1.5" stroke-miterlimit="10" />
                                                </svg>
                                            </div>
                                            <div className='text'>
                                                <p className='mb-0'><b>Call Us</b></p>
                                                <a href={`tel:${UserInfo.telephoneNumber}`} className='text-black mr-3'><small>{UserInfo.telephoneNumber}</small></a>
                                            </div>

                                        </div>}
                                    <div className='col-sm-6 contact_box mb-3'>
                                        <div className='icon'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#202044" stroke-width="1.5" />
                                                <path d="M12 8V12L14.5 14.5" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                        <div className='text'>
                                            <p className='mb-0'><b>Opening Hours</b></p>
                                            {(UserInfo.openingHours && UserInfo.openingHours.length > 0) && <>
                                                {Object.entries(UserInfo.openingHours).map(([key, time]) => (
                                                    <>{time.isOpen && <p key={key} className='mb-0' style={{ 'whiteSpace': 'nowrap' }}> <small><span style={{ "minWidth": "57px", "display": "inline-block" }}>{time.day.replace('day', '')}</span>&nbsp; {time.openTime} - {time.closeTime}</small></p>}</>
                                                ))}
                                            </>}
                                        </div>
                                    </div>

                                    <div className='col-sm-6 mb-3 mt-3'>
                                        {UserInfo.facebookLink && <a href={UserInfo.facebookLink} target='_blank' className='mr-3 c-btn-blue-outline btn'>
                                            <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 1H9C7.67392 1 6.40215 1.52678 5.46447 2.46447C4.52678 3.40215 4 4.67392 4 6V9H1V13H4V21H8V13H11L12 9H8V6C8 5.73478 8.10536 5.48043 8.29289 5.29289C8.48043 5.10536 8.73478 5 9 5H12V1Z" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </a>}
                                        {UserInfo.linkedinLink && <a href={UserInfo.linkedinLink} target='_blank' className='mr-3 c-btn-blue-outline btn'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16 8C17.5913 8 19.1174 8.63214 20.2426 9.75736C21.3679 10.8826 22 12.4087 22 14V21H18V14C18 13.4696 17.7893 12.9609 17.4142 12.5858C17.0391 12.2107 16.5304 12 16 12C15.4696 12 14.9609 12.2107 14.5858 12.5858C14.2107 12.9609 14 13.4696 14 14V21H10L10 14C10 12.4087 10.6321 10.8826 11.7574 9.75736C12.8826 8.63214 14.4087 8 16 8V8Z" stroke="#232F61" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M6 9H2L2 21H6L6 9Z" stroke="#232F61" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6Z" stroke="#232F61" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </a>}
                                        {UserInfo.instagramLink && <a href={UserInfo.instagramLink} target='_blank' className='mr-3 c-btn-blue-outline btn'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M15.9997 11.3698C16.1231 12.2021 15.981 13.052 15.5935 13.7988C15.206 14.5456 14.5929 15.1512 13.8413 15.5295C13.0898 15.9077 12.2382 16.0394 11.4075 15.9057C10.5768 15.7721 9.80947 15.3799 9.21455 14.785C8.61962 14.1901 8.22744 13.4227 8.09377 12.592C7.96011 11.7614 8.09177 10.9097 8.47003 10.1582C8.84829 9.40667 9.45389 8.79355 10.2007 8.40605C10.9475 8.01856 11.7975 7.8764 12.6297 7.99981C13.4786 8.1257 14.2646 8.52128 14.8714 9.12812C15.4782 9.73496 15.8738 10.5209 15.9997 11.3698Z" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M17.5 6.5H17.51" stroke="#202044" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </a>}
                                        {UserInfo.twitterLink && <a href={UserInfo.twitterLink} target='_blank' className='mr-3 c-btn-blue-outline btn'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M22 4.00002C22 4.00002 21.3 6.10002 20 7.40002C21.6 17.4 10.6 24.7 2 19C4.2 19.1 6.4 18.4 8 17C3 15.5 0.5 9.60002 3 5.00002C5.2 7.60002 8.6 9.10002 12 9.00002C11.1 4.80002 16 2.40002 19 5.20002C20.1 5.20002 22 4.00002 22 4.00002Z" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </a>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-6 bg_white rounded p-4'>
                                {!InquirySendSuccess && <form onSubmit={handleSubmit} >
                                    <div className='row'>
                                        <div className='col-sm-12 mb-2'>
                                            <div className='form-group'>
                                                <label>Full Name</label>
                                                <input type='text' name="full_name" value={InquiryForm.full_name} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('name', InquiryForm.full_name, 'required')}
                                            </div>
                                            <div className='form-group'>
                                                <label>Phone</label>
                                                <input type='text' name="phone" value={InquiryForm.phone} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('phone number', InquiryForm.phone, 'required')}
                                            </div>
                                            <div className='form-group'>
                                                <label>Email</label>
                                                <input type='text' name="email" value={InquiryForm.email} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('email', InquiryForm.email, 'required|email')}
                                            </div>
                                            <div className='form-group'>
                                                <label>Message</label>
                                                <textarea rows={5} name="message" value={InquiryForm.message} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('message', InquiryForm.message, 'required')}
                                            </div>
                                            <div className='form-group'>
                                                <ReCAPTCHA
                                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                    onChange={onChangeRecaptcha}
                                                />
                                                {simpleValidator.current.message('captcha', InquiryForm.captchaValid, 'required')}
                                            </div>
                                            <div className='form-group mt-3'>
                                                <button disabled={ButtonDisabled} className="c-btn-blue pt-2 pb-2 radius-10 btn-block pr-5 pl-5">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Send Message </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>}
                                {InquirySendSuccess && <div className='text-center pt-5 pb-5'>
                                    <svg width="50px" xmlns="http://www.w3.org/2000/svg" class="mb-4 h-10 w-10 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path style={{ 'color': '#fff' }} stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    <h4 className='mb-3 text-white'>Thank you!</h4>
                                    <p className='mb-0 text-white'>Your message has been successfully sent.</p>
                                    <p className='text-white'> We will contact you very soon!</p>
                                </div>}
                            </div>
                        </div>
                    </div>
                </section >}

                <footer className="footer" style={{ 'backgroundColor': '#fff' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-lg-4">
                                <div className="mb-3">
                                    {UserInfo.logo && <img src={img_view(UserInfo.logo)} alt="Tap Review" className='logo mb-3' />}
                                    {UserInfo.showFooterAddress <= 0 && <div className='mb-2'>
                                        {UserInfo.addressLine1 && <p className='mb-0' style={{ whiteSpace: "pre-wrap" }} >{UserInfo.addressLine1}</p>}
                                        {UserInfo.addressLine2 && <p className='mb-0' style={{ whiteSpace: "pre-wrap" }} >{UserInfo.addressLine2}</p>}
                                        {(UserInfo.town || UserInfo.country || UserInfo.postCode) && <p className='mb-0'><span className='mr-2'>{UserInfo.town}</span> <span className='mr-2'>{UserInfo.country}</span> <span className='mr-2'>{UserInfo.postCode}</span></p>}
                                    </div>}
                                    <div className='mt-4 mb-4'>
                                        {UserInfo.facebookLink && <a href={UserInfo.facebookLink} target='_blank' className='mr-4'>
                                            <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 1H9C7.67392 1 6.40215 1.52678 5.46447 2.46447C4.52678 3.40215 4 4.67392 4 6V9H1V13H4V21H8V13H11L12 9H8V6C8 5.73478 8.10536 5.48043 8.29289 5.29289C8.48043 5.10536 8.73478 5 9 5H12V1Z" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </a>}
                                        {UserInfo.linkedinLink && <a href={UserInfo.linkedinLink} target='_blank' className='mr-4'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16 8C17.5913 8 19.1174 8.63214 20.2426 9.75736C21.3679 10.8826 22 12.4087 22 14V21H18V14C18 13.4696 17.7893 12.9609 17.4142 12.5858C17.0391 12.2107 16.5304 12 16 12C15.4696 12 14.9609 12.2107 14.5858 12.5858C14.2107 12.9609 14 13.4696 14 14V21H10L10 14C10 12.4087 10.6321 10.8826 11.7574 9.75736C12.8826 8.63214 14.4087 8 16 8V8Z" stroke="#232F61" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M6 9H2L2 21H6L6 9Z" stroke="#232F61" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6Z" stroke="#232F61" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </a>}
                                        {UserInfo.instagramLink && <a href={UserInfo.instagramLink} target='_blank' className='mr-4'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M15.9997 11.3698C16.1231 12.2021 15.981 13.052 15.5935 13.7988C15.206 14.5456 14.5929 15.1512 13.8413 15.5295C13.0898 15.9077 12.2382 16.0394 11.4075 15.9057C10.5768 15.7721 9.80947 15.3799 9.21455 14.785C8.61962 14.1901 8.22744 13.4227 8.09377 12.592C7.96011 11.7614 8.09177 10.9097 8.47003 10.1582C8.84829 9.40667 9.45389 8.79355 10.2007 8.40605C10.9475 8.01856 11.7975 7.8764 12.6297 7.99981C13.4786 8.1257 14.2646 8.52128 14.8714 9.12812C15.4782 9.73496 15.8738 10.5209 15.9997 11.3698Z" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M17.5 6.5H17.51" stroke="#202044" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </a>}
                                        {UserInfo.twitterLink && <a href={UserInfo.twitterLink} target='_blank' className='mr-4'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M22 4.00002C22 4.00002 21.3 6.10002 20 7.40002C21.6 17.4 10.6 24.7 2 19C4.2 19.1 6.4 18.4 8 17C3 15.5 0.5 9.60002 3 5.00002C5.2 7.60002 8.6 9.10002 12 9.00002C11.1 4.80002 16 2.40002 19 5.20002C20.1 5.20002 22 4.00002 22 4.00002Z" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </a>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-5">
                                <div className="row">
                                    <div className="col-5">
                                        <h5>Company</h5>
                                        <ul className="footer-link">
                                            <li><a href="#" onClick={handleClickScroll.bind(this, 'Services')}>Services</a></li>
                                            <li><a href="#" onClick={handleClickScroll.bind(this, 'Reviews')}>Reviews</a></li>
                                        </ul>
                                    </div>
                                    <div className="col-7">
                                        <h5>Company</h5>
                                        <ul className="footer-link">
                                            <li><a href="#" onClick={handleClickScroll.bind(this, 'Home')}>About</a></li>
                                            <li><a href="#" onClick={handleClickScroll.bind(this, 'FAQS')}>FAQ's</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-4">
                                {/* {(UserInfo.openingHours && UserInfo.openingHours.length > 0) && <>
                                    {Object.entries(UserInfo.openingHours).map(([key, time]) => (
                                        <p key={key} className='mb-0'><span style={{ "minWidth": "100px", "display": "inline-block" }}>{time.day}</span> &nbsp; &nbsp; {time.openTime} - {time.closeTime}</p>
                                    ))}
                                </>} */}
                            </div>

                        </div>
                    </div>
                </footer>
            </>}
        </>
    );
}
export default withRouter(Services)
