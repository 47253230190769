import React, { useState, useContext, useRef, useEffect } from 'react';
import { withRouter } from "react-router-dom";

import { post, del } from '../../../helpers/api_helper';
import { img_view } from '../../../helpers/Helper';
import { GET_BUSINESS_PROFILE_DETAILES, POST_SAVE_BUSINESS_PROFILE, DELETE_GALLARY_IMAGE } from '../../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { UserInfoContext } from "../../../layouts/App_route";
import Loader from "../../../component/Loader";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';



const Gallery = props => {
    let history = useHistory();

    const { userSubscription } = useContext(UserInfoContext);
    const [isLoader, setLoader] = useState(0);

    const simpleValidator = useRef(new SimpleReactValidator());
    const [form_inputs, setInputs] = useState({
        services: []
    });
    const [, forceUpdate] = useState();
    const [ButtonDisabled, SetButtonDisabled] = useState(false);

    useEffect(() => {
        async function get_detaile() {
            await get_profile_info();
        }
        get_detaile();
    }, [])

    const get_profile_info = async () => {
        var res_data = await post(GET_BUSINESS_PROFILE_DETAILES, {});
        if (res_data.status) {
            setInputs(res_data.data.detail);
            setLoader(1);
        } else {
            toast.error(res_data.message);
        }
    }
    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            form_inputs.type = 'gallery'
            var res_data = await post(POST_SAVE_BUSINESS_PROFILE, form_inputs, {});
            if (res_data.status) {
                // await get_profile_info();
                toast(res_data.message);
                history.push("/dashboard");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'gallary') {
            var value = event.target.files[0];
            var file_extension = event.target.files[0].name.split('.').pop();
            var file_size = event.target.files[0].size;
            var file_size = (file_size / 1024 / 1024).toFixed(1);

            var extension = ['jpg', 'jpeg', 'png'];
            if (!extension.includes(file_extension.toLowerCase())) {
                toast.error('Alludu only file jpg , jpeg and png.');
                event.target.value = null;
                return false;
            }

            if (file_size > 1) {
                toast.error('Max File size Allowed 1Mb.');
                event.target.value = null;
                return false;
            }

            var file = event.target.files[0];
            var reader = new FileReader();
            reader.onload = function () {
                var gallary_image = form_inputs.gallary_image
                gallary_image.push({ id: 0, image: reader.result, image_url: '' })
                setInputs(inputs => ({ ...form_inputs, ['gallary_image']: gallary_image }));
            }
            reader.readAsDataURL(file);
        } else if (event.target.name === 'logo') {
            var value = event.target.files[0];
            var file_extension = event.target.files[0].name.split('.').pop();
            var file_size = event.target.files[0].size;
            var file_size = (file_size / 1024 / 1024).toFixed(1);

            var extension = ['jpg', 'jpeg', 'png'];
            if (!extension.includes(file_extension.toLowerCase())) {
                toast.error('Alludu only file jpg , jpeg and png.');
                event.target.value = null;
                return false;
            }

            if (file_size > 1) {
                toast.error('Max File size Allowed 1Mb.');
                event.target.value = null;
                return false;
            }

            var file = event.target.files[0];
            var reader = new FileReader();
            reader.onload = function () {
                var accreditation_logos = form_inputs.accreditation_logos
                accreditation_logos.push({ id: 0, image: reader.result, image_url: '' })
                setInputs(inputs => ({ ...form_inputs, ['accreditation_logos']: accreditation_logos }));
            }
            reader.readAsDataURL(file);
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

   
    const deleteImage = async (index, data, type) => {
        if (data.id > 0) {
            // window.$('#DeleteImageModal').modal({ backdrop: 'static', keyboard: false });
            SetButtonDisabled(true);
            var res_data = await del(DELETE_GALLARY_IMAGE + '?id=' + data.id);
            if (res_data.status) {
                toast(res_data.message);
                if (type == 'logo') {
                    var img_array = form_inputs.accreditation_logos;
                    img_array.splice(index, 1);
                    setInputs(inputs => ({ ...form_inputs, ['accreditation_logos']: img_array }));
                } else {
                    var img_array = form_inputs.gallary_image;
                    img_array.splice(index, 1);
                    setInputs(inputs => ({ ...form_inputs, ['gallary_image']: img_array }));
                }
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        } else {
            if (type == 'logo') {
                var img_array = form_inputs.accreditation_logos;
                img_array.splice(index, 1);
                setInputs(inputs => ({ ...form_inputs, ['accreditation_logos']: img_array }));
            } else {
                var img_array = form_inputs.gallary_image;
                img_array.splice(index, 1);
                setInputs(inputs => ({ ...form_inputs, ['gallary_image']: img_array }));
            }
        }
    }

    return (
        <>
            {!isLoader && <Loader />}
            <div className='row mb-4'>
                <div className="col-sm-8">
                    <h4 className="mb-0">Gallery</h4>
                </div>
                <div className="col-sm-4 text-sm-right">
                    <p className='mb-0 pt-2'>Current Plan: <b>{(userSubscription && userSubscription.shortName)? userSubscription.shortName : 'Free'}</b></p>
                </div>
            </div>
            <form onSubmit={handleSubmit}>

                <div className='row'>
                    <div className='col-sm-12'>
                        <h5><b>Accreditation Logos</b></h5>
                    </div>
                    <div className='col-sm-12'>
                        <div className='Image_man_box no-border'>
                            {form_inputs.accreditation_logos && form_inputs.accreditation_logos.map((value, index) => {
                                return (<div className='img_box' key={index}>
                                    {value.id > 0 && <img src={img_view(value.image_url, 170, 170)} style={{ 'maxWidth': '100%' }} />}
                                    {value.id <= 0 && <img src={value.image} style={{ 'maxWidth': '100%' }} />}
                                    <span onClick={deleteImage.bind(this, index, value, 'logo')}><i className="fa fa-trash-o" aria-hidden="true"></i></span>
                                </div>);
                            })}
                            {form_inputs.accreditation_logos && form_inputs.accreditation_logos.length < 5 &&  <label htmlFor="myfile" className='img_box add'>
                                <div className='text-center'>
                                    <input id='myfile' name='logo' type="file" onChange={handleInputChange} className='d-none' />

                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.5 10C10.6046 10 11.5 9.10457 11.5 8C11.5 6.89543 10.6046 6 9.5 6C8.39543 6 7.5 6.89543 7.5 8C7.5 9.10457 8.39543 10 9.5 10Z" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22H15.5C20.5 22 22.5 20 22.5 15V10" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M18.5 8V2L20.5 4" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M18.5 2L16.5 4" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M3.16992 18.9501L8.09992 15.6401C8.88992 15.1101 10.0299 15.1701 10.7399 15.7801L11.0699 16.0701C11.8499 16.7401 13.1099 16.7401 13.8899 16.0701L18.0499 12.5001C18.8299 11.8301 20.0899 11.8301 20.8699 12.5001L22.4999 13.9001" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>

                                    <p className='text-center mt-2'><small>Upload Image<br /> (Jpg,Jpeg,Png)</small></p>
                                </div>
                            </label>}
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-sm-12'>
                        <h5><b>Business Gallery</b></h5>
                    </div>
                    <div className='col-sm-12'>
                        <div className='Image_man_box no-border'>
                            {form_inputs.gallary_image && form_inputs.gallary_image.map((value, index) => {
                                return (<div className='img_box' key={index}>
                                    {value.id > 0 && <img src={img_view(value.image_url, 170, 170)} style={{ 'maxWidth': '100%' }} />}
                                    {value.id <= 0 && <img src={value.image} style={{ 'maxWidth': '100%' }} />}
                                    <span onClick={deleteImage.bind(this, index, value, 'gallary')}><i className="fa fa-trash-o" aria-hidden="true"></i></span>
                                </div>);
                            })}
                            {form_inputs.gallary_image && form_inputs.gallary_image.length < 10 && <label htmlFor="gallaryfile" className='img_box add'>
                                <div className='text-center'>
                                    <input id='gallaryfile' name='gallary' type="file" onChange={handleInputChange} className='d-none' />
                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.5 10C10.6046 10 11.5 9.10457 11.5 8C11.5 6.89543 10.6046 6 9.5 6C8.39543 6 7.5 6.89543 7.5 8C7.5 9.10457 8.39543 10 9.5 10Z" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22H15.5C20.5 22 22.5 20 22.5 15V10" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M18.5 8V2L20.5 4" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M18.5 2L16.5 4" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M3.16992 18.9501L8.09992 15.6401C8.88992 15.1101 10.0299 15.1701 10.7399 15.7801L11.0699 16.0701C11.8499 16.7401 13.1099 16.7401 13.8899 16.0701L18.0499 12.5001C18.8299 11.8301 20.0899 11.8301 20.8699 12.5001L22.4999 13.9001" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <p className='text-center'><small>Upload Image <br /> (Jpg,Jpeg,Png)</small></p>
                                </div>
                            </label>}
                        </div>
                    </div>
                </div>

                <div className='row mt-5'>
                    <div className='col-sm-6'>
                        <button type='button' onClick={() => { history.push("/dashboard"); }} disabled={ButtonDisabled} className="c-btn-blue-outline btn-md pt-2 pb-2 pl-3 pr-3 radius-10 mb-3 c-btn-block"><i className="fa fa-long-arrow-left mr-2" aria-hidden="true"></i>  Back To Dashboard </button>
                        {/* <button type='button' onClick={() => { props.call_back(4) }} disabled={ButtonDisabled} className="c-btn-blue-outline btn-md pt-2 pb-2 pl-3 pr-3 radius-10 mb-3 c-btn-block"><i className="fa fa-long-arrow-left mr-2 text-white" aria-hidden="true"></i> Back </button> */}
                    </div>
                    <div className='col-sm-6 text-right'>
                        <button type='submit' disabled={ButtonDisabled} className="c-btn-blue btn-md pt-2 pb-2 pl-3 pr-3 radius-10 mb-3 c-btn-block">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin mr-1"></i>} Done <i className="fa fa-long-arrow-right ml-2 text-white" aria-hidden="true"></i></button>
                    </div>
                </div>
            </form >
        </>
    );
}
export default withRouter(Gallery)

