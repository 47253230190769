import React from 'react';
import { withRouter } from "react-router-dom";

import Card from '../assets/images/about_card.png';

const About = props => {
    return (
        <>
            <section className="section gb_blue pt-0">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-md-12 col-lg-6  align-self-center">
                            <h2 className='mb-4 mt-4 text-white'>What is Tap Review?</h2>
                            <p className='text-white'>Tap Review is your ultimate business companion, designed to revolutionize the way you gather and showcase customer feedback. In the bustling UK market, where reputation matters, Tap Review empowers you to effortlessly collect authentic reviews through a simple tap on your customer’s phone.</p>
                            <p className='text-white'>Our ingenious Tap Card lets you harness the magic of tapping to seamlessly gather reviews, boosting your credibility and online presence. But that’s not all! Tap Review goes beyond reviews. With our intuitive Mini Website feature, you can create a stunning digital showcase. Highlight your services, flaunt your portfolio, set pricing, and even manage leads – all in one place. </p>
                            <p className='text-white'>Join a community of thriving UK businesses who’ve harnessed the power of Tap Review to stand out, connect, and conquer. Your brand’s voice deserves to be heard loud and clear – and Tap Review is the megaphone that gets you noticed. Ignite your business success today with Tap Review! </p>
                            <p className='text-white'>Tap Review: Your Reviews, Your Success, Your Way.</p>
                        </div>
                        <div className="col-md-12 col-lg-6 d-none d-lg-block align-self-center text-center">
                            <img src={Card} alt='card' style={{ "width": "100%" }} />
                        </div>
                    </div>
                    <div className='row pt-5 mb-5'>
                        <div className='col-sm-12'>
                            <iframe width="100%" height="500px" style={{ 'borderRadius': '30px' }} src="https://www.youtube.com/embed/86N8OEgMEg0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}
export default withRouter(About)