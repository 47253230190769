import React, { useState, useEffect } from 'react';
import { useHistory, Link, withRouter } from "react-router-dom";

import { post } from '../../helpers/api_helper';
import { GET_All_SUBSCRIPTION,DOWNLOAD_INVOICE } from '../../helpers/url_helper';
import { toast } from 'react-toastify';

import ProfileSettings from '../../component/ProfileSettings';
import Logo from './../../assets/images/logo.svg';
import Loader from "../../component/Loader";


const SubscriptionProfile = props => {
    let history = useHistory();
    const [isLoader, setLoader] = useState(0);
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [FilterData, SetFilterData] = useState({ limit: 20, page: 0, search: '' });
    const [ShowLoadMore, seShowLoadMore] = useState(false);
    const [Subscription, setSubscription] = useState([]);
    const [SubscriptionInfo, SetSubscriptionInfo] = useState({});

    useEffect(() => {
        async function get_detaile() {
            await get_subscription();
            setLoader(1);
        }
        get_detaile();
    }, [])

    const get_subscription = async (load_more = 0) => {
        SetButtonDisabled(true);
        FilterData.page = (load_more > 0) ? FilterData.page : 0;
        var res_data = await post(GET_All_SUBSCRIPTION, FilterData);
        if (res_data.status) {
            if (load_more > 0) {
                setSubscription([...Subscription, ...res_data.data.subscription_list]);
            } else {
                setSubscription(res_data.data.subscription_list);
            }
            seShowLoadMore(res_data.data.load_more);
            SetFilterData(inputs => ({ ...FilterData, ['page']: res_data.data.page }));
            SetButtonDisabled(false);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    const load_more = async () => {
        get_subscription(1);
    }

    return (
        <>
            {!isLoader && <Loader />}
            <ProfileSettings>
                <div className="row mb-3">
                    <div className="col-sm-6">
                        <h5 className="mb-0">subscription</h5>
                    </div>
                    <div className="col-sm-6 text-sm-right">
                        {Subscription.length > 0 && <Link to="/renew-subscription" className="c-btn-light radius-10 pr-3 pl-3 btn-sm">Renew</Link>}
                    </div>
                </div>

                <div className="row ">
                    <div className='col-sm-12 col-lg-12 mb-4' style={{ 'minHeight': '30vh' }}>
                        <div className='table-responsive'>
                            <table className='table table-striped'>
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Date</th>
                                        <th>Description</th>
                                        <th>Satrt Date</th>
                                        <th>End Date</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Subscription.length > 0 && Subscription.map((value, index) => {
                                        return (<tr key={index}>
                                            <td>{value.subscriptionId}</td>
                                            <td>{value.orderDate}</td>
                                            <td>{value.name}</td>
                                            <td>{value.startDate}</td>
                                            <td>{value.endDate}</td>
                                            <td>
                                                {value.status == 'Active' && <span className='border-0 badge badge-success pt-1 pb-1'>{value.status}</span>}
                                                {value.status == 'Expired' && <span className='border-0 badge badge-danger pt-1 pb-1'>{value.status}</span>}
                                            </td>
                                            <td>
                                                <button onClick={() => { SetSubscriptionInfo(value) }} data-toggle="modal" data-target="#exampleModal" type='button' className='btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1'><i className="fa fa-eye text-white" aria-hidden="true"></i></button>
                                                <a href={DOWNLOAD_INVOICE+"?id="+value.subscriptionId} target='_blank' className='btn btn-sm btn-primary ml-2 pt-0 pb-0 pl-1 pr-1'><i class="fa fa-arrow-down text-white" aria-hidden="true"></i></a>
                                            </td>
                                        </tr>)
                                    })}
                                    {Subscription.length <= 0 && <tr>
                                        <td colSpan={7} className='align-middle'>
                                            <p className='mb-0 text-center'>No data found for the criteria</p>
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                        {ShowLoadMore && <div className='row'>
                            <div className="col-sm-12 text-center mt-3">
                                <button onClick={load_more} disabled={ButtonDisabled} className="btn btn-primary btn-sm load_more_btn pl-4 pr-4">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>}Load More...</button>
                            </div>
                        </div>}
                    </div>
                </div>
            </ProfileSettings>

            {/* <!-- Modal --> */}
            <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Subscription Detail</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className='row mb-2'>
                                                <div className='col-6'>
                                                    <img src={Logo} width={'150px'}/>
                                                </div>
                                                <div className='col-6 text-right'>
                                                    <p>Invoice #{SubscriptionInfo.invoiceId}
                                                        {SubscriptionInfo.paymentStatus == 'Complete' && <span className="badge bg-success font-size-12 ms-2 ml-2">Paid</span>}
                                                        {SubscriptionInfo.paymentStatus == 'Pending' && <span className="badge bg-danger font-size-12 ms-2 ml-2">Pending</span>}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="invoice-title">
                                                <div className="text-muted">
                                                    <p className="mb-1">Second Floor Office Suite, The White House,</p>
                                                    <p className="mb-1"><i className="uil uil-envelope-alt me-1"></i>4-10 Queens Square, Poulton Le Fylde, FY6 7BN</p>
                                                </div>
                                            </div>

                                            <hr className="my-4" />

                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="text-muted">
                                                        <h6 className="font-size-16 mb-3">Billed To:</h6>
                                                        <h5 className="font-size-15 mb-2">{SubscriptionInfo.user_name}</h5>
                                                        {SubscriptionInfo.address1 && <p className="mb-1">{SubscriptionInfo.address1}</p>}
                                                        {SubscriptionInfo.address2 && <p className="mb-1">{SubscriptionInfo.address2}</p>}
                                                        <p className="mb-1">{SubscriptionInfo.city}, {SubscriptionInfo.state}, {SubscriptionInfo.country} - {SubscriptionInfo.zipCode}</p>
                                                        <p className="mb-1">{SubscriptionInfo.email}</p>
                                                        <p>{SubscriptionInfo.phoneNumber}</p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="text-muted text-sm-end">
                                                        <div>
                                                            <h5 className="font-size-15 mb-1">Invoice No:</h5>
                                                            <p>#{SubscriptionInfo.invoiceId}</p>
                                                        </div>
                                                        <div className="mt-4">
                                                            <h5 className="font-size-15 mb-1">Invoice Date:</h5>
                                                            <p>{SubscriptionInfo.orderDate}</p>
                                                        </div>
                                                        <div className="mt-4">
                                                            <h5 className="font-size-15 mb-1">Payment Tr Id:</h5>
                                                            <p>{SubscriptionInfo.paymentTransactionId}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="py-2">
                                                <h5>Order Summary</h5>

                                                <div className="table-responsive">
                                                    <table className="table align-middle table-nowrap table-centered mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ 'width': '70px' }}>No.</th>
                                                                <th>Item</th>
                                                                <th>Price</th>
                                                                <th>Quantity</th>
                                                                <th className="text-end" style={{ 'width': '120px' }}>Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">01</th>
                                                                <td>
                                                                    <div>
                                                                        <h5 className="text-truncate mb-1">{SubscriptionInfo.name} </h5>
                                                                        <p className="text-muted mb-0">{SubscriptionInfo.startDate} To {SubscriptionInfo.endDate}</p>
                                                                    </div>
                                                                </td>
                                                                <td>£{parseFloat(SubscriptionInfo.amount).toFixed(2)}</td>
                                                                <td>1</td>
                                                                <td>£{parseFloat(SubscriptionInfo.amount).toFixed(2)}</td>
                                                            </tr>

                                                            <tr>
                                                                <th scope="row" colspan="4" className="text-right">Sub Total</th>
                                                                <td className="text-end">£{parseFloat(SubscriptionInfo.amount).toFixed(2)}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" colspan="4" className="text-right">Shipping Charges</th>
                                                                <td className="text-end">£{parseFloat(SubscriptionInfo.shippingCharge).toFixed(2)}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" colspan="4" className="text-right">VAT {SubscriptionInfo.vatPercentage}%</th>
                                                                <td className="text-end">£{parseFloat(SubscriptionInfo.vat).toFixed(2)}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" colspan="4" className="text-right">Total Amount</th>
                                                                <td className="text-end"><b>£{parseFloat(SubscriptionInfo.totalAmount).toFixed(2)}</b></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(SubscriptionProfile)

