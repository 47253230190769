import React, { useState, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { post } from '../helpers/api_helper';
import { toast } from 'react-toastify';
import { POST_CONTACT_SEND_INQUIRY } from '../helpers/url_helper';
import SimpleReactValidator from 'simple-react-validator';


const Contact = props => {

    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const [ButtonDisabled, SetButtonDisabled] = useState(false);


    const [form_inputs, setInputs] = useState({
        fullName: '',
        businessName: '',
        phoneNumber: '',
        email: '',
        message: '',
    });

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            const response = await post(POST_CONTACT_SEND_INQUIRY, form_inputs);
            if (response.status) {
                toast(response.message);
                setInputs({
                    fullName: '',
                    businessName: '',
                    phoneNumber: '',
                    email: '',
                    message: '',
                });
            } else {
                toast.error(response.message);
            }
            SetButtonDisabled(false);
        }
    }
    return (
        <>
            <section className='section contact-us'>
                <div className="container">
                    <div className='row mb-5 mt-5'>
                        <div className='col-sm-12 text-center'>
                            <h2 className='mb-3'>Contact Us</h2>
                            <p>Fill out your details and we will get back to you as soon as possible.</p>
                        </div>
                    </div>
                    <div className='bg'>
                        <form onSubmit={handleSubmit}>
                            <div className='row'>
                                <div className='col-sm-6 mb-2'>
                                    <div className='form-group'>
                                        <label className='text-white'>Full Name</label>
                                        <input type='text' name="fullName" value={form_inputs.fullName} onChange={handleInputChange} className="form-control" />
                                        {simpleValidator.current.message('full name', form_inputs.fullName, 'required')}
                                    </div>
                                </div>
                                <div className='col-sm-6 mb-2'>
                                    <div className='form-group'>
                                        <label className='text-white'>Business Name</label>
                                        <input type='text' name="businessName" value={form_inputs.businessName} onChange={handleInputChange} className="form-control" />
                                        {simpleValidator.current.message('business name', form_inputs.businessName, 'required')}
                                    </div>
                                </div>
                                <div className='col-sm-6 mb-2'>
                                    <div className='form-group'>
                                        <label className='text-white'>Phone</label>
                                        <input type='text' name="phoneNumber" value={form_inputs.phoneNumber} onChange={handleInputChange} className="form-control" />
                                        {simpleValidator.current.message('phone number', form_inputs.phoneNumber, 'required')}
                                    </div>
                                </div>
                                <div className='col-sm-6 mb-2'>
                                    <div className='form-group'>
                                        <label className='text-white'>Email</label>
                                        <input type='text' name="email" value={form_inputs.email} onChange={handleInputChange} className="form-control" />
                                        {simpleValidator.current.message('email', form_inputs.email, 'required|email')}
                                    </div>
                                </div>
                                <div className='col-sm-12 mb-2'>
                                    <div className='form-group'>
                                        <label className='text-white'>Message</label>
                                        <textarea rows={5} name="message" value={form_inputs.message} onChange={handleInputChange} className="form-control" />
                                        {simpleValidator.current.message('message', form_inputs.message, 'required')}
                                    </div>
                                </div>
                                <div className='col-sm-12 mb-2 text-center mt-3'>
                                    <button disabled={ButtonDisabled} className="c-btn-light radius-10 pr-5 pl-5">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin mr-1"></i>} SEND</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section >
        </>
    );
}
export default withRouter(Contact)