import React, { useState, useContext, useEffect } from 'react';
import { Link, withRouter } from "react-router-dom";
import { post } from '../../helpers/api_helper';
import { GET_BUSINESS_PROFILE_DETAILES,GET_USER_ACTIVE_SUBSCRIPTION } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import { UserInfoContext } from "../../layouts/App_route";
import Loader from "../../component/Loader";

import UserIcon from '../../assets/images/user_icon.png';
import LinkIcon from '../../assets/images/link_icon.png';
import WebIcon from '../../assets/images/web_icon.png';
import InboxIcon from '../../assets/images/inbox_icon.png';
import StarIcon from '../../assets/images/star_icon.png';
import SettingIcon from '../../assets/images/setting_icon.png';
import widgetIcon from '../../assets/images/widget_icon.png';
import MiniWebsiteIcon from '../../assets/images/mini_website_icon.png';
import VisibilityIcon from '../../assets/images/visibility_icon.png';
import AnalyticsIcon from '../../assets/images/analytics_icon.png';

import CheckIcon from '../../assets/images/check.svg';
import UncheckIcon from '../../assets/images/uncheck.svg';

const Contact = props => {
    const Context_value = useContext(UserInfoContext);

    const { userSubscription } = useContext(UserInfoContext);
    const [isLoader, setLoader] = useState(0);

    const [ReviewLink, setReviewLink] = useState('');
    const [WebsiteLink, setWebsiteLink] = useState('');

    useEffect(() => {

        async function get_detaile() {
            var res_data = await post(GET_USER_ACTIVE_SUBSCRIPTION, {});
            if (res_data.status) {
                Context_value.setUserSubscription(res_data.data.detail)
            }

            var res_data = await post(GET_BUSINESS_PROFILE_DETAILES, {});
            if (res_data.status) {
                setReviewLink('/' + res_data.data.detail.url_slug + '/review')
                setWebsiteLink('/' + res_data.data.detail.url_slug)
                setLoader(1);
            } else {
                toast.error(res_data.message);
            }
        }
        get_detaile();
    }, [])

    return (
        <>
            {!isLoader && <Loader />}
            <section className='section mb-5'>
                <div className="container">
                    <div className='row mb-5 mt-4'>
                        <div className='col-sm-12 text-center'>
                            <h2 className='mb-3'>Dashboard</h2>
                        </div>
                    </div>

                    <div className="row mt-5 justify-content-md-center">
                        <div className='col-sm-12 col-lg-4 mb-4'>
                            <div className={`white-box ${userSubscription && userSubscription.isWebsiteAccess ? "" : "box-disble"}`}>
                                <Link to={(userSubscription && userSubscription.isWebsiteAccess) ? '/business-profile' : '/pricing'}>
                                    <small className='premium'>Unlock with Premium <i className="fa fa-lock ml-2 text-sky" aria-hidden="true"></i></small>
                                    <div className='icon mb-3'>
                                        <img className='' src={UserIcon} />
                                    </div>
                                    <h5 className='text-light-blue mb-3'>Profile Setup</h5>
                                    <p>Craft your business details and design your unique website here.</p>
                                </Link>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-4 mb-4'>
                            <div className={`white-box ${(userSubscription && (userSubscription.code == 'CardOnly' || userSubscription.code == 'CardAndWidgets')) ? "" : "box-disble"}`}>
                                <Link to={(userSubscription && (userSubscription.code == 'CardOnly' || userSubscription.code == 'CardAndWidgets')) ? '/cards' : '/pricing'}>
                                    <small className='premium'>{`${(userSubscription && userSubscription.code == 'WidgetsOnly') ? 'Unlock with upgrade plan' : 'Unlock with Premium'}`} <i className="fa fa-lock ml-2 text-sky" aria-hidden="true"></i></small>
                                    <div className='icon mb-3'>
                                        <img className='' src={LinkIcon} />
                                    </div>
                                    <h5 className='text-light-blue mb-3'>Tap Review Cards</h5>
                                    <p>Manage, view, and order your innovative review cards here.</p>
                                </Link>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-4 mb-4'>
                            <div className={`white-box ${(userSubscription && (userSubscription.code == 'WidgetsOnly' || userSubscription.code == 'CardAndWidgets')) ? "" : "box-disble"}`}>
                                <Link to={(userSubscription && (userSubscription.code == 'WidgetsOnly' || userSubscription.code == 'CardAndWidgets')) ? '/widget' : '/pricing'}>
                                    <small className='premium'>{`${(userSubscription && userSubscription.code == 'CardOnly') ? 'Unlock with upgrade plan' : 'Unlock with Premium'}`} <i className="fa fa-lock ml-2 text-sky" aria-hidden="true"></i></small>
                                    <div className='icon mb-3'>
                                        <img className='' src={widgetIcon} />
                                    </div>
                                    <h5 className='text-light-blue mb-3'>Tap Review Widget</h5>
                                    {/* <p>Copy your business review widget code</p> */}
                                    <p>Simply copy the code provided. </p>
                                </Link>
                            </div>
                        </div>
                        {/* <div className='col-sm-12 col-lg-4 mb-4'>
                            <div className='white-box'>
                                <Link to={ReviewLink} target="_blank">
                                    <div className='icon mb-3'>
                                        <img className='' src={LinkIcon} />
                                    </div>
                                    <h5 className='text-light-blue mb-3'>Review Link</h5>
                                    <p>Share this on your social media to get more customers</p>
                                </Link>
                            </div>
                        </div> */}
                        <div className='col-sm-12 col-lg-4 mb-4'>
                            <div className={`white-box ${userSubscription && userSubscription.isWebsiteAccess ? "" : "box-disble"}`}>
                                <Link to={(userSubscription && userSubscription.isWebsiteAccess) ? WebsiteLink : '/pricing'} target="_blank">
                                    <small className='premium'>Unlock with Premium <i className="fa fa-lock ml-2 text-sky" aria-hidden="true"></i></small>
                                    <div className='icon mb-3'>
                                        <img className='' src={WebIcon} />
                                    </div>
                                    <h5 className='text-light-blue mb-3'>Website View</h5>
                                    <p>Preview and experience your business's digital presence.</p>
                                </Link>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-4 mb-4'>
                            <div className={`white-box ${userSubscription && userSubscription.isWebsiteAccess ? "" : "box-disble"}`}>
                                <Link to={(userSubscription && userSubscription.isWebsiteAccess) ? '/inbox' : '/pricing'}>
                                    <small className='premium'>Unlock with Premium <i className="fa fa-lock ml-2 text-sky" aria-hidden="true"></i></small>
                                    <div className='icon mb-3'>
                                        <img className='' src={InboxIcon} />
                                    </div>
                                    <h5 className='text-light-blue mb-3'>Inbox</h5>
                                    <p>View and respond to customer messages.</p>
                                </Link>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-4 mb-4'>
                            <div className={`white-box ${userSubscription && userSubscription.isWebsiteAccess ? "" : "box-disble"}`}>
                                <Link to={(userSubscription && userSubscription.isWebsiteAccess) ? '/review' : '/pricing'} >
                                    <small className='premium'>Unlock with Premium <i className="fa fa-lock ml-2 text-sky" aria-hidden="true"></i></small>
                                    <div className='icon mb-3'>
                                        <img className='' src={StarIcon} />
                                    </div>
                                    <h5 className='text-light-blue mb-3'>My Reviews</h5>
                                    <p>See your customer reviews</p>
                                </Link>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-4 mb-4'>
                            <div className='white-box'>
                                <Link to="/account">
                                    <div className='icon mb-3'>
                                        <img className='' src={SettingIcon} />
                                    </div>
                                    <h5 className='text-light-blue mb-3'>Billing & Upgrades</h5>
                                    <p>Handle subscriptions, view invoices, and explore premium features</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            {/* <section className='section gb_blue '>
                <div className='container'>
                    <div className="row mt-4">
                        <div className="col-sm-12 text-center mb-4">
                            <h2 className='text-white mx-auto' style={{ 'maxWidth': '760px' }}>Unlock Your Full Potential with our Premium Membership</h2>
                        </div>
                    </div>
                    <div className="row mt-5 mb-5">
                        <div className='col-sm-12 col-lg-4 mb-3'>
                            <div className='white-box'>
                                <Link to="">
                                    <div className='icon mb-3'>
                                        <img src={MiniWebsiteIcon} />
                                    </div>
                                    <h5 className='text-light-blue mb-3'>Mini-Website Creation</h5>
                                    <p>Lorem ipsum dolor sit amet elit, sed eiusmod tempor incididunt.</p>
                                </Link>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-4 mb-3'>
                            <div className='white-box'>
                                <Link to="">
                                    <div className='icon mb-3'>
                                        <img src={VisibilityIcon} />
                                    </div>
                                    <h5 className='text-light-blue mb-3'>Enhanced Visibility</h5>
                                    <p>Lorem ipsum dolor sit amet elit, sed eiusmod tempor incididunt.</p>
                                </Link>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-4 mb-3'>
                            <div className='white-box'>
                                <Link to="">
                                    <div className='icon mb-3'>
                                        <img src={AnalyticsIcon} />
                                    </div>
                                    <h5 className='text-light-blue mb-3'>Advanced Analytics</h5>
                                    <p>Lorem ipsum dolor sit amet elit, sed eiusmod tempor incididunt.</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section className='section bg_white'>
                <div className="container">
                    <div className='row mb-5 mt-4'>
                        <div className='col-sm-12 text-center'>
                            <h2 className='mb-3'>Compare Plans</h2>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <table className='table table-bordered plans-table'>
                                <tr>
                                    <td className='no-border'></td>
                                    <td className='text-center' width="200px">
                                        <h5 className='text-black mb-0'>Free Plan</h5>
                                        <small className='mb-0 text-sky'>Ignite Your Online Presence</small>
                                    </td>
                                    <td className='text-center' width="200px">
                                        <h5 className='text-black mb-0'>Individual Pro Plan</h5>
                                        <small className='mb-0 text-sky'>Elevate Your Reputation</small>
                                    </td>
                                    <td className='text-center' width="200px">
                                        <h5 className='text-black mb-0'>Team Boost Plan</h5>
                                        <small className='mb-0 text-sky'>Unleash Collective Brilliance</small>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Personalized review page</td>
                                    <td className='text-center'><img src={CheckIcon} alt="" /></td>
                                    <td className='text-center'><img src={CheckIcon} alt="" /></td>
                                    <td className='text-center'><img src={CheckIcon} alt="" /></td>
                                </tr>
                                <tr>
                                    <td>Access to our system’s essentials</td>
                                    <td className='text-center'><img src={CheckIcon} alt="" /></td>
                                    <td className='text-center'><img src={CheckIcon} alt="" /></td>
                                    <td className='text-center'><img src={CheckIcon} alt="" /></td>
                                </tr>
                                <tr>
                                    <td>Tap Card for seamless reviews</td>
                                    <td className='text-center'><img src={UncheckIcon} alt="" /></td>
                                    <td className='text-center'><img src={CheckIcon} alt="" /></td>
                                    <td className='text-center'><img src={CheckIcon} alt="" /></td>
                                </tr>
                                <tr>
                                    <td>Mini website to showcase services</td>
                                    <td className='text-center'><img src={UncheckIcon} alt="" /></td>
                                    <td className='text-center'><img src={CheckIcon} alt="" /></td>
                                    <td className='text-center'><img src={CheckIcon} alt="" /></td>
                                </tr>
                                <tr>
                                    <td>Showcase services, locations, gallery, about us, pricing</td>
                                    <td className='text-center'><img src={UncheckIcon} alt="" /></td>
                                    <td className='text-center'><img src={CheckIcon} alt="" /></td>
                                    <td className='text-center'><img src={CheckIcon} alt="" /></td>
                                </tr>
                                <tr>
                                    <td>Inbox for easy lead management</td>
                                    <td className='text-center'><img src={UncheckIcon} alt="" /></td>
                                    <td className='text-center'><img src={CheckIcon} alt="" /></td>
                                    <td className='text-center'><img src={CheckIcon} alt="" /></td>
                                </tr>
                                <tr>
                                    <td>Multiple Tap Cards for your team</td>
                                    <td className='text-center'><img src={UncheckIcon} alt="" /></td>
                                    <td className='text-center'><img src={UncheckIcon} alt="" /></td>
                                    <td className='text-center'><img src={CheckIcon} alt="" /></td>
                                </tr>
                                <tr>
                                    <td>Inbox for easy lead management</td>
                                    <td className='text-center'><img src={UncheckIcon} alt="" /></td>
                                    <td className='text-center'><img src={UncheckIcon} alt="" /></td>
                                    <td className='text-center'><img src={CheckIcon} alt="" /></td>
                                </tr>
                            </table>

                            <div className='mt-5 text-center'>
                                <Link to="/pricing" className="c-btn-dark ml-3">View pricing  <i className="fa fa-long-arrow-right ml-2" aria-hidden="true"></i></Link>
                            </div>
                        </div>
                    </div>

                </div>
            </section> */}
        </>
    );
}
export default withRouter(Contact)

