import React, { useState, useContext, useRef, useEffect } from 'react';
import { useHistory, Link, withRouter } from "react-router-dom";

import { post, del } from '../../../helpers/api_helper';
import { img_view } from '../../../helpers/Helper';
import { POST_SAVE_BANNER_LOGO, POST_REMOVE_BANNER_LOGO, GET_BUSINESS_PROFILE_DETAILES, POST_SAVE_BUSINESS_PROFILE, DELETE_GALLARY_IMAGE } from '../../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { UserInfoContext } from "../../../layouts/App_route";
import Loader from "../../../component/Loader";
import ImageCrop from "../../../component/ImageCrop";

import UserImage from './../../../assets/images/logo.svg';
import ComingSoonImage from './../../../assets/images/coming_soon.png';


const BusinessDetails = props => {
    let history = useHistory();
    const { userSubscription } = useContext(UserInfoContext);
    const [isLoader, setLoader] = useState(0);

    const simpleValidator = useRef(new SimpleReactValidator());
    const [form_inputs, setInputs] = useState({
        profileId: '', logo: '', aboutUsImage: '', businessName: '', firstName: '', lastName: '', email: '',
        url_slug: '', telephoneNumber: '', mobileNumber: '', showMobileNumber: 0, addressLine1: '',
        addressLine2: '', town: '', country: '', postCode: '', headline: '', aboutUs: '',
        ShowContactForm: 0, cardName: '', facebookLink: '', linkedinLink: '', instagramLink: '', twitterLink: '',
        services: [], faq: [], gallary_image: [], accreditation_logos: [], openingHours: [], showFooterAddress: 0
    });
    const [, forceUpdate] = useState();
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [CropImage, SetCropImage] = useState({ type: '', image: '' });
    const [MaxCharecters, SetMaxCharecters] = useState(1500);
    const [WebsiteLink, setWebsiteLink] = useState('');

    useEffect(() => {
        async function get_detaile() {
            await get_profile_info();
        }
        get_detaile();
    }, [])

    const get_profile_info = async () => {
        var res_data = await post(GET_BUSINESS_PROFILE_DETAILES, {});
        if (res_data.status) {

            setInputs(res_data.data.detail);
            setWebsiteLink('/' + res_data.data.detail.url_slug)

            setLoader(1);
        } else {
            toast.error(res_data.message);
        }
    }
    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            form_inputs.type = 'aboutYourBusiness'
            var res_data = await post(POST_SAVE_BUSINESS_PROFILE, form_inputs, {});
            if (res_data.status) {
                await get_profile_info();
                props.call_back(3)
                // toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'ShowContactForm' || event.target.name === 'showMobileNumber' || event.target.name === 'showFooterAddress') {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: (event.target.checked) ? 1 : 0 }));
        } else if (event.target.name === 'aboutUs') {
            if (event.target.value.split(/\s+/g).length <= 250) {
                setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
                SetMaxCharecters(1500);
            } else {
                setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value.split(' ').slice(0, 250).join(' ') }));
                SetMaxCharecters(event.target.value.length);
            }
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    // input text change handler
    const handleInputChangeLogo = (event) => {
        event.persist();
        var value = event.target.files[0];
        var file_extension = event.target.files[0].name.split('.').pop();
        var file_size = event.target.files[0].size;
        var file_size = (file_size / 1024 / 1024).toFixed(1);

        var extension = ['jpg', 'jpeg', 'png'];
        if (!extension.includes(file_extension.toLowerCase())) {
            toast.error('Alludu only file jpg , jpeg and png.');
            event.target.value = null;
            return false;
        }

        if (file_size > 1) {
            toast.error('Max File size Allowed 1Mb.');
            event.target.value = null;
            return false;
        }

        var file = event.target.files[0];
        var reader = new FileReader();
        reader.onload = async function () {
            // SetCropImage({ type: event.target.name, image: reader.result });
            // window.$('#ImageCropModal').modal({ backdrop: 'static', keyboard: false });
            // var res_data = await post(POST_SAVE_BANNER_LOGO, { 'profileId': form_inputs.profileId, 'type': event.target.name, 'image': reader.result });
            // if (res_data.status) {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: reader.result }));
            //     toast(res_data.message);
            // } else {
            //     toast.error(res_data.message);
            // }
        }
        reader.readAsDataURL(file);
    }


    // remove image
    const remove_image = async (type) => {
        if (type == 'logo_image') {
            form_inputs.logo_image = '';
            setInputs(inputs => ({ ...form_inputs, ['logo']: '' }));
        } else {
            form_inputs.about_image = '';
            setInputs(inputs => ({ ...form_inputs, ['aboutUsImage']: '' }));
        }
        return true
        var res_data = await post(POST_REMOVE_BANNER_LOGO, { 'profileId': form_inputs.profileId, 'type': type });
        if (res_data.status) {
            if (type == 'logo') {
                setInputs(inputs => ({ ...form_inputs, ['logo']: '' }));
            } else {
                setInputs(inputs => ({ ...form_inputs, ['aboutUsImage']: '' }));
            }
            toast(res_data.message);
        } else {
            toast.error(res_data.message);
        }
    }

    //addRow
    const addRow = (type) => {
        if (type === 'services') {
            var data = (form_inputs.services) ? form_inputs.services : [];
            data.push({ title: '', description: '', charactersLeft: 1500 });
            setInputs(inputs => ({ ...form_inputs, [type]: data }));
        } else if (type === 'faq') {
            var data = (form_inputs.faq) ? form_inputs.faq : [];
            data.push({ question: '', answer: '' });
            setInputs(inputs => ({ ...form_inputs, [type]: data }));
        } else if (type === 'openingHours') {
            var data = (form_inputs.openingHours) ? form_inputs.openingHours : [];
            data.push({ day: '', openTime: '', closeTime: '' });
            setInputs(inputs => ({ ...form_inputs, [type]: data }));
        }
    }

    //handleInputChangeMultiple
    const handleInputChangeMultiple = (index, event) => {
        var name = event.target.name;
        if (name === 'isOpen' || name === 'day' || name === 'openTime' || name === 'closeTime') {
            if (name === 'isOpen') {
                form_inputs.openingHours[index][name] = event.target.checked;
            } else {
                form_inputs.openingHours[index][name] = event.target.value;
            }
            console.log(form_inputs);
            setInputs(inputs => ({ ...form_inputs, ['openingHours']: form_inputs.openingHours }));
        }
    }

    const removeRow = (index, type) => {
        if (type === 'services') {
            var data = form_inputs.services;
            data.splice(index, 1);
            setInputs(inputs => ({ ...form_inputs, ['services']: data }));
        } else if (type === 'faq') {
            var data = form_inputs.faq;
            data.splice(index, 1);
            setInputs(inputs => ({ ...form_inputs, ['faq']: data }));
        } else if (type === 'openingHours') {
            var data = form_inputs.openingHours;
            data.splice(index, 1);
            setInputs(inputs => ({ ...form_inputs, ['openingHours']: data }));
        }
    }



    return (
        <>
            {!isLoader && <Loader />}
            <div className='row mb-4'>
                <div className="col-sm-8">
                    <h4 className="mb-0">About Your Business</h4>
                </div>
                <div className="col-sm-4 text-sm-right">
                    <p className='mb-0 pt-2'>Current Plan: <b> {(userSubscription && userSubscription.shortName)? userSubscription.shortName : 'Free'}</b></p>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className='row mb-5'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='form-group'>
                            <label className='w-100'>Business Headline * </label>
                            <input type='text' name="headline" placeholder='A cafe might write: Freshly Brewed Moments, Every Day!' value={form_inputs.headline}  onChange={handleInputChange} className="form-control" />
                            {simpleValidator.current.message('headline', form_inputs.headline, 'required')}
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='form-group'>
                            <label className='w-100'>About Us *</label>
                            <textarea rows={7} name="aboutUs" maxLength={MaxCharecters} value={form_inputs.aboutUs}  onPaste={handleInputChange} onChange={handleInputChange} className="form-control"
                                placeholder={`The "About Us" section provides a glimpse into the business's ethos, values, story, or what sets it apart try to write up to 250 words.`} />
                            {simpleValidator.current.message('aboutUs', form_inputs.aboutUs, 'required')}
                            {/* <small className='float-right mt-2'>Tell people about your business max 250 words</small> */}
                        </div>
                    </div>

                    <div className='col-sm-12 pt-2 pb-2'>
                        <h5><b>Logo</b></h5>
                    </div>
                    <div className='col-sm-12 col-lg-12 mb-3'>
                        <div className='Image_man_box no-border'>
                            {(form_inputs.logo || form_inputs.logo_image) && <div className='img_box' >
                                {!form_inputs.logo_image && <img src={img_view(form_inputs.logo + '?t=' + new Date().getTime(), 170, 170)} style={{ 'maxWidth': '100%' }} />}
                                {form_inputs.logo_image && <img src={form_inputs.logo_image} style={{ 'maxWidth': '100%' }} />}
                                <span onClick={remove_image.bind(this, 'logo_image')}><i className="fa fa-trash-o" aria-hidden="true"></i></span>
                            </div>}

                            {(!form_inputs.logo && !form_inputs.logo_image) && <label htmlFor="myfile" className='img_box add'>
                                <div className='text-center'>
                                    <input id='myfile' name='logo_image' type="file" onChange={handleInputChangeLogo} className='d-none' accept="image/*" />

                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.5 10C10.6046 10 11.5 9.10457 11.5 8C11.5 6.89543 10.6046 6 9.5 6C8.39543 6 7.5 6.89543 7.5 8C7.5 9.10457 8.39543 10 9.5 10Z" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22H15.5C20.5 22 22.5 20 22.5 15V10" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M18.5 8V2L20.5 4" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M18.5 2L16.5 4" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M3.16992 18.9501L8.09992 15.6401C8.88992 15.1101 10.0299 15.1701 10.7399 15.7801L11.0699 16.0701C11.8499 16.7401 13.1099 16.7401 13.8899 16.0701L18.0499 12.5001C18.8299 11.8301 20.0899 11.8301 20.8699 12.5001L22.4999 13.9001" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>

                                    <p className='text-center mt-2'><small>Upload Logo<br /> (Jpg,Jpeg,Png)</small></p>
                                </div>
                            </label>}
                        </div>
                    </div>

                    <div className='col-sm-12 pt-2 pb-2'>
                        <h5><b>Hero Image</b></h5>
                    </div>
                    <div className='col-sm-12 col-lg-12 mb-3'>
                        <div className='Image_man_box no-border'>
                            {(form_inputs.aboutUsImage || form_inputs.about_image) && <div className='img_box' style={{'marginLeft': '0px', 'maxWidth': '450px', 'padding': '0px', 'width': '100%', 'minHeight': '220px'}}>
                                {!form_inputs.about_image && <img src={img_view(form_inputs.aboutUsImage + '?t=' + new Date().getTime(), 170, 170)} style={{ 'maxWidth': '100%' }} />}
                                {form_inputs.about_image && <img src={form_inputs.about_image} style={{ 'maxWidth': '100%' }} />}
                                <span onClick={remove_image.bind(this, 'about_image')}><i className="fa fa-trash-o" aria-hidden="true"></i></span>
                            </div>}

                            {(!form_inputs.aboutUsImage && !form_inputs.about_image) && <label htmlFor="myfile" className='img_box add'>
                                <div className='text-center'>
                                    <input id='myfile' name='about_image' type="file" onChange={handleInputChangeLogo} className='d-none' accept="image/*" />

                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.5 10C10.6046 10 11.5 9.10457 11.5 8C11.5 6.89543 10.6046 6 9.5 6C8.39543 6 7.5 6.89543 7.5 8C7.5 9.10457 8.39543 10 9.5 10Z" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22H15.5C20.5 22 22.5 20 22.5 15V10" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M18.5 8V2L20.5 4" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M18.5 2L16.5 4" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M3.16992 18.9501L8.09992 15.6401C8.88992 15.1101 10.0299 15.1701 10.7399 15.7801L11.0699 16.0701C11.8499 16.7401 13.1099 16.7401 13.8899 16.0701L18.0499 12.5001C18.8299 11.8301 20.0899 11.8301 20.8699 12.5001L22.4999 13.9001" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>

                                    <p className='text-center mt-2'><small>Upload Hero Image<br /> (Jpg,Jpeg,Png)</small></p>
                                </div>
                            </label>}
                        </div>

                    </div>

                    {/* social media */}
                    <div className='col-sm-12 pt-2 pb-2'>
                        <h5><b>Social Links</b></h5>
                    </div>
                    <div className='col-sm-12 col-lg-6'>
                        <div className='form-group'>
                            <label className='w-100'>Facebook Link </label>
                            <input type='text' name="facebookLink" value={form_inputs.facebookLink}  onChange={handleInputChange} className="form-control" placeholder='Facebook.com/janebusiness' />
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-6'>
                        <div className='form-group'>
                            <label className='w-100'>Linkedin Link </label>
                            <input type='text' name="linkedinLink" value={form_inputs.linkedinLink}  onChange={handleInputChange} className="form-control" placeholder='Linkedin.com/janebusiness' />
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-6'>
                        <div className='form-group'>
                            <label className='w-100'>Instagram Link </label>
                            <input type='text' name="instagramLink" value={form_inputs.instagramLink}  onChange={handleInputChange} className="form-control" placeholder='Instagram.com/janebusiness' />
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-6'>
                        <div className='form-group'>
                            <label className='w-100'>X (Twitter) Link </label>
                            <input type='text' name="twitterLink" value={form_inputs.twitterLink}  onChange={handleInputChange} className="form-control" placeholder='Twitter.com/janebusiness' />
                        </div>
                    </div>

                    <div className='col-sm-12 pt-2 pb-3'>
                        <h5><b>Opening Hours</b></h5>
                    </div>
                    <div className='col-sm-12 col-lg-9'>
                        <div className='form-group'>
                            {form_inputs.openingHours && Object.entries(form_inputs.openingHours).map(([key, openinghours]) => (
                                <div className='row mb-3 mb-sm-0 mb-md-0 mb-lg-0' key={key}>
                                    <div className='col-12 col-sm-4 pr-lg-1 pr-md-1'>
                                        <div className='form-group'>
                                            <label>
                                                <input type='checkbox' name='isOpen' checked={openinghours.isOpen} onChange={handleInputChangeMultiple.bind(this, key)}  className="form-control pull-left mr-2 mt-1" />
                                                {openinghours.day}
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-5 col-sm-3 pl-lg-1 pl-md-1 pr-lg-1 pr-md-1'>
                                        <div className='form-group'>
                                            <select name='openTime' onChange={handleInputChangeMultiple.bind(this, key)} value={openinghours.openTime}  className="form-control">
                                                <option value="">Time</option>
                                                <option value="Close">Close</option>
                                                <option value="01:00 AM">01:00 AM</option>
                                                <option value="02:00 AM">02:00 AM</option>
                                                <option value="03:00 AM">03:00 AM</option>
                                                <option value="04:00 AM">04:00 AM</option>
                                                <option value="05:00 AM">05:00 AM</option>
                                                <option value="06:00 AM">06:00 AM</option>
                                                <option value="07:00 AM">07:00 AM</option>
                                                <option value="08:00 AM">08:00 AM</option>
                                                <option value="09:00 AM">09:00 AM</option>
                                                <option value="10:00 AM">10:00 AM</option>
                                                <option value="11:00 AM">11:00 AM</option>
                                                <option value="12:00 AM">12:00 AM</option>
                                                <option value="01:00 PM">01:00 PM</option>
                                                <option value="02:00 PM">02:00 PM</option>
                                                <option value="03:00 PM">03:00 PM</option>
                                                <option value="04:00 PM">04:00 PM</option>
                                                <option value="05:00 PM">05:00 PM</option>
                                                <option value="06:00 PM">06:00 PM</option>
                                                <option value="07:00 PM">07:00 PM</option>
                                                <option value="08:00 PM">08:00 PM</option>
                                                <option value="09:00 PM">09:00 PM</option>
                                                <option value="10:00 PM">10:00 PM</option>
                                                <option value="11:00 PM">11:00 PM</option>
                                                <option value="12:00 PM">12:00 PM</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className='col-2 col-sm-1 text-center pb-3 pt-sm-2 pt-md-2 pt-lg-2 pl-lg-1 pl-md-1 pr-lg-1 pr-md-1 '>
                                        To
                                    </div>
                                    <div className='col-5 col-sm-3 pl-lg-1 pl-md-1 pr-lg-1 pr-md-1'>
                                        <select name='closeTime' onChange={handleInputChangeMultiple.bind(this, key)} value={openinghours.closeTime}  className="form-control">
                                            <option value="">Time</option>
                                            <option value="Close">Close</option>
                                            <option value="01:00 AM">01:00 AM</option>
                                            <option value="02:00 AM">02:00 AM</option>
                                            <option value="03:00 AM">03:00 AM</option>
                                            <option value="04:00 AM">04:00 AM</option>
                                            <option value="05:00 AM">05:00 AM</option>
                                            <option value="06:00 AM">06:00 AM</option>
                                            <option value="07:00 AM">07:00 AM</option>
                                            <option value="08:00 AM">08:00 AM</option>
                                            <option value="09:00 AM">09:00 AM</option>
                                            <option value="10:00 AM">10:00 AM</option>
                                            <option value="11:00 AM">11:00 AM</option>
                                            <option value="12:00 AM">12:00 AM</option>
                                            <option value="01:00 PM">01:00 PM</option>
                                            <option value="02:00 PM">02:00 PM</option>
                                            <option value="03:00 PM">03:00 PM</option>
                                            <option value="04:00 PM">04:00 PM</option>
                                            <option value="05:00 PM">05:00 PM</option>
                                            <option value="06:00 PM">06:00 PM</option>
                                            <option value="07:00 PM">07:00 PM</option>
                                            <option value="08:00 PM">08:00 PM</option>
                                            <option value="09:00 PM">09:00 PM</option>
                                            <option value="10:00 PM">10:00 PM</option>
                                            <option value="11:00 PM">11:00 PM</option>
                                            <option value="12:00 PM">12:00 PM</option>
                                        </select>
                                    </div>
                                </div>
                            ))}
                            <div className='row'>
                                <div className='col-sm-12'>
                                    {(!form_inputs.openingHours || form_inputs.openingHours?.length < 7) && <button type='button'  onClick={addRow.bind(this, 'openingHours')} className='btn c-btn-sky btn-block btn-sm'>+ Add</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-sm-6'>
                        <button type='button' onClick={() => { history.push("/dashboard"); }} disabled={ButtonDisabled} className="c-btn-blue-outline btn-md pt-2 pb-2 pl-3 pr-3 radius-10 mb-3 c-btn-block"><i className="fa fa-long-arrow-left mr-2" aria-hidden="true"></i>  Back To Dashboard </button>
                        {/* <button type='button' onClick={() => { props.call_back(1) }} disabled={ButtonDisabled} className="c-btn-blue-outline btn-md pt-2 pb-2 pl-3 pr-3 radius-10 mb-3 c-btn-block"><i className="fa fa-long-arrow-left mr-2 text-white" aria-hidden="true"></i> Back </button> */}
                    </div>
                    <div className='col-sm-6 text-right'>
                        <button type='submit' disabled={ButtonDisabled} className="c-btn-blue btn-md pt-2 pb-2 pl-3 pr-3 radius-10 mb-3 c-btn-block">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin mr-1"></i>} Save and Continue <i className="fa fa-long-arrow-right ml-2 text-white" aria-hidden="true"></i></button>
                    </div>
                </div>
            </form >
        </>
    );
}
export default withRouter(BusinessDetails)

