import React, { useState, useRef, useEffect, useContext } from 'react';
import { withRouter, useHistory, Link } from "react-router-dom";
import SimpleReactValidator from 'simple-react-validator';
import { GET_SUBSCRIPTION_RENEW_INFO, ORDER_SUBSCRIPTION_RENEW } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import { post } from '../../helpers/api_helper';
import StripeCheckout from 'react-stripe-checkout';
import { UserInfoContext } from "../../layouts/App_route";
import Loader from "../../component/Loader";

import Logo from './../../assets/images/logo.svg';

var SubscriptionInfo = {};

const BuyCards = props => {
    let history = useHistory();
    const { userSubscription, setTotalCard } = useContext(UserInfoContext);
    const [isLoader, setLoader] = useState(0);

    const simpleValidator = useRef(new SimpleReactValidator());
    const [form_inputs, setInputs] = useState({
        name: '',
        email: '',
        phoneNumber: '',

        address1: '',
        address2: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',

        selectCard: 0,
        planId: '',
        planName: '',
        subscriptionType: '',

        amount: '0.0',
        shippingId: '',
        shippingCharge: '0.00',
        vat: '0.00',
        totalAmount: '0.00',
        vatPercentage: '0',

    });
    const [, forceUpdate] = useState();
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [PlanDate, SetPlanDate] = useState({ satartDate: '', endDate: '' });

    useEffect(() => {
        async function get_detaile() {
            // if (userSubscription && userSubscription.planId > 1) {

                var res_data = await post(GET_SUBSCRIPTION_RENEW_INFO, {});
                if (res_data.status) {
                    form_inputs.name = res_data.data.name;
                    form_inputs.email = res_data.data.email;

                    form_inputs.planId = res_data.data.planId;
                    form_inputs.planName = res_data.data.planName;
                    form_inputs.subscriptionType = res_data.data.subscriptionType;
                    form_inputs.selectCard = res_data.data.total_card;

                    setInputs(inputs => ({ ...form_inputs }));
                    SubscriptionInfo = res_data.data;
                    change_plan();
                } else {
                    toast.error(res_data.message);
                    history.push("/subscription");
                }
                setLoader(1);
            // } else {
            //     history.push("/subscription");
            // }

        }
        get_detaile();
    }, [])

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'subscriptionType') {
            form_inputs.subscriptionType = (event.target.checked) ? 'annually' : 'monthly';
            setInputs(inputs => ({ ...form_inputs }));
            change_plan();
        } else {

            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            window.$('.StripeCheckout').click();
            SetButtonDisabled(true);
        }
    }


    const handleToken = async (token) => {
        SetButtonDisabled(true);

        form_inputs.token = token
        const response = await post(ORDER_SUBSCRIPTION_RENEW, form_inputs);
        if (response.status) {
            toast(response.message);
            history.push("/subscription");
        } else {
            toast.error(response.message);
        }
        SetButtonDisabled(false);
    }

    const change_plan = async () => {
        if (form_inputs.subscriptionType == 'monthly') {
            SetPlanDate({
                satartDate: SubscriptionInfo.monthly_info?.startDate,
                endDate: SubscriptionInfo.monthly_info?.endDate
            })

            form_inputs.amount = SubscriptionInfo.monthly_info?.amount;
            form_inputs.vat = SubscriptionInfo.monthly_info?.vat;
            form_inputs.vatPercentage = SubscriptionInfo.monthly_info?.vatPercentage;
            form_inputs.totalAmount = SubscriptionInfo.monthly_info?.totalAmount;
            setInputs(inputs => ({ ...form_inputs }));
        } else {
            SetPlanDate({
                satartDate: SubscriptionInfo.annually_info?.startDate,
                endDate: SubscriptionInfo.annually_info?.endDate
            })
            form_inputs.amount = SubscriptionInfo.annually_info?.amount;
            form_inputs.vat = SubscriptionInfo.annually_info?.vat;
            form_inputs.vatPercentage = SubscriptionInfo.annually_info?.vatPercentage;
            form_inputs.totalAmount = SubscriptionInfo.annually_info?.totalAmount;
            setInputs(inputs => ({ ...form_inputs }));
        }
    }
    return (
        <>
            {!isLoader && <Loader />}
            <section className='section pricing'>
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-sm-6">
                            <h3 className="mb-0">Renew Subscription</h3>
                        </div>
                        <div className="col-sm-6">
                            <p className="p-breadcrumb text-right mb-0 mt-2">
                                <Link className="text-sky" to="/dashboard">Dashboard</Link>
                                <i className="fa fa-angle-right mr-2 ml-2" aria-hidden="true"></i>
                                <Link className="text-sky" to="/subscription">Subscription</Link>
                                <i className="fa fa-angle-right mr-2 ml-2" aria-hidden="true"></i>
                                Renew
                            </p>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="row mb-5">

                            <div className='col-md-12 col-lg-7 mb-3'>
                                <div className='bg_white p-4'>
                                    <div className='row'>
                                        <div className='col-sm-12 mb-3'>
                                            <h5>Contact Information</h5>
                                        </div>
                                        <hr />
                                        <div className='col-sm-12'>
                                            <div className='form-group mb-2'>
                                                <label>Full name</label>
                                                <input type='text' name="name" value={form_inputs.name} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('name', form_inputs.name, 'required')}
                                            </div>
                                            <div className='form-group mb-2'>
                                                <label>Email</label>
                                                <input type='text' name="email" value={form_inputs.email} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('email', form_inputs.email, 'required|email')}
                                            </div>
                                            <div className='form-group mb-2'>
                                                <label>Phone number</label>
                                                <input type='text' name="phoneNumber" value={form_inputs.phoneNumber} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('phone number', form_inputs.phoneNumber, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-sm-12 mb-3 mt-4'>
                                            <h5>Billing Details</h5>
                                        </div>
                                        <hr />
                                        <div className='col-sm-6'>
                                            <div className='form-group mb-2'>
                                                <label>Address line 1</label>
                                                <input type='text' name="address1" value={form_inputs.address1} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('address', form_inputs.address1, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group mb-2'>
                                                <label>Address line 2</label>
                                                <input type='text' name="address2" value={form_inputs.address2} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('address', form_inputs.address2, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group mb-2'>
                                                <label>City</label>
                                                <input type='text' name="city" value={form_inputs.city} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('city', form_inputs.city, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group mb-2'>
                                                <label>State</label>
                                                <input type='text' name="state" value={form_inputs.state} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('state', form_inputs.state, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group mb-2'>
                                                <label>Country</label>
                                                <input type='text' name="country" value={form_inputs.country} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('country', form_inputs.country, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group mb-2'>
                                                <label>Zip Code</label>
                                                <input type='text' name="zipCode" value={form_inputs.zipCode} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('zip code', form_inputs.zipCode, 'required')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-12 col-lg-5 mb-3'>
                                <div className="bg_white p-4">
                                    <div>
                                        <h4 className='text-light-blue'>Summary</h4>
                                    </div>
                                    <div className="mt-4">
                                        <div className='row'>
                                            <div className='col-12 mb-4'>
                                                {/* <p className='mb-3'>
                                                    Monthly
                                                    <label className="switch ml-3 mr-3">
                                                        <input name="subscriptionType" type="checkbox" disabled={ButtonDisabled} onChange={handleInputChange} checked={(form_inputs.subscriptionType == 'monthly') ? false : true} />
                                                        <span className="slider round"></span>
                                                    </label>
                                                    Annually
                                                </p> */}

                                                <p className='mb-0'>Date  {PlanDate.satartDate} To {PlanDate.endDate}</p>
                                            </div>
                                            <div className='col-8'>
                                                <p className='mb-0'>{form_inputs.planName} - {form_inputs.selectCard} Cards</p>
                                            </div>
                                            <div className='col-4 text-right'>£{form_inputs.amount}</div>
                                        </div>
                                        <hr className='' />
                                        <div className='row'>
                                            <div className='col-8'><p><b>Sub Total</b></p></div>
                                            <div className='col-4 text-right'><b>£{form_inputs.amount}</b></div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-8'><p><b>VAT {form_inputs.vatPercentage}%</b></p></div>
                                            <div className='col-4 text-right'><b>£{form_inputs.vat}</b></div>
                                        </div>
                                        <hr className='' />
                                        <div className='row'>
                                            <div className='col-8'><p><b>Total Amount</b></p></div>
                                            <div className='col-4 text-right'><b>£{form_inputs.totalAmount}</b></div>
                                        </div>
                                        <div className='row mt-4'>
                                            <div className='col-sm-12'>
                                                <div class="form-group form-check mb-2">
                                                    <input type="checkbox" value="1" name='tc' onChange={handleInputChange} class="form-check-input" id="exampleCheck1" />
                                                    <label class="form-check-label mr-2 ml-2" for="exampleCheck1">I agree to the <Link to="/terms-conditions.pdf" target="_blank">Terms & Conditions </Link>.</label>
                                                    {simpleValidator.current.message('terms and conditions', form_inputs.tc, 'required')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center mt-4'>
                                        <button disabled={(ButtonDisabled || !form_inputs.tc)} className="c-btn-light mb-3" >{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin mr-1"></i>} Pay Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section >

            <StripeCheckout
                className="btn btn-success StripeCheckout d-none"
                image={Logo}
                stripeKey={process.env.REACT_APP_STRIPE_KEY}
                token={handleToken}
                email={form_inputs.email}
                currency={process.env.REACT_APP_CURRENCY}
                amount={form_inputs.totalAmount * 100}
                name={"TAP Review"}
                billingAddress={false}
                alipay={false}
            />
        </>
    );
}
export default withRouter(BuyCards)