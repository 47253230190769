import React, { useState, useEffect, useRef } from 'react';
import { useHistory, withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import { post } from '../helpers/api_helper';
import { img_view } from '../helpers/Helper';
import { POST_GET_PROFILE, POST_ADD_REVIEW, GET_REVIEW } from '../helpers/url_helper';
import SimpleReactValidator from 'simple-react-validator';
import ReactStars from "react-rating-stars-component";

import email_icon from '../assets/images/email.png';
import phone_icon from '../assets/images/phone.png';
import Logo from '../assets/images/logo.svg';

import Loader from "../component/Loader";

const Services = props => {
    let history = useHistory();

    const [UserInfo, SetUserInfo] = useState({});
    const [InquiryForm, SetInquiryForm] = useState({ cardId: 0, profileId: 0, rating: 0, name: '', email: '', message: '' });
    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [InquirySendSuccess, SetInquirySendSuccess] = useState(false);

    const [ShowLoadMore, seShowLoadMore] = useState(false);
    const [FilterData, SetFilterData] = useState({ profileId: 0, limit: 10, page: 0, search: '' });
    const [ReviewList, setReviewList] = useState([]);

    const [isLoader, setLoader] = useState(0);

    useEffect(() => {
        async function get_detaile() {
            await get_profile();
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.slug])

    const get_profile = async () => {
        var res_data = await post(POST_GET_PROFILE, { 'slug': props.match.params.slug, 'cardId': props.match.params.cardId });
        if (res_data.status) {
            SetUserInfo(res_data.data.user_info);
            SetInquiryForm({ profileId: res_data.data.user_info.profileId });

            FilterData.profileId = res_data.data.user_info.profileId;
            SetFilterData(inputs => ({ ...FilterData, ['profileId']: res_data.data.user_info.profileId }));
            // await get_revies();
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    const link_clikc = (data) => {
        if (data.name == 'Phone') {
            window.open('tel:' + data.value, '_blank', 'noreferrer');
        } else if (data.name == 'Email') {
            window.location.replace('mailto:' + data.value);
        } else if (data.name == 'Skype' || data.name == 'Paytm' || data.name == 'Google Pay' || data.name == 'PhonePe' || data.name == 'UPI') {
            copyToClipboard(data.value);
        } else if (data.name == 'Whatsapp') {
            window.open('https://api.whatsapp.com/send?phone=' + data.value + '&text=hii', '_blank', 'noreferrer');
        } else {
            window.open(data.value, '_blank', 'noreferrer');
        }
    }

    const copyToClipboard = (text) => {
        var textField = document.createElement('textarea')
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        toast('Link copied successfully')
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        SetInquiryForm(inputs => ({ ...InquiryForm, [event.target.name]: event.target.value }));
    }

    const ratingChanged = (newRating) => {
        SetInquiryForm(inputs => ({ ...InquiryForm, ['rating']: newRating }));
    };

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            InquiryForm.cardId = props.match.params.cardId;
            var res_data = await post(POST_ADD_REVIEW, InquiryForm, {});
            if (res_data.status) {
                SetInquiryForm({ profileId: UserInfo.profileId, rating: 2, name: '', message: '' });
                SetInquirySendSuccess(true);
                await get_profile();
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    const get_revies = async (load_more = 0) => {
        SetButtonDisabled(true);
        FilterData.page = (load_more > 0) ? FilterData.page : 0;
        var res_data = await post(GET_REVIEW, FilterData);
        if (res_data.status) {
            if (load_more > 0) {
                setReviewList([...ReviewList, ...res_data.data.review_list]);
            } else {
                setReviewList(res_data.data.review_list);
            }
            seShowLoadMore(res_data.data.load_more);
            SetFilterData(inputs => ({ ...FilterData, ['page']: res_data.data.page }));
            SetButtonDisabled(false);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    const load_more = async () => {
        get_revies(1);
    }

    return (
        <>
            {!isLoader && <Loader />}
            <section className="viee_profile" style={{ 'backgroundColor': `${UserInfo.theme_color}` }}>
                <div className="profile_box">
                    <div className='header'>
                        {!UserInfo.logo && <img src={Logo} />}
                        {UserInfo.logo && <img src={img_view(UserInfo.logo)} />}
                    </div>
                    <div className='user_info mt-3'>
                        <div className='text-center'>
                            <h5 className='m-0 mb-2'>{UserInfo.businessName}</h5>
                            <p className='mb-2'>{UserInfo.categoryName}</p>
                            {UserInfo.showFooterAddress <= 0 && <>
                                {UserInfo.addressLine1 && <p className='mb-0' style={{ whiteSpace: "pre-wrap" }} >{UserInfo.addressLine1}</p>}
                                {UserInfo.addressLine2 && <p className='mb-0' style={{ whiteSpace: "pre-wrap" }} >{UserInfo.addressLine2}</p>}
                                {(UserInfo.town || UserInfo.country || UserInfo.postCode) && <p className='mb-0'><span className='mr-2'>{UserInfo.town}</span> <span className='mr-2'>{UserInfo.country}</span> <span className='mr-2'>{UserInfo.postCode}</span></p>}
                            </>}
                            {/* {UserInfo.address && <p className='mb-2' style={{ whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: UserInfo.address }}></p>} */}
                        </div>
                    </div>
                    <hr />

                    <div className='pl-3 pr-3 pt-2 pb-2'>
                        {!InquirySendSuccess && <form className="av-invalid" onSubmit={handleSubmit}>
                            <div className="form-group mb-3">
                                <label className='mb-0'>Rate your recent experience</label>
                                <ReactStars
                                    count={5}
                                    onChange={ratingChanged}
                                    size={40}
                                    value={InquiryForm.rating}
                                    activeColor="#ffd700"
                                />
                            </div>
                            {InquiryForm.rating > 0 && <>
                                <div className="form-group mb-4">
                                    <input type='text' name="name" placeholder='Enter your name *' value={InquiryForm.name} onChange={handleInputChange} className="form-control" />
                                    {simpleValidator.current.message('name', InquiryForm.name, 'required')}
                                </div>
                                <div className="form-group mb-4">
                                    <input type='text' name="email" placeholder='Enter your email' value={InquiryForm.email} onChange={handleInputChange} className="form-control" />
                                    {/* {InquiryForm.email && simpleValidator.current.message('email', InquiryForm.email, 'email')} */}
                                </div>
                                <div className="form-group mb-3">
                                    <textarea name="message" placeholder='Write a review *' value={InquiryForm.message} onChange={handleInputChange} className="form-control" rows={5} style={{ "resize": "none" }} />
                                    {simpleValidator.current.message('message', InquiryForm.message, 'required')}
                                </div>
                                <div className="text-center mt-4">
                                    <button disabled={ButtonDisabled} type="submit" className='btn btn-secondary'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Submit</button>
                                </div>
                            </>}
                        </form>}
                        {InquirySendSuccess && <div className='text-center pt-5 pb-5'>
                            <svg width="50px" style={{ 'color': '#10b981' }} xmlns="http://www.w3.org/2000/svg" class="mb-4 h-10 w-10 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                            <h4 className='mb-3'>Thank you!</h4>
                            <p className='mb-0'>Your review has been successfully submitted.</p>
                        </div>}
                    </div>

                    <hr />
                    <div className='pl-3 pr-3 pt-2 pb-2'>
                        <div>
                            <h6 className='mb-3'><b>Contact Details</b></h6>
                            <ul className='link-list mb-0'>
                                {UserInfo.email && <li>
                                    <a href='javascript:;' onClick={link_clikc.bind(this, { name: 'Email', value: UserInfo.email })}>
                                        <div className='img'>
                                            <img src={email_icon} alt="email" />
                                        </div>
                                        <div style={{ 'float': 'left' }}>
                                            <h6 className='m-0'>{UserInfo.email}</h6>
                                            <p className='m-0'><small>Email</small></p>
                                        </div>
                                    </a>
                                </li>}
                                {UserInfo.showMobileNumber <= 0 && <>
                                    {UserInfo.telephoneNumber && <li>
                                        <a href='javascript:;' onClick={link_clikc.bind(this, { name: 'Phone', value: UserInfo.telephoneNumber })}>
                                            <div className='img'>
                                                <img src={phone_icon} alt="Phone Number" />
                                            </div>
                                            <div style={{ 'float': 'left' }}>
                                                <h6 className='m-0'>{UserInfo.telephoneNumber}</h6>
                                                <p className='m-0'><small>Telephone Number</small></p>
                                            </div>
                                        </a>
                                    </li>}
                                </>}
                            </ul>
                        </div>
                    </div>

                    {/* <hr />
                    <div className='pl-3 pr-3 pt-2 pb-2'>
                        <div className='row'>
                            {ReviewList && Object.entries(ReviewList).map(([key, review]) => (
                                <div className='col-sm-12' key={key}>
                                    {[...Array(review.rating)].map((x, i) =>
                                        <i className="fa fa-star mr-2" style={{ 'fontSize': '25px', 'color': 'rgb(255, 215, 0)' }} aria-hidden="true"></i>
                                    )}
                                    {[...Array(5 - review.rating)].map((x, i) =>
                                        <i className="fa fa-star mr-2" style={{ 'fontSize': '25px', 'color': '#808080' }} aria-hidden="true"></i>
                                    )}
                                    <div className='mt-3'>
                                        <p>
                                            {review.name}
                                            <span className='pull-right'>{review.date}</span>
                                        </p>
                                    </div>
                                    <p className='mt-3' style={{ whiteSpace: "pre-wrap" }}>{review.review}</p>
                                    <hr />
                                </div>
                            ))}
                        </div>
                        {ShowLoadMore && <div className='row'>
                                <div className="col-sm-12 text-center mt-3">
                                    <button onClick={load_more} disabled={ButtonDisabled} className="btn btn-primary btn-sm load_more_btn pl-4 pr-4">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>}Load More...</button>
                                </div>
                            </div>}
                    </div> */}
                </div>
            </section>
        </>
    );
}
export default withRouter(Services)
