import React, { useEffect, useState } from 'react';
import { useHistory, Link, withRouter } from "react-router-dom";



import { post } from '../../helpers/api_helper';
import { GET_BLOGS } from '../../helpers/url_helper';


const Home = props => {
    let history = useHistory();

    const [blogList, setBlogList] = useState([]);

    useEffect(() => {
        async function get_detaile() {
            var res_data = await post(GET_BLOGS, {});
            if (res_data.status) {
                setBlogList(res_data.data.blog_list);
            }
        }
        get_detaile();
    }, []);


    return (
        <>
          

            {/* LATEST NEWS */}
            < section className="section news" >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center" style={{ flexWrap: "wrap" }}>
                                <div>
                                    <h2>Latest News from our Team</h2>
                                </div>
                                <div>
                                    {/* <Link to='/blogs'  >View All Blogs </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        {blogList && blogList.map((blog,key) => {
                            return(<>
                                <div className="col-12 col-lg-4 mt-3" key={key}>
                                    <div className="news-body">
                                        <div className="news-img">
                                            <img src={blog.image} alt="news" />
                                        </div>
                                        <div className="news-action d-flex justify-content-between my-3">
                                            <div>
                                                {/* <span className='mr-2'>Branding</span> */}
                                                {/* <span href="">Web Design</span> */}
                                            </div>
                                            <div>
                                                <label>{blog.createdAt}</label>
                                            </div>
                                        </div>
                                        <div className="news-title">
                                            <h4>{blog.title}</h4>
                                        </div>
                                        <div className="news-btn mt-3">
                                            <button type='button' onClick={() => {history.push(`/blogs/${blog.slug}`);}} >Read More</button>
                                        </div>
                                    </div>
                                </div>
                            </>)
                        })}
                      
                    </div>
                </div>
            </section>



        </>
    );
}
export default withRouter(Home)