
const getToken = () => {
    return localStorage.getItem('web_token') || null;
}

const getUserDetail = () => {
    return JSON.parse(localStorage.getItem('web_user_detail'));
}

const getUserId = () => {
    return (localStorage.getItem('web_user_detail'))? JSON.parse(localStorage.getItem('web_user_detail'))['user_id'] : 0;
}

const getUserType = () => {
    if(localStorage.getItem('web_user_detail')){
        return JSON.parse(localStorage.getItem('web_user_detail'))['user_role'];
    }else{
        return 0;
    }
}

const removeUserSession = () => {
    localStorage.removeItem('web_token');
    localStorage.removeItem('web_user_detail');
}

const setUserSession = (token, user) => {
    localStorage.setItem('web_token', token);
    localStorage.setItem('web_user_detail', JSON.stringify(user));
}

const is_login = () => {
    if (localStorage.getItem('web_token')) {
        return true;
    } else {
        return false;
    }
}

const amountFormat = (amount) => {
    return parseFloat(amount).toFixed(2);
}

const img_view = (img) => {
    return process.env.REACT_APP_IMG_URL+'uploads/'+img
}

// arrayColumn
const arrayColumn = (column_array, column) => {
    const arrayColumn = (arr, n) => arr.map(x => x[n]);
    return arrayColumn(column_array, column);
}

export { arrayColumn, img_view,amountFormat, getUserType,getToken, removeUserSession, setUserSession, is_login,getUserDetail, getUserId };