import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Link, withRouter } from "react-router-dom";
import { UserInfoContext } from "../../layouts/App_route";

import { post } from '../../helpers/api_helper';
import { GET_All_CARDS, CANCEL_CARD } from '../../helpers/url_helper';
import { toast } from 'react-toastify';

import ProfileSettings from '../../component/ProfileSettings';
import Loader from "../../component/Loader";


const UserCards = props => {
    const { userSubscription, TotalCard } = useContext(UserInfoContext);
    let history = useHistory();
    const [isLoader, setLoader] = useState(0);
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [FilterData, SetFilterData] = useState({ limit: 300, page: 0, search: '' });
    const [ShowLoadMore, seShowLoadMore] = useState(false);
    const [cardsList, setCardsList] = useState([]);
    const [CardId, setCardId] = useState(0);

    useEffect(() => {
        async function get_detaile() {
            await get_cards();
            setLoader(1);
        }
        get_detaile();
    }, [])

    const get_cards = async (load_more = 0) => {
        SetButtonDisabled(true);
        FilterData.page = (load_more > 0) ? FilterData.page : 0;
        var res_data = await post(GET_All_CARDS, FilterData);
        if (res_data.status) {
            if (load_more > 0) {
                setCardsList([...cardsList, ...res_data.data.cards_list]);
            } else {
                setCardsList(res_data.data.cards_list);
            }
            seShowLoadMore(res_data.data.load_more);
            SetFilterData(inputs => ({ ...FilterData, ['page']: res_data.data.page }));
            SetButtonDisabled(false);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    // remove user role
    const cancel_card = async () => {
        var res_data = await post(CANCEL_CARD , {cardId : CardId});
        if (res_data.status) {
            await get_cards();
            toast(res_data.message);
        } else {
            toast.error(res_data.message);
        }
    }

    const load_more = async () => {
        get_cards(1);
    }

    return (
        <>
            {!isLoader && <Loader />}
            <ProfileSettings>
                <div className="row mb-3">
                    <div className="col-sm-6">
                        <h5 className="mb-0">Cards</h5>
                    </div>
                    <div className="col-sm-6 text-right">
                        {(userSubscription && (userSubscription.code == 'CardOnly'  || userSubscription.code == 'CardAndWidgets') && TotalCard < 25) && <Link to="/order-cards" className="c-btn-light radius-10 pr-3 pl-3 btn-sm">Add Person</Link>}
                    </div>
                </div>

                <div className="row ">
                    <div className='col-sm-12 col-lg-12 mb-4' style={{ 'minHeight': '30vh' }}>
                        <div className='table-responsive'>
                            <table className='table table-striped'>
                                <thead>
                                    <tr>
                                        <th>Sr.</th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cardsList.length > 0 && cardsList.map((value, index) => {
                                        return (<tr key={index}>
                                            <td>{++index}</td>
                                            <td>{value.firstName}</td>
                                            <td>{value.lastName}</td>
                                            <td>{value.order_date}</td>
                                            <td>
                                                {value.status == 'Active' && <span className='border-0 badge badge-success pt-1 pb-1'>Active</span>}
                                                {value.status == 'Cancel' && <span className='border-0 badge badge-danger pt-1 pb-1'>Cancel</span>}
                                            </td>
                                            <td>
                                                {value.status == 'Active' && <button onClick={() => { setCardId(value.cardId) }} data-toggle="modal" data-target="#exampleModal" type='button' className='btn btn-sm btn-danger pt-0 pb-0 pl-1 pr-1'>Cancel</button>}
                                                <Link to={`/order-cards?id=${value.cardId}&first_name=${value.firstName}&last_name=${value.lastName}`} className='btn btn-sm btn-success pt-0 pb-0 pl-1 pr-1 ml-3'>Replace</Link>
                                            </td>
                                        </tr>)
                                    })}
                                    {cardsList.length <= 0 && <tr>
                                        <td colSpan={6} className='align-middle'>
                                            <p className='mb-0 text-center'>No data found for the criteria</p>
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                        {ShowLoadMore && <div className='row'>
                            <div className="col-sm-12 text-center mt-3">
                                <button onClick={load_more} disabled={ButtonDisabled} className="btn btn-primary btn-sm load_more_btn pl-4 pr-4">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>}Load More...</button>
                            </div>
                        </div>}
                    </div>
                </div>
            </ProfileSettings>

            {/* deleteModal */}
            <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirm Cancel</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to cancel this card?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-danger" data-dismiss="modal">No</button>
                            <button onClick={cancel_card.bind(this)} data-dismiss="modal" className="btn btn-sm btn-primary c-btn-primary">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(UserCards)

