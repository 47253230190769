import React, { useEffect, useState } from 'react';
import { useHistory, Link, withRouter } from "react-router-dom";
import Slider from 'react-slick';

import Card from '../assets/images/home-card.png';

import Process1 from '../assets/images/process_1.png';
import Process2 from '../assets/images/process_2.png';
import Process3 from '../assets/images/process_3.png';
import Offer1 from '../assets/images/offer_1.png';
import Offer2 from '../assets/images/offer_2.png';
import Offer3 from '../assets/images/offer_3.png';
import Offer4 from '../assets/images/offer_4.png';
import WhyBusiness from '../assets/images/why_businesses.png';
import News1 from '../assets/images/news_1.png';
import News2 from '../assets/images/news_2.png';
import News3 from '../assets/images/news_3.png';
import RatingStar from '../assets/images/rating_star.png';
import { post } from '../helpers/api_helper';
import { GET_HOME_DATA } from '../helpers/url_helper';

const Home = props => {
    let history = useHistory();

    const [reviewList, setReviewList] = useState([
        {
            name: 'John Smith',
            review: 'Tap Review transformed how we collect feedback. Simple, effective, and our clients love the ease of use. Highly recommended!',
            businessName: "JS Plumbing",
            url_slug : '/'
        },
        {
            name: 'Emily Davis',
            review: 'Since using Tap Review, our customer engagement has soared. Its incredibly user-friendly and has significantly boosted our online presence.',
            businessName: "Davis Designs",
            url_slug : '/'
        },
        {
            name: 'Raj Patel',
            review: 'Tap Reviews seamless process for gathering reviews has helped our restaurant stand out. Excellent tool for businesses looking to enhance their reputation.',
            businessName: "Bombay Bistro",
            url_slug : '/'
        },
        {
            name: 'Samantha Lee',
            review: 'The difference Tap Review made is night and day. Our positive reviews have doubled, and customer trust is at an all-time high.',
            businessName: "Lee's Landscaping",
            url_slug : '/'
        },
        {
            name: 'Michael Thompson',
            review: 'Implementing Tap Review was a game-changer. Its not just a tool; its become an essential part of our growth strategy.',
            businessName: "Thompson Tech Solutions",
            url_slug : '/'
        },
        {
            name: 'Lucy Garcia',
            review: 'Tap Reviews innovative approach has made collecting client feedback a breeze. Its boosted our credibility and attracted more art lovers to our gallery.',
            businessName: "Garcia Gallery",
            url_slug : '/'
        },
    ]);
    const [blogList, setBlogList] = useState([]);

    useEffect(() => {
        async function get_detaile() {
            var res_data = await post(GET_HOME_DATA, {});
            if (res_data.status) {
                // setReviewList(res_data.data.review_list);
                setBlogList(res_data.data.blog_list);
            }
        }
        get_detaile();
    }, []);

    const settings = {
        infinite: false, // Infinite loop
        speed: 500, // Animation speed in milliseconds
        slidesToShow: 4, // Show 4 slides at once
        slidesToScroll: 1, // Scroll 1 slide at a time
        draggable: true, // Allow the slider to be draggable
        arrows: false, // Hide the next and previous buttons
        responsive: [
            {
                breakpoint: 768, // Adjust settings for screens smaller than 768px
                settings: {
                    slidesToShow: 1, // Show 1 slide at a time on small screens
                    slidesToScroll: 1,
                },
            },
        ],
    };


    return (
        <>
            {/* HERO */}
            <section className="section home-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-6 align-self-center mb-5 mb-lg-0 text-center text-lg-right image-box" >
                            <img src={Card} alt='card' />
                        </div>
                        <div className="col-md-12 col-lg-6 pl-md-5 align-self-center">
                            <h2 className='mb-4'>Increase Your business Growth</h2>
                            <p className='mb-4'>Join businesses like yours to effortlessly collect 5-star reviews and build a professional online presence</p>
                            <Link to='/sign-up' className="c-btn-light mb-3">See How it Works </Link>
                        </div>
                    </div>
                </div>
            </section>

            {/* PROCESS */}
            <section className="section process">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="d-flex justify-content-between" style={{ flexWrap: "wrap" }}>
                                <div>
                                    <h2>We offer the simplest process</h2>
                                </div>
                                <div>
                                    <Link to='/sign-up' className="c-btn-dark" >Get Started </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-12 col-lg-4 process-body mt-3">
                            <div className='process-line mb-3'>
                                <label htmlFor="">1</label>
                            </div>
                            <div className="process-card">
                                <div className="process-card-img-body p-3">
                                    <div className="process-img">
                                        <img src={Process1} alt="process1" />
                                    </div>
                                </div>
                                <div className="process-card-title my-3">
                                    <h3>You get a website</h3>
                                </div>
                                <div className="process-card-para">
                                    <p>create a personalized mini-website that showcases your business and collects reviews. This dedicated space highlights your services, making it easier for customers to learn about and engage with your brand.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 process-body mt-3">
                            <div className='process-line mb-3'>
                                <label htmlFor="">2</label>
                            </div>
                            <div className="process-card">
                                <div className="process-card-img-body p-3">
                                    <div className="process-img">
                                        <img src={Process2} alt="process2" />
                                    </div>
                                </div>
                                <div className="process-card-title my-3">
                                    <h3>You get a review card</h3>
                                </div>
                                <div className="process-card-para">
                                    <p>We provide a custom Tap Review Card, a simple yet powerful tool for gathering feedback. You tap a customers phone and it loads your website instantly</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 process-body mt-3">
                            <div className='process-line mb-3'>
                                <label htmlFor="">3</label>
                            </div>
                            <div className="process-card">
                                <div className="process-card-img-body p-3">
                                    <div className="process-img">
                                        <img src={Process3} alt="process3" />
                                    </div>
                                </div>
                                <div className="process-card-title my-3">
                                    <h3>Tap and collect reviews</h3>
                                </div>
                                <div className="process-card-para">
                                    <p>Holding your card over a an unlocked mobile phone will open your review page</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            {/* BEST OFFERS */}
            <section className="section offers">
                <div className='bg'>

                    <div className="container">
                        <div className="row mt-2">
                            <div className="col-12">
                                <h2 className='text-light'>The Best Offers from Tap Review</h2>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-12 col-md-6 col-lg-3 mt-4">
                                <div className="offer-box">
                                    <div className="offer-box-icon">
                                        <img src={Offer1} alt="offer" />
                                    </div>
                                    <div className="offer-box-title mt-2">
                                        <h4>A Review Page</h4>
                                    </div>
                                    <div className="offer-box-para">
                                        <p>Launch Your Online Presence: Get a sleek, customizable mini-website that beautifully showcases your business and services, making it easy for customers to find and trust you.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mt-4 ">
                                <div className="offer-box">
                                    <div className="offer-box-icon">
                                        <img src={Offer2} alt="offer" />
                                    </div>
                                    <div className="offer-box-title mt-2">
                                        <h4>Instant Reviews</h4>
                                    </div>
                                    <div className="offer-box-para">
                                        <p>Feedback at Your Fingertips: Utilize the Tap Review card to gather instant reviews from your customers, boosting your reputation with every tap.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mt-4">
                                <div className="offer-box">
                                    <div className="offer-box-icon">
                                        <img src={Offer3} alt="offer" />
                                    </div>
                                    <div className="offer-box-title mt-2">
                                        <h4>Review Widget</h4>
                                    </div>
                                    <div className="offer-box-para">
                                        <p>Showcase Success: Embed our dynamic review widget on your site to display real-time customer feedback, enhancing credibility and encouraging potential clients to choose you</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mt-4">
                                <div className="offer-box">
                                    <div className="offer-box-icon">
                                        <img src={Offer4} alt="offer" />
                                    </div>
                                    <div className="offer-box-title mt-2">
                                        <h4>Lead Generation</h4>
                                    </div>
                                    <div className="offer-box-para">
                                        <p>Turn Browsers into Buyers: Our platform not only showcases your business but also captures leads, helping you convert interest into sales seamlessly.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Why Businesses */}
            <section className="section why-businesses">
                <div className="container">
                    <div className="row">
                        <div className='col-12 text-center'>
                            <h2>Why Businesses Want us</h2>
                        </div>
                    </div>
                    <div className="row mt-3 mt-lg-5">
                        <div className="col-md-12 col-lg-6 align-self-center mb-5 mb-lg-0 text-center text-lg-right order-1 order-lg-1">
                            <div className="why-business-img">
                                <img src={WhyBusiness} alt='WhyBusiness' />
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6 align-self-center order-2 order-lg-2">
                            <div className='pl-0 pl-lg-5 '>
                                <h3 className='mb-4'>Just tap a phone and it works instantly</h3>
                                <p className='mb-4'>Streamline your lead management process with Tap Review’s user-friendly inbox. From initial inquiries to loyal clients, our inbox simplifies communication, helping you grow your business effortlessly</p>
                                <Link to='/sign-up' className="c-btn-light mb-5">Sign Up </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 mt-lg-5">
                        <div className="col-md-12 col-lg-6 align-self-center mb-5 mb-lg-0 text-center text-lg-right order-1 order-lg-2">
                            <div className="why-business-img">
                                <img src={WhyBusiness} alt='WhyBusiness' />
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6 align-self-center order-2 order-lg-1">
                            <div className='pr-0 pr-lg-5'>
                                <h3 className='mb-4'>Enquiries direct to your inbox</h3>
                                <p className='mb-4'>Streamline your lead management process with Tap Review’s user-friendly inbox. From initial inquiries to loyal clients, our inbox simplifies communication, helping you grow your business effortlessly</p>
                                <Link to='/sign-up' className="c-btn-light">Sign Up </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* SLIDER */}
            <section className='client-reviews'>
                {/* <div className="client-reviews"> */}
                <div className='col-12 text-center mb-5'>
                    <h2>What Our Clients Say</h2>
                </div>
                <Slider {...settings} >
                    {reviewList.map((review, index) => (
                        <div key={index} className="slide ">
                            <div className="review-box">
                                <h4>{review?.name}</h4>
                                <div className='reviews-rating mb-3'>
                                    {/* <img src={RatingStar} alt="reting star" /> */}
                                    <i className="fa fa-star mr-2" style={{ 'fontSize': '20px', 'color': '#f7b405' }} aria-hidden="true"></i>
                                    <i className="fa fa-star mr-2" style={{ 'fontSize': '20px', 'color': '#f7b405' }} aria-hidden="true"></i>
                                    <i className="fa fa-star mr-2" style={{ 'fontSize': '20px', 'color': '#f7b405' }} aria-hidden="true"></i>
                                    <i className="fa fa-star mr-2" style={{ 'fontSize': '20px', 'color': '#f7b405' }} aria-hidden="true"></i>
                                    <i className="fa fa-star mr-2" style={{ 'fontSize': '20px', 'color': '#f7b405' }} aria-hidden="true"></i>
                                </div>
                                <p>{review?.review}</p>
                                <div className='d-flex justify-content-end '>
                                    <Link to={`/${review.url_slug}`} target='_blank' className='text-dark'>{review?.businessName}</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                {/* </div> */}
            </section >

            {/* LATEST NEWS */}
            < section className="section news" >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center" style={{ flexWrap: "wrap" }}>
                                <div>
                                    <h2>Latest News from our Team</h2>
                                </div>
                                <div>
                                    <Link to='/blogs'  >View All Blogs </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        {blogList && blogList.map((blog, key) => {
                            return (<>
                                <div className="col-12 col-lg-4 mt-3" key={key}>
                                    <div className="news-body">
                                        <div className="news-img">
                                            <img src={blog.image} alt="news" />
                                        </div>
                                        <div className="news-action d-flex justify-content-between my-3">
                                            <div>
                                                {/* <span className='mr-2'>Branding</span> */}
                                                {/* <span href="">Web Design</span> */}
                                            </div>
                                            <div>
                                                <label>{blog.createdAt}</label>
                                            </div>
                                        </div>
                                        <div className="news-title">
                                            <h4>{blog.title}</h4>
                                        </div>
                                        <div className="news-btn mt-3">
                                            <button type='button' onClick={() => { history.push(`/blogs/${blog.slug}`); }} >Read More</button>
                                        </div>
                                    </div>
                                </div>
                            </>)
                        })}
                    </div>
                </div>
            </section>

            {/* SUBSCRIBE FORM */}
            <section className="section subscribe pb-0">
                <div className='bg'>

                    <div className="container">
                        <div className="row mt-2">
                            <div className="col-12">
                                <h2 className='text-light text-center'>Subscribe to our Newsletter</h2>
                            </div>
                        </div>
                        <form className='w-100 px-0 px-md-5 mt-5 mb-2 mx-0'>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1" className='text-light'>First Name</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter name" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div class="form-group">
                                        <label for="exampleInputPassword1" className='text-light'>Email</label>
                                        <input type="email" class="form-control" id="exampleInputPassword1" placeholder="Enter email" />
                                    </div>
                                </div>
                                <div className="col-12 d-flex justify-content-end mt-3">
                                    <Link to='/sign-up' className="c-btn-light">Subscribe </Link>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </section>


        </>
    );
}
export default withRouter(Home)