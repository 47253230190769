import React, { useContext } from "react"
import { withRouter } from "react-router-dom"
import { removeUserSession } from '../../helpers/Helper';
import { useHistory } from 'react-router-dom';
import { UserInfoContext } from "../../layouts/App_route";

const Logout = props => {
  const Context_value = useContext(UserInfoContext);
  let history = useHistory();
  removeUserSession();
  Context_value.setIsLogin(false);

  history.push("/login");
  return <></>
}

export default withRouter(Logout)
