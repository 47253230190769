import React, { useContext, useEffect } from 'react';
import { Link, NavLink } from "react-router-dom";
import { UserInfoContext } from "../layouts/App_route";
import Logo from './../assets/images/logo.svg';

export default function Header(props) {
    const { IsLogin } = useContext(UserInfoContext);

    useEffect(() => {
        async function get_detaile() {
        }
        get_detaile();
    })

    return (<>
        <header className='fix-header'>
            <div className="heade">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light p-0">
                        <div className="form-inline my-2 my-lg-0">
                            <button className="navbar-toggler navbar-toggler-right collapsed " type="button" data-toggle="collapse"
                                data-target="#Navigation" aria-controls="Navigation" aria-expanded="false"
                                aria-label="Toggle navigation"><i className="fa fa-bars" aria-hidden="true"></i></button>
                        </div>
                        <Link className="navbar-brand " to="/">
                            <img src={Logo} alt="Tap Review" className='logo' />
                        </Link>
                        <ul className="navbar-nav ml-auto sign-up-btn-show">
                            <li className={`nav-item`}><NavLink to="/sign-up" className="nav-link sign-up mb-0">Sign Up</NavLink></li>
                        </ul>
                        <div className="collapse navbar-collapse" id="Navigation">
                            <ul className="navbar-nav pt-3 pt-lg-0 ml-auto">
                                <li className={`nav-item`}><NavLink to="/about" className="nav-link">About Us </NavLink></li>
                                <li className={`nav-item`}><NavLink to="/pricing" className="nav-link">Pricing</NavLink></li>
                                <li className={`nav-item`}><NavLink to="/faqs" className="nav-link">FAQs</NavLink></li>
                                <li className={`nav-item`}><NavLink to="/contact" className="nav-link">Contact Us</NavLink></li>
                                {!IsLogin && <>
                                    <li className={`nav-item`}><NavLink to="/sign-up" className="nav-link sign-up sign-up-btn-hide">Sign Up</NavLink></li>
                                    <li className={`nav-item`}><NavLink to="/login" className="nav-link sign-in">Log In</NavLink></li></>}
                                {IsLogin && <> <li className={`nav-item`}><NavLink to="/logout" className="nav-link">Log Out</NavLink></li>
                                    <li className={`nav-item shopping-cart`}><NavLink to="/dashboard" className="nav-link sign-up"><i class="fa fa-user-circle" aria-hidden="true"></i></NavLink></li></>}
                            </ul>
                        </div>

                    </nav>
                </div>
            </div>
        </header>
        <div style={{ 'minHeight': '83px' }}></div>
    </>
    );
}