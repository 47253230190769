import React, { useState, useEffect, useContext } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import { UserInfoContext } from "../layouts/App_route";
import { GET_PRICING } from '../helpers/url_helper';
import { get } from '../helpers/api_helper';
import Loader from "../component/Loader";

import price1 from '../assets/images/price_1.svg';
import price2 from '../assets/images/price_2.svg';
import price3 from '../assets/images/price_3.svg';

const Pricing = props => {
    const { IsLogin } = useContext(UserInfoContext);
    let history = useHistory();
    const [isLoader, setLoader] = useState(0);

    const [card, setCard] = useState({ 'type': 'monthly', 'plan': '', 'selectCard': '1' });
    const [faqsOpen, setFaqsOpen] = useState();
    const [plan, setPlan] = useState({});

    useEffect(() => {
        async function get_detaile() {
            var res_data = await get(GET_PRICING, {});
            if (res_data.status) {
                setPlan(res_data.data);
                setLoader(1);
            }
        }
        get_detaile();
    }, []);

    const faqOpn = (index) => {
        setFaqsOpen(index);
    };

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'type') {
            setCard(inputs => ({ ...card, [event.target.name]: (event.target.checked) ? 'annually' : 'monthly' }));
        } else {
            setCard(inputs => ({ ...card, [event.target.name]: event.target.value }));
        }
    }

    const AddToCart = (type) => {
        if (IsLogin) {
            card.plan = type
            card.selectCard = (type == 'CardOnly')? 1 : (type == 'CardAndWidgets')? 2 : 0;
            localStorage.setItem('cart', JSON.stringify(card));
            history.push("/cart");
        } else {
            history.push("/sign-up");
        }
    }
    return (
        <>
            {!isLoader && <Loader />}
            <section className='section pricing'>
                <div className="container">
                    <div className='row mb-5 mt-5'>
                        <div className='col-sm-12 text-center mb-4'>
                            <h2>Choose the perfect plan for your business</h2>
                        </div>
                        {/* <div className='col-sm-12 text-center'>
                            Billed monthly
                            <label className="switch ml-3 mr-3">
                                <input name="type" type="checkbox" onChange={handleInputChange} checked={(card.type == 'monthly') ? false : true} />
                                <span className="slider round"></span>
                            </label>
                            Annually
                        </div> */}
                    </div>
                    <div className="row mb-5">
                        <div className='col-md-12 col-lg-4 mb-3'>
                            <div className="price_box">
                                <div className="text-center">
                                    <img className="mb-4" src={price1} alt='' />
                                    <h4 className='text-light-blue'>Tap Review Card </h4>
                                    <p className="text-sky">Ignite Your Online Presence</p>
                                </div>
                                <div className="mt-3">
                                    <ul>
                                        <li>1 Tap Review Card</li>
                                        <li>Instant Reviews</li>
                                        <li>Personalised Website to Spotlight Your Brand</li>
                                        <li>Custom Review Page</li>
                                        <li>Ad-Free for a Premium Look</li>
                                        <li>Priority Support & Exclusive Resources</li>
                                    </ul>
                                </div>
                                <div className='text-center'>
                                    <h4 className='mb-4'>£29 p/m</h4>
                                    {/* <Link className="c-btn-light mb-3" to="/">Sign Up <i className="fa fa-long-arrow-right ml-2" aria-hidden="true"></i></Link> */}
                                    <button className="c-btn-light mb-3" onClick={AddToCart.bind(this, 'CardOnly')}>{(IsLogin)? 'Buy Now' : 'Sign Up Now'} <i className="fa fa-long-arrow-right ml-2" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-4 mb-3'>
                            <div className="price_box">
                                <div className="text-center">
                                    <img className="mb-4" src={price2} alt='' />
                                    <h4 className='text-light-blue'>Review Widgets</h4>
                                    <p className="text-sky">Elevate Your Reputation</p>
                                </div>
                                <div className="mt-3">
                                    <ul>
                                        <li>Review Widgets</li>
                                        <li>Instant Reviews</li>
                                        <li>Personalised Website to Spotlight Your Brand</li>
                                        <li>Custom Review Page</li>
                                        <li>Ad-Free for a Premium Look</li>
                                        <li>Priority Support & Exclusive Resources</li>
                                    </ul>
                                </div>
                                <div className='text-center'>
                                    <h4 className='mb-4'>£99 p/m</h4>
                                    {/* <select className='form-control mb-4' onChange={handleInputChange} name='selectCard' value={card.selectCard}>
                                        {plan?.proPlan?.price && Object.entries(plan?.proPlan?.price).map(([key, price]) => (
                                            <option value={price.card}>{price.card} {price.card == 1 ? `Card` : `Cards`} £{(card.type == 'monthly') ? parseFloat(price.monthlyPrice).toFixed(2) : parseFloat(price.AnnuallyPrice).toFixed(2)}</option>
                                        ))}
                                    </select> */}
                                    {/* <h4 className='mb-4'>£{plan?.proPlan?.price}pm</h4> */}
                                    <button className="c-btn-light mb-3" onClick={AddToCart.bind(this, 'WidgetsOnly')}>{(IsLogin)? 'Buy Now' : 'Sign Up Now'} <i className="fa fa-long-arrow-right ml-2" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-4 mb-3'>
                            <div className="price_box">
                                <div className='text-center'>
                                    <img className="mb-4" src={price3} alt='' />
                                    <h4 className='text-light-blue' style={{'fontSize' : '26px'}}>Review Widgets & Card</h4>
                                    <p className="text-sky">Unleash Collective Brilliance</p>
                                </div>
                                <div className="mt-3">
                                    <ul>
                                        <li>Review Widgets & Card</li>
                                        <li>2 Tap Review Cards & Website Widgets</li>
                                        <li>Instant Reviews</li>
                                        <li>Personalised Website to Spotlight Your Brand</li>
                                        <li>Custom Review Page</li>
                                        <li>Ad-Free for a Premium Look</li>
                                        <li>Priority Support & Exclusive Resources</li>
                                    </ul>
                                </div>
                                <div className='text-center'>
                                    <h4 className='mb-4'>£149 p/m</h4>
                                    <button className="c-btn-light mb-3" onClick={AddToCart.bind(this, 'CardAndWidgets')}>{(IsLogin)? 'Buy Now' : 'Sign Up Now'} <i className="fa fa-long-arrow-right ml-2" aria-hidden="true"></i></button>
                                    {/* <Link className="c-btn-light mb-3" to="/contact">Contact Sales <i className="fa fa-long-arrow-right ml-2" aria-hidden="true"></i></Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className='section gb_blue pricing_faq'>
                <div className="container">
                    {/* <div className='row'>
                        <div className='col-sm-12'>
                            <p className='text-white'>Unlock Your Full Potential with our Premium Membership</p>
                            <p className='text-white'>Mini-Website Creation Craft a dedicated space for your business! Set up with ease, showcase your services, and impress potential clients without the tech headaches.</p>
                            <p className='text-white'>Enhanced Visibility Stand out from the crowd! With our premium features, your business shines brighter, grabbing more eyes and turning them into clients.</p>
                            <p className='text-white'>Advanced Analytics Knowledge is power! Dive deep into insights, understand your audience better, and refine your strategies for even bigger success.</p>
                        </div>
                    </div> */}
                    <div className='row mb-5 mt-5'>
                        <div className='col-sm-12 text-center'>
                            <h2 className='text-white'>Frequently Asked Questions</h2>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12'>
                        <div className='faqs'>
                                <div className={"faq " + (faqsOpen === 1 ? "open" : "")}>
                                    <div className='faq-question' onClick={faqOpn.bind(this,1)}><h5 className='text-white'>How do I use the card?</h5></div>
                                    <div className='faq-answer'>
                                        <p className='text-white'>Got your Tap Review card? Cool! Just hand it over to your customer, and they'll tap it with their phone. Boom, they're on your page ready to drop a review or check out what you do. Need to see how click here! </p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className='faqs'>
                                <div className={"faq " + (faqsOpen === 2 ? "open" : "")}>
                                    <div className='faq-question' onClick={faqOpn.bind(this,2)}><h5 className='text-white'>How do I create my site?</h5></div>
                                    <div className='faq-answer'>
                                        <p className='text-white'>Easy-peasy. Once you're in with your account, you'll see steps to set up your page. Just pop in your details, some cool pics, and what you offer. No tech wizardry needed – promise!</p>
                                    </div>
                                </div>
                            </div>

                            <div className='faqs'>
                                <div className={"faq " + (faqsOpen === 3 ? "open" : "")}>
                                    <div className='faq-question' onClick={faqOpn.bind(this,3)}><h5 className='text-white'>Who uses tap review?</h5></div>
                                    <div className='faq-answer'>
                                        <p className='text-white'>Anyone and everyone! Whether you're fixing sinks, serving coffee, or giving fab makeovers – if you want feedback and a quick spot online, Tap Review's for you.</p>
                                    </div>
                                </div>
                            </div>

                            <div className='faqs'>
                                <div className={"faq " + (faqsOpen === 4 ? "open" : "")}>
                                    <div className='faq-question' onClick={faqOpn.bind(this,4)}><h5 className='text-white'>Where can I post my review link?</h5></div>
                                    <div className='faq-answer'>
                                        <p className='text-white'>Anywhere you fancy! Chuck it on your Facebook, Instagram, or Twitter. Slip it into your emails or even print it on your flyers. The more folks see it, the more feedback you'll get.</p>
                                    </div>
                                </div>
                            </div>

                            <div className='faqs'>
                                <div className={"faq " + (faqsOpen === 5 ? "open" : "")}>
                                    <div className='faq-question' onClick={faqOpn.bind(this,5)}><h5 className='text-white'>How can I get more clients?</h5></div>
                                    <div className='faq-answer'>
                                        <p className='text-white'>Rack up those positive vibes with reviews! Ask your happy peeps to leave feedback and share your page link. The more thumbs-ups you get, the more peeps will wanna check you out. Use all the cool features on Tap Review to show off what you can do!</p>
                                    </div>
                                </div>
                            </div>

                            <div className='faqs'>
                                <div className={"faq " + (faqsOpen === 6 ? "open" : "")}>
                                    <div className='faq-question' onClick={faqOpn.bind(this,6)}><h5 className='text-white'>How does my lead inbox work?</h5></div>
                                    <div className='faq-answer'>
                                        <p className='text-white'>Think of your lead inbox as your chatroom for business. Whenever someone's curious about what you do and drops a message on your page, it pops up there. It's like getting a heads-up every time someone's interested.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default withRouter(Pricing)