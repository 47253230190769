import React, { useState, useRef, useContext } from 'react';
import { useHistory, withRouter, Link } from "react-router-dom";
import { post } from '../../helpers/api_helper';
import { POST_LOGIN, GET_USER_ACTIVE_SUBSCRIPTION } from '../../helpers/url_helper';
import { setUserSession, getToken } from '../../helpers/Helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { UserInfoContext } from "../../layouts/App_route";
import GoogleIcon from '../../assets/images/register_google.png'
import MicrosoftIcon from '../../assets/images/register_microsoft.png'

const Contact = props => {
    const Context_value = useContext(UserInfoContext);

    let history = useHistory();
    if (getToken()) {
        history.push("/dashboard");
    }

    const simpleValidator = useRef(new SimpleReactValidator());

    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [EmailSend, SetEmailSend] = useState(false);
    const [login_inputs, setInputs] = useState({ email: '', password: '' });
    const [, forceUpdate] = useState();

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_LOGIN, login_inputs, {});
            if (res_data.status) {
                if (res_data.email_send) {
                    SetEmailSend(true);
                } else {
                    setUserSession(res_data.data.token, res_data.data);
                    var res_data = await post(GET_USER_ACTIVE_SUBSCRIPTION, {});
                    if (res_data.status) {
                        Context_value.setUserSubscription(res_data.data.detail)
                    }
                    Context_value.setIsLogin(true);
                    history.push("/dashboard");
                    toast(res_data.message);
                }
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...login_inputs, [event.target.name]: event.target.value }));
    }

    return (
        <>
            <section className='section contact-us'>
                <div className="container">
                    <div className='row justify-content-center'>
                        {/* <div className='col-sm-3'></div> */}
                        <div className='col-md-6'>
                            <div className='white-box'>
                                <form onSubmit={handleSubmit}>
                                    <div className='row '>
                                        <div className='col-sm-12 mb-4'>
                                            <h2 className='text-center'>Sign In</h2>
                                        </div>
                                        <div className='col-sm-12 mb-2'>
                                            <div className='form-group'>
                                                <label>Email</label>
                                                <input type='text' name="email" value={login_inputs.email} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('email', login_inputs.email, 'required|email')}
                                            </div>
                                            <div className='form-group mb-4'>
                                                <label>Password</label>
                                                <input type='password' name="password" value={login_inputs.password} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('password', login_inputs.password, 'required')}
                                            </div>
                                            <div className='form-group mb-4 float-right'>
                                                <Link to="/forget-password" className="text-black">Forgot Password</Link>
                                            </div>
                                            <div className='form-group'>
                                                <button disabled={ButtonDisabled} className="c-btn-light radius-10 pr-5 pl-5 btn-block mb-3">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin mr-2"></i>} Sign In</button>
                                                {EmailSend && <p style={{ 'color': '#27abc9' }}>A verification mail has been sent to your email account. Please check your inbox to verify.</p>}
                                                <p className='text-center'>Don’t have an account?  <Link to="/sign-up" className="text-black"><span style={{ fontWeight: 600 }}> Sign up</span></Link></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 mt-2">
                                            <div className="d-flex justify-content-center">
                                                <hr class="divider" />
                                                <p class="or">OR</p>
                                                <hr class="divider" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 mt-2">
                                            <button className='register-btn'>
                                                <img src={GoogleIcon} alt="google" style={{ height: "30px" }} />
                                            </button>
                                        </div>
                                        <div className="col-6 mt-2">
                                            <button className='register-btn'>
                                                <img src={MicrosoftIcon} alt="microsoft" style={{ height: "30px" }} />
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/* <div className='col-sm-3'></div> */}
                    </div>
                </div>
            </section >

        </>
    );
}
export default withRouter(Contact)

