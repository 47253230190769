import React, { useState, useEffect, useContext } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import { GET_PRICING, GET_USER_ACTIVE_SUBSCRIPTION } from '../helpers/url_helper';
import { get, post } from '../helpers/api_helper';
import { Link } from "react-router-dom";
import {UserInfoContext} from "../layouts/App_route";


const Cart = props => {
    let history = useHistory();
    const Context_value = useContext(UserInfoContext);


    const [planName, setPlanName] = useState('');

    useEffect(() => {
        async function get_detaile() {
            var user_cart = localStorage.getItem('cart');
            user_cart = (user_cart) ? JSON.parse(user_cart) : '';
            if (!user_cart) {
                history.push("/pricing");
            }else{
                var res_data = await get(GET_PRICING, {});
                if (res_data.status) {
                    setPlanName((res_data.data[user_cart.plan].title) ? res_data.data[user_cart.plan].title : 0);
                    localStorage.removeItem('cart');

                    var res_data = await post(GET_USER_ACTIVE_SUBSCRIPTION, {});
                    if (res_data.status) {
                        Context_value.setUserSubscription(res_data.data.detail)
                    }
                }
            }
          
        }
        get_detaile();
    }, [])

    return (
        <>
            <section className='section pricing'>
                <div className="container">
                    <div className="row mb-5">
                        <div className='col-lg-3'></div>
                        <div className='col-md-12 col-lg-6 mb-3'>
                            <div className="bg_white p-4">
                                <div className='text-center mt-5'>
                                    <svg className='mb-4' xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="144" height="144" viewBox="0 0 48 48">
                                        <path fill="#c8e6c9" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path><path fill="#4caf50" d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"></path>
                                    </svg>
                                    <h2 className='text-light-blue mb-4'>Congratulations!</h2>
                                    <p className='mb-4'>{planName} has been successfully activated.</p>
                                    <Link to="/dashboard" className="c-btn-light mb-3" > Dashboard </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    );
}
export default withRouter(Cart)