import React, { createContext, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Route } from 'react-router';
import { is_login } from "../helpers/Helper";
import { post } from '../helpers/api_helper';
import { GET_USER_ACTIVE_SUBSCRIPTION } from '../helpers/url_helper';

const UserInfoContext = createContext();

const AppRoute = ({ component: Component, layout: Layout, CheckAuthGuard: CheckAuthGuard = false, ...rest }) => {
    let history = useHistory();
    const [IsLogin, setIsLogin] = useState(false);
    const [UserSubscription, setUserSubscription] = useState({});
    const [TotalCard, setTotalCard] = useState(0);

    useEffect(async () => {
        setIsLogin(is_login());
        if (CheckAuthGuard) {
            if (!is_login()) {
                history.push("/");
            } else {
                var res_data = await post(GET_USER_ACTIVE_SUBSCRIPTION, {});
                if (res_data.status) {
                    setUserSubscription(res_data.data.detail)
                    setTotalCard(res_data.data.total_card)
                }
            }
        }
    }, [])

    return (
        <UserInfoContext.Provider value={{ IsLogin: IsLogin, setIsLogin: setIsLogin, TotalCard: TotalCard, setTotalCard: setTotalCard, userSubscription: UserSubscription, setUserSubscription: setUserSubscription }}>
            <Route
                {...rest}
                render={props => (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                )}
            />
        </UserInfoContext.Provider>
    );
}
export default AppRoute;
export { UserInfoContext }