import axios from "axios"
import { removeUserSession } from './Helper';

const axiosApi = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		'X-Requested-With': 'XMLHttpRequest',
		'Content-Type': 'application/json',
		'appversion': '1.1'
		// Authorization: localStorage.getItem('token')
	},
	responseType: 'json',
})



export async function get(url, config = {}) {
	axiosApi.defaults.headers.Authorization = localStorage.getItem('web_token');
	var api_res = await axiosApi.get(url, { ...config }).then(response => response.data).catch(error => error)
	if (api_res.response && api_res.response.status === 401) {
		removeUserSession();
		window.location.reload();
	} else {
		return api_res
	}
}

export async function post(url, data, config = {}) {
	axiosApi.defaults.headers.Authorization = localStorage.getItem('web_token');
	var api_res = await axiosApi
		.post(url, { ...data }, { ...config })
		.then(response => response.data)
		.catch(error => error)
	if (api_res.response && api_res.response.status === 401) {
		removeUserSession();
		window.location.reload();
	} else {
		return api_res
	}
}

export async function put(url, data, config = {}) {
	axiosApi.defaults.headers.Authorization = localStorage.getItem('web_token');
	var api_res = await axiosApi
		.put(url, { ...data }, { ...config })
		.then(response => response.data)
		.catch(error => error)
	if (api_res.response && api_res.response.status === 401) {
		removeUserSession();
		window.location.reload();
	} else {
		return api_res
	}
}

export async function del(url, config = {}) {
	axiosApi.defaults.headers.Authorization = localStorage.getItem('web_token');
	var api_res = await axiosApi
		.delete(url, { ...config })
		.then(response => response.data)
		.catch(error => error)
	if (api_res.response && api_res.response.status === 401) {
		removeUserSession();
		window.location.reload();
	} else {
		return api_res
	}
}
