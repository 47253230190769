import React, { useState, useContext, useRef, useEffect } from 'react';
import { useHistory, Link, withRouter } from "react-router-dom";

import { post, del } from '../../../helpers/api_helper';
import { GET_BUSINESS_PROFILE_DETAILES, POST_SAVE_BUSINESS_PROFILE } from '../../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { UserInfoContext } from "../../../layouts/App_route";
import Loader from "../../../component/Loader";


const BusinessDetails = props => {
    let history = useHistory();
    const { userSubscription } = useContext(UserInfoContext);
    const [isLoader, setLoader] = useState(0);

    const simpleValidator = useRef(new SimpleReactValidator());
    const [form_inputs, setInputs] = useState({
        faq: []
    });
    const [, forceUpdate] = useState();
    const [ButtonDisabled, SetButtonDisabled] = useState(false);

    useEffect(() => {
        async function get_detaile() {
            await get_profile_info();
        }
        get_detaile();
    }, [])

    const get_profile_info = async () => {
        var res_data = await post(GET_BUSINESS_PROFILE_DETAILES, {});
        if (res_data.status) {
            setInputs(res_data.data.detail);
            setLoader(1);
        } else {
            toast.error(res_data.message);
        }
    }
    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            form_inputs.type = 'faq';
            var res_data = await post(POST_SAVE_BUSINESS_PROFILE, form_inputs, {});
            if (res_data.status) {
                await get_profile_info();
                props.call_back(5);
                // toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    //addRow
    const addRow = (type) => {
        if (type === 'faq') {
            var data = (form_inputs.faq) ? form_inputs.faq : [];
            data.push({ question: '', answer: '' });
            setInputs(inputs => ({ ...form_inputs, [type]: data }));
        }
    }

    //handleInputChangeMultiple
    const handleInputChangeMultiple = (index, event) => {
        var name = event.target.name;
        if (name === 'question' || name === 'answer') {
            form_inputs.faq[index][name] = event.target.value;
            setInputs(inputs => ({ ...form_inputs, ['faq']: form_inputs.faq }));
        }
    }

    const removeRow = (index, type) => {
        if (type === 'faq') {
            var data = form_inputs.faq;
            data.splice(index, 1);
            setInputs(inputs => ({ ...form_inputs, ['faq']: data }));
        }
    }


    return (
        <>
            {!isLoader && <Loader />}
            <div className='row mb-4'>
                <div className="col-sm-8">
                    <h4 className="mb-0">Add FAQs</h4>
                </div>
                <div className="col-sm-4 text-sm-right">
                    <p className='mb-0 pt-2'>Current Plan: <b>{(userSubscription && userSubscription.shortName) ? userSubscription.shortName : 'Free'}</b></p>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                {form_inputs.faq && Object.entries(form_inputs.faq).map(([key, faq]) => (
                    <div className='row mb-3 mb-sm-0 mb-md-0 mb-lg-0' key={key}>
                        <div className='col-10 col-sm-11'>
                            <div className='row'>
                                <div className='col-sm-5'>
                                    <div className='form-group'>
                                        <label className='w-100'>{parseInt(key) + 1} Question </label>
                                        <input type='text' name="question" onChange={handleInputChangeMultiple.bind(this, key)} value={faq.question} className="form-control"
                                            placeholder='Get Better enquiries - detail your most asked questions. ' />
                                    </div>
                                </div>
                                <div className='col-sm-7'>
                                    <div className='form-group'>
                                        <label className='w-100'>Answer </label>
                                        <textarea name="answer" rows={3} onChange={handleInputChangeMultiple.bind(this, key)} value={faq.answer} className="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-2 col-sm-1 pt-4 pt-sm-4 pt-md-4 pt-lg-4'>
                            <button type="button" onClick={removeRow.bind(this, key, 'faq')} className="btn btn-sm btn-outline-dark hover-none mt-2"><i className="fa fa-trash-o" aria-hidden="true"></i></button>
                        </div>
                    </div>
                ))}
                <div className='row'>
                    <div className='col-sm-12 text-right'>
                        <button type='button' onClick={addRow.bind(this, 'faq')} className='add_more_btn'><i className="fa fa-plus-square-o mr-2" aria-hidden="true"></i> Add More Questions</button>
                    </div>
                </div>

                <div className='row mt-5'>
                    <div className='col-sm-6'>
                        <button type='button' onClick={() => { history.push("/dashboard"); }} disabled={ButtonDisabled} className="c-btn-blue-outline btn-md pt-2 pb-2 pl-3 pr-3 radius-10 mb-3 c-btn-block"><i className="fa fa-long-arrow-left mr-2" aria-hidden="true"></i>  Back To Dashboard </button>
                        {/* <button type='button' onClick={() => { props.call_back(3) }} disabled={ButtonDisabled} className="c-btn-blue-outline btn-md pt-2 pb-2 pl-3 pr-3 radius-10 mb-3 c-btn-block"><i className="fa fa-long-arrow-left mr-2 text-white" aria-hidden="true"></i> Back </button> */}
                    </div>
                    <div className='col-sm-6 text-right'>
                        <button type='submit' disabled={ButtonDisabled} className="c-btn-blue btn-md pt-2 pb-2 pl-3 pr-3 radius-10 mb-3 c-btn-block">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin mr-1"></i>} Save and Continue <i className="fa fa-long-arrow-right ml-2 text-white" aria-hidden="true"></i></button>
                    </div>
                </div>
            </form >
        </>
    );
}
export default withRouter(BusinessDetails)

