import React from 'react';
import { Link } from "react-router-dom";

export default function footerSignUp(props) {

    return (
        <section>
            <div className="container">
                <div className='ready-to-unleash text-center'>
                    <h2 className='mb-3'>Ready to Be the UK’s Next Success Story?</h2>
                    <p className='text-white mb-4'>Embrace the future of reviews and digital presence with Tap Review</p>
                    <Link to='/sign-up' className="c-btn-light">Sign Up <i className="fa fa-long-arrow-right ml-2" aria-hidden="true"></i></Link>
                </div>
            </div>
        </section>
    );
}