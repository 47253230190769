import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import { GET_BLOG_DETAIL } from '../../helpers/url_helper';
import { post } from '../../helpers/api_helper';
import Loader from "../../component/Loader";
import { Helmet } from "react-helmet";

const Home = props => {
    let history = useHistory();
    const [isLoader, setLoader] = useState(0);
    const [blogInfo, setBlogInfo] = useState([]);

    useEffect(() => {
        async function get_detaile() {
            var res_data = await post(GET_BLOG_DETAIL, { slug: props.match.params.slug });
            if (res_data.status) {
                setBlogInfo(res_data.data.blog_info);
            } else {
                history.push("/");
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.slug]);

    if (!isLoader) {
        return (<Loader />)
    }
    return (
        <>
            <Helmet>
                <title>Tap Review | {blogInfo.title}</title>
                <meta name="title" content={blogInfo.metaTitle} />
                <meta name="description" content={blogInfo.metaDescription} />
                <meta name="keywords" content={blogInfo.metaKeywords} />
            </Helmet>
            <section className="section home-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <h3 className='mb-4'>{blogInfo.title}</h3>
                            <p className='mb-4'>{blogInfo.createdAt}</p>
                        </div>
                        <div className="col-md-12 col-lg-12  mb-5 mb-lg-0  image-box" >
                            <img src={blogInfo.image} className='w-100' alt='card' />
                        </div>
                        <div className="col-md-12 col-lg-12 mt-5" >
                            <div dangerouslySetInnerHTML={{ __html: blogInfo.description }} />
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}
export default withRouter(Home)