import { React, useContext } from 'react';
import { Link, NavLink } from "react-router-dom";
import { UserInfoContext } from "./../layouts/App_route";

export default function ProfileSettings({ children }) {
    const { userSubscription } = useContext(UserInfoContext);

    return (
        <>
            <section className='section'>
                <div className='container'>
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="mb-0">Account</h3>
                        </div>
                        <div className="col-sm-6 text-right">
                        <Link className="btn c-btn-blue" to="/dashboard"><i className="fa fa-long-arrow-left mr-2 text-white" aria-hidden="true"></i> Back To Dashboard</Link>

                            {/* <p className="p-breadcrumb text-right mb-0 mt-2">
                                <Link className="text-sky" to="/dashboard">Dashboard</Link>
                                <i className="fa fa-angle-right mr-2 ml-2" aria-hidden="true"></i>
                                Account
                            </p> */}
                        </div>
                        <div className='col-sm-12'>
                            <hr />
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-4 col-lg-3 col-xl-3">
                            <div className="profile_menu mt-2">
                                <ul className='m-0 p-0'>
                                    <li>
                                        <NavLink to="/account" >
                                            <i className="fa fa-user-circle" aria-hidden="true"></i>
                                            <span>Account Details</span>
                                        </NavLink>
                                    </li>
                                    {(userSubscription && userSubscription.code != 'WidgetsOnly') && <li>
                                        <NavLink to="/cards" >
                                            <i className="fa fa-credit-card" aria-hidden="true"></i>
                                            <span>Add Cards</span>
                                        </NavLink>
                                    </li>}
                                    <li>
                                        <NavLink to="/subscription" >
                                            <i className="fa fa-book" aria-hidden="true"></i>
                                            <span>Subscription</span>
                                        </NavLink>
                                    </li>

                                    <li>
                                        <NavLink to="/change-password">
                                            <i className="fa fa-key" aria-hidden="true"></i>
                                            <span>Change Password</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/logout">
                                            <i className="fa fa-power-off" aria-hidden="true"></i>
                                            <span>Logout</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-8 col-lg-9 col-xl-9">
                            {children}
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
}