import React, { useState, useRef, useEffect } from 'react';
import { useHistory, Link, withRouter } from "react-router-dom";

import { post, del } from '../../helpers/api_helper';
import { GET_All_INQUIRY, DELETE_INQUIRY, POST_INQUIRY_REPLY, POST_INQUIRY_DETAIL } from '../../helpers/url_helper';
import { toast } from 'react-toastify';

import Loader from "../../component/Loader";
import SimpleReactValidator from 'simple-react-validator';


const BusinessProfile = props => {
    let history = useHistory();
    const [isLoader, setLoader] = useState(0);

    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();

    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [FilterData, SetFilterData] = useState({ limit: 20, page: 0, search: '' });
    const [ShowLoadMore, seShowLoadMore] = useState(false);
    const [InquiryList, setInquiryList] = useState([]);
    const [InquiryId, setInquiryId] = useState(0);
    const [Index, setIndex] = useState(0);
    const [LeadInfo, SetLeadInfo] = useState({});
    const [LeadInfoLoader, SetLeadInfoLoader] = useState(0);
    const [form_inputs, setInputs] = useState({
        inquiryId: '',
        to: '',
        subject: '',
        message: ''
    });

    useEffect(() => {
        async function get_detaile() {
            await get_invoices();
            setLoader(1);
        }
        get_detaile();
    }, [])

    const get_invoices = async (load_more = 0) => {
        SetButtonDisabled(true);
        FilterData.page = (load_more > 0) ? FilterData.page : 0;
        var res_data = await post(GET_All_INQUIRY, FilterData);
        if (res_data.status) {
            if (load_more > 0) {
                setInquiryList([...InquiryList, ...res_data.data.inquiry_list]);
            } else {
                setInquiryList(res_data.data.inquiry_list);
            }
            seShowLoadMore(res_data.data.load_more);
            SetFilterData(inputs => ({ ...FilterData, ['page']: res_data.data.page }));
            SetButtonDisabled(false);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    // remove user role
    const remove_row = async () => {
        var res_data = await del(DELETE_INQUIRY + "?id=" + InquiryId);
        if (res_data.status) {
            InquiryList.splice(Index, 1);
            setInquiryList([...InquiryList]);
            toast(res_data.message);
        } else {
            toast.error(res_data.message);
        }
    }

    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_INQUIRY_REPLY, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                setInputs({ subject: '', message: '' })
                window.$("#replayModal").modal("hide");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // remove user role
    const get_info = async (id) => {
        SetLeadInfoLoader(1)
        var res_data = await post(POST_INQUIRY_DETAIL, { inquiryId: id });
        if (res_data.status) {
            SetLeadInfo(res_data.data.inquiry_info);
        } else {
            toast.error(res_data.message);
        }
        SetLeadInfoLoader(0)
    }

    const load_more = async () => {
        get_invoices(1);
    }

    return (
        <>
            {!isLoader && <Loader />}
            <section className='section'>
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-sm-6">
                            <h3 className="mb-0">Inbox</h3>
                        </div>
                        <div className="col-sm-6 text-right">
                            <Link className="btn c-btn-blue" to="/dashboard"><i className="fa fa-long-arrow-left mr-2 text-white" aria-hidden="true"></i> Back To Dashboard</Link>

                            {/* <p className="p-breadcrumb text-right mb-0 mt-2">
                                <Link className="text-sky" to="/dashboard">Dashboard</Link>
                                <i className="fa fa-angle-right mr-2 ml-2" aria-hidden="true"></i>
                                Inbox
                            </p> */}
                        </div>
                    </div>

                    <div className="row ">
                        <div className='col-sm-12 col-lg-12 mb-4' style={{ 'minHeight': '30vh' }}>
                            <div className='table-responsive'>
                                <table className='table table-striped'>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Name</th>
                                            <th>Phone Number</th>
                                            <th>Email</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {InquiryList.length > 0 && InquiryList.map((value, index) => {
                                            return (<tr key={index}>
                                                <td>{value.date}</td>
                                                <td>{value.fullName}</td>
                                                <td>{value.phoneNumber}</td>
                                                <td>{value.email}</td>
                                                <td>
                                                    <button onClick={() => { get_info(value.inquiryId) }} data-toggle="modal" data-target="#exampleModal" type='button' className='btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1 mr-2'><i className="fa fa-eye text-white" aria-hidden="true"></i></button>
                                                    <button className="btn btn-sm btn-info pt-0 pb-0 pl-1 pr-1  mr-2" onClick={() => { setInputs({ inquiryId: value.inquiryId, to: value.email, subject: '', message: '' }) }} data-toggle="modal" data-target="#replayModal" ><i className="fa fa-reply text-white" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                                                    <button className="btn btn-sm btn-danger pt-0 pb-0 pl-1 pr-1  mr-2" onClick={() => { setInquiryId(value.inquiryId); setIndex(index); }} data-toggle="modal" data-target="#deleteModal" ><i className="fa fa-trash-o text-white" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                                                </td>
                                            </tr>)
                                        })}
                                        {InquiryList.length <= 0 && <tr>
                                            <td colSpan={5} className='align-middle'>
                                                <p className='mb-0 text-center'>No data found for the criteria</p>
                                            </td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                            {ShowLoadMore && <div className='row'>
                                <div className="col-sm-12 text-center mt-3">
                                    <button onClick={load_more} disabled={ButtonDisabled} className="btn btn-primary btn-sm load_more_btn pl-4 pr-4">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>}Load More...</button>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </section >

            {/* <!-- Modal --> */}
            <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Lead Detail</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {LeadInfoLoader > 0 && <div role="status" className="ms-2 spinner-border text-dark">
                                <span className="sr-only">Loading...</span>
                            </div>}
                            {!LeadInfoLoader && <>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <h6 className='mb-0'>Date</h6>
                                        <p className='mb-2'>{LeadInfo.date}</p>
                                    </div>
                                    <div className='col-sm-6'>
                                        <h6 className='mb-0'>Name</h6>
                                        <p className='mb-2'>{LeadInfo.fullName}</p>
                                    </div>
                                    <div className='col-sm-6'>
                                        <h6 className='mb-0'>Email</h6>
                                        <p className='mb-2'>{LeadInfo.email}</p>
                                    </div>
                                    <div className='col-sm-6'>
                                        <h6 className='mb-0'>Phone Number</h6>
                                        <p className='mb-2'>{LeadInfo.phoneNumber}</p>
                                    </div>
                                    <div className='col-sm-12'>
                                        <h6 className='mb-0'>Message</h6>
                                        <p className='mb-2' style={{'whiteSpace': 'pre-line'}}>{LeadInfo.message}</p>
                                    </div>
                                </div>
                                {LeadInfo.replay && LeadInfo.replay.length > 0 && <>
                                    <hr />
                                    <h5 className='text-center'>Reply</h5>
                                    <hr />
                                    {LeadInfo.replay.map((reply, index) => {
                                        return (<><div className='row' key={index}>
                                            <div className='col-sm-6'>
                                                <h6 className='mb-0'>Date</h6>
                                                <p className='mb-2'>{reply.date}</p>
                                            </div>
                                            <div className='col-sm-6'>
                                                <h6 className='mb-0'>Email</h6>
                                                <p className='mb-2'>{reply.email}</p>
                                            </div>
                                            <div className='col-sm-12'>
                                                <h6 className='mb-0'>Message</h6>
                                                <p className='mb-2' style={{'whiteSpace': 'pre-line'}}>{reply.message}</p>
                                            </div>
                                        </div>
                                            <hr /></>)
                                    })}

                                </>}
                            </>}

                        </div>
                    </div>
                </div>
            </div>

            {/* deleteModal */}
            <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirm Delete</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this record?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-danger" data-dismiss="modal">No</button>
                            <button onClick={remove_row.bind(this)} data-dismiss="modal" className="btn btn-sm btn-primary c-btn-primary">Yes</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- replay --> */}
            <div className="modal fade" id="replayModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Replay</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className='form-group'>
                                            <label className='w-100'>To  </label>
                                            <input type='text' name="to" value={form_inputs.to} onChange={handleInputChange} className="form-control" />
                                            {simpleValidator.current.message('to', form_inputs.to, 'required|email')}
                                        </div>
                                        <div className='form-group'>
                                            <label className='w-100'>Subject  </label>
                                            <input type='text' name="subject" value={form_inputs.subject} onChange={handleInputChange} className="form-control" />
                                            {simpleValidator.current.message('subject', form_inputs.subject, 'required')}
                                        </div>
                                        <div className='form-group'>
                                            <label className='w-100'>Message  </label>
                                            <textarea rows={5} name="message" value={form_inputs.message} onChange={handleInputChange} className="form-control" />
                                            {simpleValidator.current.message('message', form_inputs.message, 'required')}
                                        </div>
                                    </div>
                                    <div className='col-sm-12 text-center'>
                                        <button disabled={ButtonDisabled} className="c-btn-light btn-md pt-2 pb-2 pl-3 pr-3 radius-10 mb-3">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin mr-1"></i>} Send</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(BusinessProfile)

