import React, { useState, useContext, useRef, useEffect } from 'react';
import { useHistory, Link, withRouter } from "react-router-dom";
import Loader from "../../../component/Loader";


import BusinessDetails from './BusinessDetails';
import AboutYourBusiness from './AboutYourBusiness';
import Services from './Services';
import Faq from './Faq';
import Gallery from './Gallery';


const BusinessProfile = props => {
    const [ActiveTab, setActiveTab] = useState(1);
    const [isLoader, setLoader] = useState(1);


    return (
        <>
            {!isLoader && <Loader />}
            <section className='section'>
                <div className="container">
                    <div className='row'>
                        <div className='col-sm-1'></div>
                        <div className='col-sm-10 mb-4'>
                            <ul className='tab_menu'>
                                <li className={(ActiveTab == 1) ? 'active' : (ActiveTab == 2)? 'show' : ''}>
                                    {ActiveTab >= 2 && <svg width="21" height="21" className='mr-2' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 12L10.2426 16.2426L18.7275 7.75732" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>}
                                    <span>Basic Details</span>
                                </li>
                                <li className={(ActiveTab == 2) ? 'active' : (ActiveTab == 3 || ActiveTab == 1)? 'show' : ''}>
                                    {ActiveTab >= 3 && <svg width="21" height="21" className='mr-2' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 12L10.2426 16.2426L18.7275 7.75732" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>}
                                    <span>About Business</span>
                                </li>
                                <li className={(ActiveTab == 3) ? 'active' : (ActiveTab == 4)? 'show' : ''}>
                                    {ActiveTab >= 4 && <svg width="21" height="21" className='mr-2' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 12L10.2426 16.2426L18.7275 7.75732" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>}
                                    <span>Services</span>
                                </li>
                                <li className={(ActiveTab == 4) ? 'active' : (ActiveTab == 5)? 'show' : ''}>
                                    {ActiveTab >= 5 && <svg width="21" height="21" className='mr-2' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 12L10.2426 16.2426L18.7275 7.75732" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>}
                                    <span>FAQs</span>
                                </li>
                                <li className={(ActiveTab == 5) ? 'active' : ''}>
                                    {ActiveTab >= 6 && <svg width="21" height="21" className='mr-2' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 12L10.2426 16.2426L18.7275 7.75732" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>}
                                    <span>Gallery</span>
                                </li>
                            </ul>
                        </div>
                        <div className='col-sm-1'></div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-1'></div>
                        <div className='col-sm-10'>
                            {ActiveTab == 1 && <BusinessDetails call_back={() => { setActiveTab(2) }} />}
                            {ActiveTab == 2 && <AboutYourBusiness call_back={(page) => { setActiveTab(page) }} />}
                            {ActiveTab == 3 && <Services call_back={(page) => { setActiveTab(page) }} />}
                            {ActiveTab == 4 && <Faq call_back={(page) => { setActiveTab(page) }} />}
                            {ActiveTab == 5 && <Gallery call_back={(page) => { setActiveTab(page) }} />}
                        </div>
                        <div className='col-sm-1'></div>
                    </div>
                </div>
            </section >
        </>
    );
}
export default withRouter(BusinessProfile)

