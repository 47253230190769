import React from 'react';
import { Switch, BrowserRouter } from "react-router-dom"
import AppRoute from './layouts/App_route';
import Default_layouts_1 from './layouts/DefaultLayout';
import Default_layouts_2 from './layouts/DefaultLayout2';
import FullLayout from './layouts/FullLayout';

import Login from "./pages/Auth/Login";
import SignUp from "./pages/Auth/SignUp";
import ForgetPassword from "./pages/Auth/ForgetPassword";
import Logout from "./pages/Auth/Logout";
import AccountVerify from "./pages/Auth/AccountVerify";

import Home from "./pages/Home";
import About from "./pages/About";
import Pricing from "./pages/Pricing";
import Contact from "./pages/Contact";
import Faqs from "./pages/Faqs";
import BlogDetail from "./pages/Blogs/BlogDetail";
import Blogs from "./pages/Blogs/Blogs";


import Dashboard from "./pages/User/Dashboard";
// import BusinessProfile from "./pages/User/BusinessProfile";
import BusinessProfile from "./pages/User/BusinessProfile/BusinessProfile";
import Inquiry from "./pages/User/Inquiry";
import Review from "./pages/User/Review";
import SubscriptionSuccess from "./pages/SubscriptionSuccess";
import Cart from "./pages/Cart";
import UserWidget from "./pages/User/Widget";

import AccountDetails from "./pages/User/AccountDetails";
import ChangePassword from "./pages/User/ChangePassword";
import Subscription from "./pages/User/Subscription";
import RenewSubscription from "./pages/User/RenewSubscription";
import UserCards from "./pages/User/UserCards";
import BuyCards from "./pages/User/BuyCards";

import Theme from "./theme/Theme";
import MiniWebsite from "./theme/MiniWebsite";
import MiniWebsiteAllReview from "./theme/MiniWebsiteAllReview";
import Widget from "./theme/Widget";

function App() {
    return (
        <>
            <BrowserRouter>
                <Switch>
                    <AppRoute exact path="/" component={Home} layout={Default_layouts_2} />
                    <AppRoute exact path="/about" component={About} layout={Default_layouts_1} />
                    <AppRoute exact path="/pricing" component={Pricing} layout={Default_layouts_1} />
                    <AppRoute exact path="/contact" component={Contact} layout={Default_layouts_1} />
                    <AppRoute exact path="/faqs" component={Faqs} layout={Default_layouts_1} />
                    <AppRoute exact path="/blogs" component={Blogs} layout={Default_layouts_2} />
                    <AppRoute exact path="/blogs/:slug" component={BlogDetail} layout={Default_layouts_2} />

                    <AppRoute exact path="/sign-up" component={SignUp} layout={Default_layouts_2} />
                    <AppRoute exact path="/login" component={Login} layout={Default_layouts_1} />
                    <AppRoute exact path="/forget-password" component={ForgetPassword} layout={Default_layouts_1} />
                    <AppRoute exact path="/account-verify/:id" component={AccountVerify} layout={Default_layouts_2} />
                    <AppRoute exact path="/logout" component={Logout} layout={Default_layouts_1} />

                    <AppRoute exact path="/cart" component={Cart} layout={Default_layouts_2} CheckAuthGuard={true} />
                    <AppRoute exact path="/subscription-success" component={SubscriptionSuccess} layout={Default_layouts_2} CheckAuthGuard={true} />
                    <AppRoute exact path="/dashboard" component={Dashboard} layout={Default_layouts_2} CheckAuthGuard={true} />
                    <AppRoute exact path="/business-profile" component={BusinessProfile} layout={Default_layouts_2} CheckAuthGuard={true} />
                    <AppRoute exact path="/account" component={AccountDetails} layout={Default_layouts_2} CheckAuthGuard={true} />
                    <AppRoute exact path="/change-password" component={ChangePassword} layout={Default_layouts_2} CheckAuthGuard={true} />
                    <AppRoute exact path="/inbox" component={Inquiry} layout={Default_layouts_2} CheckAuthGuard={true} />
                    <AppRoute exact path="/review" component={Review} layout={Default_layouts_2} CheckAuthGuard={true} />
                    <AppRoute exact path="/widget" component={UserWidget} layout={Default_layouts_2} CheckAuthGuard={true} />
                    <AppRoute exact path="/subscription" component={Subscription} layout={Default_layouts_2} CheckAuthGuard={true} />
                    <AppRoute exact path="/renew-subscription" component={RenewSubscription} layout={Default_layouts_2} CheckAuthGuard={true} />
                    <AppRoute exact path="/cards" component={UserCards} layout={Default_layouts_2} CheckAuthGuard={true} />
                    <AppRoute exact path="/order-cards" component={BuyCards} layout={Default_layouts_2} CheckAuthGuard={true} />

                    <AppRoute exact path="/:slug/review/:cardId?" component={Theme} layout={FullLayout} />
                    <AppRoute exact path="/:slug/all-review" component={MiniWebsiteAllReview} layout={FullLayout} />
                    <AppRoute exact path="/:slug/widget" component={Widget} layout={FullLayout} />
                    <AppRoute exact path="/:slug" component={MiniWebsite} layout={FullLayout} />

                </Switch>
            </BrowserRouter>
        </>
    );
}

export default App;
