import React, { useState, useEffect } from 'react';

// component
import Header from '../component/header';
import Footer from '../component/footer';
import '../assets/css/style.css'
import { is_login } from "../helpers/Helper";



function Default1({ children }) {
    window.scrollTo(0, 0);
    const [IsLogin, setIsLogin] = useState(false);
    useEffect(() => {
        setIsLogin(is_login());
    }, [])

    return (
        <>
            <Header />
            <main>{children}</main>
            <Footer />
        </>
    );
}

export default Default1;
