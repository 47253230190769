import React, { useState, useEffect, useRef } from 'react';
import { useHistory, withRouter, Link } from "react-router-dom";

import { toast } from 'react-toastify';
import { post } from '../helpers/api_helper';
import { POST_GET_PROFILE, GET_REVIEW } from '../helpers/url_helper';

import './../assets/css/widget.css'
import Logo_full from '../assets/images/logo.svg';
import Logo from './../assets/icone.png';


const Widget = props => {
    let history = useHistory();


    const [UserInfo, SetUserInfo] = useState({});
    const [isLoader, setLoader] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [widget, setwidget] = useState(1);

    useEffect(() => {
        async function get_detaile() {
            const params = new URLSearchParams(window.location.search);
            var res_data = await post(POST_GET_PROFILE, { 'slug': props.match.params.slug });
            setwidget(params.get('t'));

            if (res_data.status) {
                SetUserInfo(res_data.data.user_info);
                var average_rating = res_data.data.user_info.average_rating;
                var pre = 0;
                if (average_rating > 0) {
                    pre = ((parseFloat(average_rating) * 100) / 5);
                }
                setPercentage(pre)

                setLoader(1);
            } else {
                toast.error(res_data.message);
                history.push("/");
            }
        }
        get_detaile();
    }, [props.match.params.slug])


    if (!isLoader) {
        return <></>;
    }
    return (
        <>
            {widget == 1 && <div className='Widget1'>
                <div className='logo'>
                    <img src={Logo} alt='Tap Review' />
                </div>
                <div className='revie_box'>
                    <h5 className='title'>Tap Review</h5>
                    <div className='rating'>
                        <div className='count'><h5>{UserInfo.average_rating}</h5></div>
                        <div className='icone'>
                            <div className='stars'>
                                <div class="filled" style={{ 'width': `${percentage}%` }}>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                </div>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    <a href={`/${props.match.params.slug}/all-review`} target='_blank'>See all our reviews</a>
                </div>
            </div>}

            {widget != 1 && <div className='Widget2'>
                <div className='text-center'>
                    <img className='mb-3' src={Logo_full} alt='Tap Review' />
                    <div ><h2>{UserInfo.average_rating}</h2></div>
                    <div className='icone'>
                        <div className='stars'>
                            <div class="filled" style={{ 'width': `${percentage}%` }}>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <i class="fa fa-star" aria-hidden="true"></i>
                            <i class="fa fa-star" aria-hidden="true"></i>
                            <i class="fa fa-star" aria-hidden="true"></i>
                            <i class="fa fa-star" aria-hidden="true"></i>
                            <i class="fa fa-star" aria-hidden="true"></i>
                        </div>
                    </div>
                    <a href={`/${props.match.params.slug}/all-review`} target='_blank'>See all our reviews</a>
                </div>
            </div>}
        </>
    );
}
export default withRouter(Widget)
