import React, { useState, useRef, useEffect } from 'react';
import {  withRouter } from "react-router-dom";

import { post } from '../../helpers/api_helper';
import {GET_PROFILE_DETAILES, POST_SAVE_USER_PROFILE } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import ProfileSettings from '../../component/ProfileSettings';
import Loader from "../../component/Loader";


const Contact = props => {
    const [isLoader, setLoader] = useState(0);

    const simpleValidator = useRef(new SimpleReactValidator());
    const [form_inputs, setInputs] = useState({ firstName: '',lastName : '', email: ''});
    const [, forceUpdate] = useState();
    const [ButtonDisabled, SetButtonDisabled] = useState(false);

    useEffect(() => {
        async function get_detaile() {
            var res_data = await post(GET_PROFILE_DETAILES, {});
            if (res_data.status) {
                setInputs(res_data.data.detail);
            } else {
                toast.error(res_data.message);
            }
            setLoader(1);
        }
        get_detaile();
    }, [])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_SAVE_USER_PROFILE, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }


    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    return (
        <>
            {!isLoader && <Loader />}
            <ProfileSettings>
                <form onSubmit={handleSubmit}>
                    <div className='col-sm-12 col-lg-6'>
                        <div className='form-group'>
                            <label><b>Business Name </b>: {form_inputs.businessName}</label>
                            <label><b className='mr-5'>Category </b>: {form_inputs.categoryName}</label>
                        </div>
                        <div className='form-group'>
                            <label>First Name</label>
                            <input type='text' name="firstName" value={form_inputs.firstName} onChange={handleInputChange} className="form-control" />
                            {simpleValidator.current.message('first name', form_inputs.firstName, 'required')}
                        </div>
                        <div className='form-group'>
                            <label>Last Name</label>
                            <input type='text' name="lastName" value={form_inputs.lastName} onChange={handleInputChange} className="form-control" />
                            {simpleValidator.current.message('last name', form_inputs.lastName, 'required')}
                        </div>
                        <div className='form-group'>
                            <label>Email</label>
                            <input type='text' name="email" value={form_inputs.email} onChange={handleInputChange} className="form-control" />
                            {simpleValidator.current.message('email', form_inputs.email, 'required|email')}
                        </div>
                        <div className='text-center mt-5'>
                            <button disabled={ButtonDisabled} className="c-btn-light radius-10 pr-5 pl-5">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin mr-1"></i>} Save</button>
                        </div>
                    </div>
                </form>

            </ProfileSettings>
        </>
    );
}
export default withRouter(Contact)

