import React, { useState, useRef, useEffect } from 'react';
import { withRouter, useHistory, Link } from "react-router-dom";
import SimpleReactValidator from 'simple-react-validator';
import { GET_PROFILE_DETAILES, GET_PRICING, ORDER_PLEASE } from '../helpers/url_helper';
import { toast } from 'react-toastify';
import { get, post } from '../helpers/api_helper';
import { getToken } from '../helpers/Helper';
import StripeCheckout from 'react-stripe-checkout';
import Loader from "../component/Loader";

import Logo from './../assets/images/logo.svg';

const Cart = props => {
    let history = useHistory();

    const [isLoader, setLoader] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [form_inputs, setInputs] = useState({
        name: '',
        email: '',
        phoneNumber: '',

        address1: '',
        address2: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',

        deliveryAddress1: '',
        deliveryAddress2: '',
        deliveryCity: '',
        deliveryState: '',
        deliveryCountry: '',
        deliveryZipCode: '',

        planId: '',
        planName: '',
        subscriptionType: '',
        selectCard: '',
        userCardInfo: [],

        setupFee: '0.0',
        planFee: '0.0',
        amount: '0.0',
        shippingId: '',
        shippingCharge: '0.00',
        vat: '0.00',
        totalAmount: '0.00',
        vatPercentage: '0'
    });
    const [, forceUpdate] = useState();
    const [shippingTypeList, SetshippingTypeList] = useState([]);
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [PlanDate, SetPlanDate] = useState({ satartDate: '', endDate: '' });

    useEffect(() => {
        async function get_detaile() {
            var user_cart = localStorage.getItem('cart');
            user_cart = (user_cart) ? JSON.parse(user_cart) : '';
            if (!user_cart) {
                history.push("/pricing");
            } else {

                // get plan info
                var res_price = await get(GET_PRICING + '?subscriptionType=' + user_cart.type + '&token=' + getToken() + '&plan=' + user_cart.plan);
                SetPlanDate({
                    satartDate: res_price.data.satartDate,
                    endDate: res_price.data.endDate
                })

                //get user info
                var profile_info = await post(GET_PROFILE_DETAILES, {});
                if (profile_info.status) {
                    form_inputs.name = profile_info.data.detail.firstName + ' ' + profile_info.data.detail.lastName;
                    form_inputs.email = profile_info.data.detail.email;

                    var amount = '0.00';
                    var setupFee = '0.00';
                    var planFee = '0.00';
                    if (user_cart.type === 'monthly') {

                        if (res_price.data[user_cart.plan]) {
                            setupFee = res_price.data[user_cart.plan].setupFee;
                            planFee = res_price.data[user_cart.plan].planFee;
                            amount = (parseFloat(setupFee) + parseFloat(planFee))

                            // for (var price of res_price.data[user_cart.plan].price) {
                            //     if (price.card == user_cart.selectCard) {
                            //         amount = price.monthlyPrice;
                            //     }
                            // }
                        }
                    } else {
                        // if (res_price.data[user_cart.plan].price) {
                        //     for (var price of res_price.data[user_cart.plan].price) {
                        //         if (price.card == user_cart.selectCard) {
                        //             amount = price.AnnuallyPrice;
                        //         }
                        //     }
                        // }
                    }

                    var userCardInfo = [];
                    for (var i = 0; i < user_cart.selectCard; i++) {
                        userCardInfo.push({
                            'firstName': '',
                            'lastName': '',
                        })
                    }
                    form_inputs.userCardInfo = userCardInfo;
                    form_inputs.subscriptionType = user_cart.type;
                    form_inputs.setupFee = setupFee;
                    form_inputs.planFee = planFee;

                    form_inputs.amount = parseFloat(amount).toFixed(2);
                    form_inputs.planId = (res_price.data[user_cart.plan].planId) ? res_price.data[user_cart.plan].planId : 0;
                    form_inputs.planName = (res_price.data[user_cart.plan].title) ? res_price.data[user_cart.plan].title : '';
                    form_inputs.selectCard = user_cart.selectCard;
                    form_inputs.vatPercentage = res_price.data.vatPercentage;
                    form_inputs.shippingId = (res_price.data.shipping_type.length > 0 && user_cart.plan != 'WidgetsOnly') ? res_price.data.shipping_type[0].shippingId : 0;
                    form_inputs.shippingCharge = (res_price.data.shipping_type.length > 0 && user_cart.plan != 'WidgetsOnly') ? res_price.data.shipping_type[0].charge : 0;
                    form_inputs.vat = ((parseFloat(form_inputs.amount) * parseFloat(form_inputs.vatPercentage)) / 100).toFixed(2);
                    form_inputs.totalAmount = (parseFloat(form_inputs.amount) + parseFloat(form_inputs.vat) + parseFloat(form_inputs.shippingCharge)).toFixed(2);
                    setInputs(inputs => ({ ...form_inputs }));
                    SetshippingTypeList(res_price.data.shipping_type)
                } else {
                    toast.error(profile_info.message);
                }
                setLoader(1);
            }

        }
        get_detaile();
    }, [])

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'tc') {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: (event.target.checked) ? 1 : '' }));
        } else if (event.target.name === 'shippingId') {
            for (var shippingInfo of shippingTypeList) {
                if (shippingInfo.shippingId == event.target.value) {
                    form_inputs.shippingCharge = shippingInfo.charge;
                }
            }

            form_inputs.vat = (((parseFloat(form_inputs.amount)) * parseFloat(form_inputs.vatPercentage)) / 100).toFixed(2);
            form_inputs.totalAmount = (parseFloat(form_inputs.amount) + parseFloat(form_inputs.vat) + parseFloat(form_inputs.shippingCharge)).toFixed(2);
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    //handleInputChangeMultiple
    const handleInputChangeMultiple = (index, event) => {
        var name = event.target.name;
        if (name === 'firstName' || name === 'lastName') {
            form_inputs.userCardInfo[index][name] = event.target.value;
            setInputs(inputs => ({ ...form_inputs, ['userCardInfo']: form_inputs.userCardInfo }));
        }
    }


    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            window.$('.StripeCheckout').click();
            SetButtonDisabled(true);
        }
    }


    const handleToken = async (token) => {
        SetButtonDisabled(true);

        form_inputs.token = token
        const response = await post(ORDER_PLEASE, form_inputs);
        if (response.status) {
            history.push("/subscription-success");
        } else {
            toast.error(response.message);
        }
        SetButtonDisabled(false);
    }


    return (
        <>
            {!isLoader && <Loader />}
            <section className='section pricing'>
                <div className="container">
                    <form onSubmit={handleSubmit}>
                        <div className="row mb-5">
                            <div className='col-md-12 col-lg-7 mb-3'>
                                <div className='bg_white p-4'>
                                    <div className='row'>
                                        {/* <div className='col-sm-12 mb-3'>
                                            <small><b>Note:</b> Purchase a new subscription. Your old subscription has expired automatically, and all cards have been removed.</small>
                                        </div> */}
                                        <div className='col-sm-12 mb-3'>
                                            <h5>Contact Information</h5>
                                        </div>
                                        <hr />
                                        <div className='col-sm-12'>
                                            <div className='form-group mb-2'>
                                                <label>Full name</label>
                                                <input type='text' name="name" value={form_inputs.name} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('name', form_inputs.name, 'required')}
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-6'>
                                                    <div className='form-group mb-2'>
                                                        <label>Email</label>
                                                        <input type='text' name="email" value={form_inputs.email} onChange={handleInputChange} className="form-control" />
                                                        {simpleValidator.current.message('email', form_inputs.email, 'required|email')}
                                                    </div>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <div className='form-group mb-2'>
                                                        <label>Phone number</label>
                                                        <input type='text' name="phoneNumber" value={form_inputs.phoneNumber} onChange={handleInputChange} className="form-control" />
                                                        {simpleValidator.current.message('phone number', form_inputs.phoneNumber, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {form_inputs.userCardInfo.length > 0 && <>
                                            <div className='col-sm-12 mb-3 mt-4'>
                                                <h5>Card Information</h5>
                                            </div>
                                            <div className='col-sm-12'>
                                                {Object.entries(form_inputs.userCardInfo).map(([key, card_info]) => (
                                                    <div className='row mb-2' key={key}>
                                                        <div className='col-sm-6'>
                                                            <div className='form-group'>
                                                                <input type='text' name="firstName" placeholder='First Name' onChange={handleInputChangeMultiple.bind(this, key)} value={card_info.firstName} className="form-control" />
                                                                {simpleValidator.current.message('first name', card_info.firstName, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-6'>
                                                            <div className='form-group'>
                                                                <input type='text' name="lastName" placeholder='Last Name' onChange={handleInputChangeMultiple.bind(this, key)} value={card_info.lastName} className="form-control" />
                                                                {simpleValidator.current.message('last number', card_info.lastName, 'required')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </>}
                                        <hr />
                                        <div className='col-sm-12 mb-3 mt-4'>
                                            <h5>Billing Details</h5>
                                        </div>
                                        <hr />
                                        <div className='col-sm-6'>
                                            <div className='form-group mb-2'>
                                                <label>Address line 1</label>
                                                <input type='text' name="address1" value={form_inputs.address1} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('address', form_inputs.address1, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group mb-2'>
                                                <label>Address line 2</label>
                                                <input type='text' name="address2" value={form_inputs.address2} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('address', form_inputs.address2, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group mb-2'>
                                                <label>City</label>
                                                <input type='text' name="city" value={form_inputs.city} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('city', form_inputs.city, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group mb-2'>
                                                <label>State</label>
                                                <input type='text' name="state" value={form_inputs.state} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('state', form_inputs.state, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group mb-2'>
                                                <label>Country</label>
                                                <input type='text' name="country" value={form_inputs.country} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('country', form_inputs.country, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group mb-2'>
                                                <label>Zip Code</label>
                                                <input type='text' name="zipCode" value={form_inputs.zipCode} onChange={handleInputChange} className="form-control" />
                                                {simpleValidator.current.message('zip code', form_inputs.zipCode, 'required')}
                                            </div>
                                        </div>
                                        {form_inputs.userCardInfo.length > 0 && <>
                                            <div className='col-sm-12 mb-3 mt-4'>
                                                <h5>Delivery Addresses</h5>
                                            </div>
                                            <hr />
                                            <div className='col-sm-6'>
                                                <div className='form-group mb-2'>
                                                    <label>Address line 1</label>
                                                    <input type='text' name="deliveryAddress1" value={form_inputs.deliveryAddress1} onChange={handleInputChange} className="form-control" />
                                                    {simpleValidator.current.message('address', form_inputs.deliveryAddress1, 'required')}
                                                </div>
                                            </div>
                                            <div className='col-sm-6'>
                                                <div className='form-group mb-2'>
                                                    <label>Address line 2</label>
                                                    <input type='text' name="deliveryAddress2" value={form_inputs.deliveryAddress2} onChange={handleInputChange} className="form-control" />
                                                    {simpleValidator.current.message('address', form_inputs.deliveryAddress2, 'required')}
                                                </div>
                                            </div>
                                            <div className='col-sm-6'>
                                                <div className='form-group mb-2'>
                                                    <label>City</label>
                                                    <input type='text' name="deliveryCity" value={form_inputs.deliveryCity} onChange={handleInputChange} className="form-control" />
                                                    {simpleValidator.current.message('city', form_inputs.deliveryCity, 'required')}
                                                </div>
                                            </div>
                                            <div className='col-sm-6'>
                                                <div className='form-group mb-2'>
                                                    <label>State</label>
                                                    <input type='text' name="deliveryState" value={form_inputs.deliveryState} onChange={handleInputChange} className="form-control" />
                                                    {simpleValidator.current.message('state', form_inputs.deliveryState, 'required')}
                                                </div>
                                            </div>
                                            <div className='col-sm-6'>
                                                <div className='form-group mb-2'>
                                                    <label>Country</label>
                                                    <input type='text' name="deliveryCountry" value={form_inputs.deliveryCountry} onChange={handleInputChange} className="form-control" />
                                                    {simpleValidator.current.message('country', form_inputs.deliveryCountry, 'required')}
                                                </div>
                                            </div>
                                            <div className='col-sm-6'>
                                                <div className='form-group mb-2'>
                                                    <label>Zip Code</label>
                                                    <input type='text' name="deliveryZipCode" value={form_inputs.deliveryZipCode} onChange={handleInputChange} className="form-control" />
                                                    {simpleValidator.current.message('zip code', form_inputs.deliveryZipCode, 'required')}
                                                </div>
                                            </div>
                                        </>}
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-12 col-lg-5 mb-3'>
                                <div className="bg_white p-4">
                                    <div>
                                        <h4 className='text-light-blue'>Summary</h4>
                                    </div>
                                    <div className="mt-4">
                                        <div className='row'>
                                            <div className='col-12 mb-4'>
                                                <p className='mb-0 text-capitalize'>{form_inputs.subscriptionType} Plan</p>
                                                <p className='mb-0'>Date  {PlanDate.satartDate} To {PlanDate.endDate}</p>
                                            </div>
                                            <div className='col-8'><p className='mb-0'>{form_inputs.planName} (Per Month)</p></div>
                                            <div className='col-4 text-right'>£{form_inputs.planFee}</div>
                                            {form_inputs.setupFee > 0 && <>
                                                <div className='col-8'><p className='mb-0'>Setup Fee</p></div>
                                                <div className='col-4 text-right'>£{form_inputs.setupFee}</div>
                                            </>}
                                        </div>
                                        <hr className='' />
                                        <div className='row'>
                                            <div className='col-8'><p><b>Sub Total</b></p></div>
                                            <div className='col-4 text-right'><b>£{form_inputs.amount}</b></div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-8'><p><b>VAT {form_inputs.vatPercentage}%</b></p></div>
                                            <div className='col-4 text-right'><b>£{form_inputs.vat}</b></div>
                                        </div>
                                        {form_inputs.userCardInfo.length > 0 && <div className='row mb-3'>
                                            <div className='col-8'>
                                                <p className='mb-2'><b>Shipping  Type</b></p>
                                                <select className='form-control pull-left' name='shippingId' onChange={handleInputChange} value={form_inputs.shippingId}>
                                                    {shippingTypeList && Object.entries(shippingTypeList).map(([key, shipping]) => (<>
                                                        <option key={key} value={shipping.shippingId}>{shipping.name}</option>
                                                    </>))}
                                                </select>
                                            </div>
                                            <div className='col-4 text-right pt-4'><b>£{form_inputs.shippingCharge}</b></div>
                                        </div>}

                                        <hr className='' />
                                        <div className='row'>
                                            <div className='col-8'><p><b>Total Amount</b></p></div>
                                            <div className='col-4 text-right'><b>£{form_inputs.totalAmount}</b></div>
                                        </div>
                                        <div className='row mt-4'>
                                            <div className='col-sm-12'>
                                                <div class="form-group form-check mb-2">
                                                    <input type="checkbox" value="1" name='tc' onChange={handleInputChange} class="form-check-input" id="exampleCheck1" />
                                                    <label class="form-check-label mr-2 ml-2" for="exampleCheck1">I agree to the <Link to="/terms-conditions.pdf" target="_blank">Terms & Conditions </Link>.</label>
                                                    {simpleValidator.current.message('terms and conditions', form_inputs.tc, 'required')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center mt-4'>
                                        <button disabled={(ButtonDisabled || !form_inputs.tc)} className="c-btn-light mb-3" >{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin mr-1"></i>} Continue To Payment </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section >

            <StripeCheckout
                className="btn btn-success StripeCheckout d-none"
                image={Logo}
                stripeKey={process.env.REACT_APP_STRIPE_KEY}
                token={handleToken}
                email={form_inputs.email}
                currency={process.env.REACT_APP_CURRENCY}
                amount={form_inputs.totalAmount * 100}
                name={"TAP Review"}
                billingAddress={false}
                alipay={false}
            />
        </>
    );
}
export default withRouter(Cart)