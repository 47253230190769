import React, { useState, useContext, useRef, useEffect } from 'react';
import { useHistory, Link, withRouter } from "react-router-dom";

import { post, del } from '../../../helpers/api_helper';
import { GET_BUSINESS_PROFILE_DETAILES, POST_SAVE_BUSINESS_PROFILE } from '../../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { UserInfoContext } from "../../../layouts/App_route";
import Loader from "../../../component/Loader";



const BusinessDetails = props => {
    let history = useHistory();
    const { userSubscription } = useContext(UserInfoContext);
    const [isLoader, setLoader] = useState(0);

    const simpleValidator = useRef(new SimpleReactValidator());
    const [form_inputs, setInputs] = useState({
        profileId: '', businessName: '', email: '',
        telephoneNumber: '', mobileNumber: '', showMobileNumber: 0, addressLine1: '',
        addressLine2: '', town: '', country: '', postCode: '',  ShowContactForm: 0, showFooterAddress: 0
    });
    const [, forceUpdate] = useState();
    const [ButtonDisabled, SetButtonDisabled] = useState(false);

    useEffect(() => {
        async function get_detaile() {
            await get_profile_info();
        }
        get_detaile();
    }, [])

    const get_profile_info = async () => {
        var res_data = await post(GET_BUSINESS_PROFILE_DETAILES, {});
        if (res_data.status) {
            res_data.data.detail.logo = (res_data.data.detail.logo) ? res_data.data.detail.logo + '?' + new Date().getTime() : '';
            setInputs(res_data.data.detail);

            setLoader(1);
        } else {
            toast.error(res_data.message);
        }
    }
    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            form_inputs.type = 'basicDetails';
            var res_data = await post(POST_SAVE_BUSINESS_PROFILE, form_inputs, {});
            if (res_data.status) {
                await get_profile_info();
                props.call_back()
                // toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();

        if (event.target.name === 'ShowContactForm' || event.target.name === 'showMobileNumber' || event.target.name === 'showFooterAddress') {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: (event.target.checked) ? 1 : 0 }));
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }


    return (
        <>
            {!isLoader && <Loader />}
            <div className='row mb-4'>
                <div className="col-sm-8">
                    <h4 className="mb-0">Basic Business Details</h4>
                </div>
                <div className="col-sm-4 text-sm-right">
                    <p className='mb-0 pt-2'>Current Plan: <b>{(userSubscription && userSubscription.shortName)? userSubscription.shortName : 'Free'}</b></p>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className='row mb-5'>
                    <div className='col-sm-12 col-lg-6'>
                        <div className='form-group'>
                            <label>Business Name *</label>
                            <input type='text' name="businessName" value={form_inputs.businessName} onChange={handleInputChange} className="form-control" />
                            {simpleValidator.current.message('business name', form_inputs.businessName, 'required')}
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-6'>
                        <div className='form-group'>
                            <label>Email *</label>
                            <input type='text' name="email" value={form_inputs.email} onChange={handleInputChange} className="form-control" />
                            {simpleValidator.current.message('email', form_inputs.email, 'required|email')}
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-6'>
                        <div className='form-group'>
                            <label>Address Line 1 *</label>
                            <input type='text' name="addressLine1" value={form_inputs.addressLine1} onChange={handleInputChange} className="form-control" />
                            {simpleValidator.current.message('address line 1', form_inputs.addressLine1, 'required')}
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-6'>
                        <div className='form-group'>
                            <label>Address Line 2 </label>
                            <input type='text' name="addressLine2" value={form_inputs.addressLine2} onChange={handleInputChange} className="form-control" />
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-6'>
                        <div className='form-group'>
                            <label>Country *</label>
                            <input type='text' name="country" value={form_inputs.country} onChange={handleInputChange} className="form-control" />
                            {simpleValidator.current.message('country', form_inputs.country, 'required')}
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-6'>
                        <div className='form-group'>
                            <label>Phone Number *</label>
                            <input type='text' name="telephoneNumber" value={form_inputs.telephoneNumber} onChange={handleInputChange} className="form-control" />
                            {simpleValidator.current.message('phone number', form_inputs.telephoneNumber, 'required')}
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-6'>
                        <div className='form-group'>
                            <label>Town *</label>
                            <input type='text' name="town" value={form_inputs.town} onChange={handleInputChange} className="form-control" />
                            {simpleValidator.current.message('town', form_inputs.town, 'required')}
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-6'>
                        <div className='form-group'>
                            <label>Post Code *</label>
                            <input type='text' name="postCode" value={form_inputs.postCode} onChange={handleInputChange} className="form-control" />
                            {simpleValidator.current.message('post code', form_inputs.postCode, 'required')}
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-12 pt-2 pb-2'>
                        <h5><b>Others</b></h5>
                    </div>
                    <div className='col-sm-4 pr-1'>
                        <label className="switch mr-1">
                            <input name="showMobileNumber" onChange={handleInputChange} checked={form_inputs.showMobileNumber} type="checkbox" />
                            <span className="slider round"></span>
                        </label>
                        <label>{`Don’t Display Mobile Number `}</label>
                    </div>
                    <div className='col-sm-4 pr-1'>
                        <label className="switch mr-1">
                            <input name="showFooterAddress"  onChange={handleInputChange} checked={form_inputs.showFooterAddress} type="checkbox" />
                            <span className="slider round"></span>
                        </label>
                        <label>{`Don’t Display Address`}</label>
                    </div>
                    <div className='col-sm-4 pr-1'>
                        <label className="switch mr-1">
                            <input name="ShowContactForm"  onChange={handleInputChange} checked={form_inputs.ShowContactForm} type="checkbox" />
                            <span className="slider round"></span>
                        </label>
                        <label >{`Show Contact Us Form`}</label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-6'>
                        <button type='button' onClick={() => { history.push("/dashboard"); }} disabled={ButtonDisabled} className="c-btn-blue-outline btn-md pt-2 pb-2 pl-3 pr-3 radius-10 mb-3 c-btn-block"><i className="fa fa-long-arrow-left mr-2" aria-hidden="true"></i>  Back To Dashboard </button>
                    </div>
                    <div className='col-sm-6 text-right'>
                        <button type='submit' disabled={ButtonDisabled} className="c-btn-blue btn-md pt-2 pb-2 pl-3 pr-3 radius-10 mb-3 c-btn-block">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin mr-1"></i>} Save and Continue <i className="fa fa-long-arrow-right ml-2 text-white" aria-hidden="true"></i></button>
                    </div>
                </div>
            </form >
        </>
    );
}
export default withRouter(BusinessDetails)

