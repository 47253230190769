import React, { useState, useRef } from 'react';
import { withRouter } from "react-router-dom";

import { post } from '../../helpers/api_helper';
import { POST_CHANGE_PASSWORD } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import ProfileSettings from '../../component/ProfileSettings';


const ChangePassword = props => {

    const simpleValidator = useRef(new SimpleReactValidator());
    const [form_inputs, setInputs] = useState({ old_password: '', password: '', c_password: '' });
    const [, forceUpdate] = useState();
    const [ButtonDisabled, SetButtonDisabled] = useState(false);



    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_CHANGE_PASSWORD, form_inputs, {});
            if (res_data.status) {
                setInputs({ old_password: '', password: '', c_password: '' });
                toast(res_data.message);
                simpleValidator.current.showMessages(false);
                forceUpdate(1);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }


    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    return (
        <>
            <ProfileSettings>
                <form onSubmit={handleSubmit}>
                    <div className='col-sm-12 col-lg-6'>
                        <div className='form-group'>
                            <label>Enter Old Password</label>
                            <input type='password' name="old_password" value={form_inputs.old_password} onChange={handleInputChange} className="form-control" />
                            {simpleValidator.current.message('name', form_inputs.old_password, 'required')}
                        </div>
                        <div className='form-group'>
                            <label>Enter New Password</label>
                            <input type='password' name="password" value={form_inputs.password} onChange={handleInputChange} className="form-control" />
                            {simpleValidator.current.message('password', form_inputs.password, 'required')}
                        </div>
                        <div className='form-group'>
                            <label>Confirm Password</label>
                            <input type='password' name="c_password" value={form_inputs.c_password} onChange={handleInputChange} className="form-control" />
                            {form_inputs.password && simpleValidator.current.message('confirm password', form_inputs.c_password, `required|in:${form_inputs.password}`, { messages: { in: `Passwords don't match.` } })}
                        </div>
                        <div className='text-center mt-5'>
                            <button disabled={ButtonDisabled} className="c-btn-light radius-10 pr-5 pl-5">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin mr-1"></i>} Change Password</button>
                        </div>
                    </div>
                </form>

            </ProfileSettings>
        </>
    );
}
export default withRouter(ChangePassword)

