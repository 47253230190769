// auth
export const POST_LOGIN = "/auth/login"
export const POST_FORGOT_PASSWORD = "/auth/forgot-password"
export const POST_RESET_PASSWORD = "/auth/reset-password"
export const POST_SIGN_UP = "/auth/sign-up"
export const POST_ACCOUNT_VERIFY = "/auth/accounnt-verify"

export const GET_BUSINESS_CATEGORY = "/web/get-business-category"

export const POST_SAVE_BANNER_LOGO = "/profile/save-banner-logo"
export const GET_BUSINESS_PROFILE_DETAILES = "/profile/business-profile-detailes"
export const POST_REMOVE_BANNER_LOGO = "/profile/remove-banner-logo"
export const POST_SAVE_PROFILE = "/profile/save-profile"
export const POST_SAVE_BUSINESS_PROFILE = "/profile/save-business-profile"
export const GET_PROFILE_DETAILES = "/profile/profile-detailes"
export const POST_SAVE_USER_PROFILE = "/profile/save-user-profile"
export const POST_CHANGE_PASSWORD = "/profile/change-password"

export const GET_All_INQUIRY = "/profile/get-all-inquiry"
export const DELETE_INQUIRY = "/profile/remove-inquiry"
export const POST_INQUIRY_REPLY = "/profile/inquiry-reply"
export const POST_INQUIRY_DETAIL = "/profile/get-inquiry-detail"

export const GET_All_REVIEWS = "/profile/get-all-reviews"
export const POST_REVIEWS_DETAIL = "/profile/get-reviews-detail"
export const POST_REVIEWS_REPLY = "/profile/reviews-reply"

export const GET_All_SUBSCRIPTION = "/profile/get-all-subscription"
export const GET_SUBSCRIPTION_RENEW_INFO = "/profile/get-subscription-renew-info"
export const ORDER_SUBSCRIPTION_RENEW = "/profile/subscription-renew"
export const DOWNLOAD_INVOICE = process.env.REACT_APP_API_URL+"web/invoice"

export const ORDER_PLEASE = "/profile/order-please"
export const ORDER_PLEASE_CARD = "/profile/order-please-card"
export const GET_USER_ACTIVE_SUBSCRIPTION = "/profile/get-user-active-subscription"
export const DELETE_GALLARY_IMAGE = "/profile/delete-gallary-image"

export const GET_All_CARDS = "/profile/get-all-cards"
export const CANCEL_CARD = "/profile/cancel-cards"



export const POST_GET_PROFILE = "/web/get-profile"
export const POST_ADD_REVIEW = "/web/add-review"
export const POST_SEND_INQUIRY = "/web/send-inquiry"
export const GET_PRICING = "/web/get-price"
export const GET_REVIEW = "/web/get-review"
export const GET_HOME_DATA = "/web/get-home-data"
export const GET_BLOG_DETAIL = "/web/get-blog-detail"
export const GET_BLOGS = "/web/get-blogs"

export const POST_CONTACT_SEND_INQUIRY = "/web/contact-send-inquiry"



