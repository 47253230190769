import React from 'react';
import { Link } from "react-router-dom";

import Logo from './../assets/images/logo.svg';
import Indeed from './../assets/images/indeed.svg';
import Facebook from './../assets/images/facebook.svg';
import Twitter from './../assets/images/twitter.svg';
import Instagram from './../assets/images/instagram.svg';
import Tiktok from './../assets/images/tiktok.svg';

export default function Header(props) {

    return (
        <>
            {/* <footer className="footer">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-md-12 col-lg-4">
                            <div className="mb-3">
                                <img src={Logo} alt="" className="img-fluid mx-auto logo-size" />
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum</p>
                            <ul className='social-media float-left'>
                                <li><a href='/'><img src={Facebook} alt="Facebook" /></a></li>
                                <li><a href='/'><img src={Instagram} alt="Instagram" /></a></li>
                                <li><a href='/'><img src={Twitter} alt="Twitter" /></a></li>
                                <li><a href='/'><img src={Indeed} alt="Indeed" /></a></li>
                                <li><a href='/'><img src={Tiktok} alt="Tiktok" /></a></li>
                            </ul>
                        </div>
                        <div className='col-md-12 col-lg-3'></div>
                        <div className="col-md-12 col-lg-5">
                            <div className="row mt-4 mt-md-0" >
                                <div className="col-12 col-md-3 mt-3">
                                    <ul className="footer-link">
                                        <div className='mb-3'>
                                            <b>Explore</b>
                                        </div>
                                        <li><Link to="/about">About Us</Link></li>
                                        <li><Link to="/pricing">Pricing</Link></li>
                                    </ul>
                                </div>
                                <div className="col-12 col-md-3 mt-0">
                                    <ul className="footer-link">
                                        <div className='mb-3'>
                                            <b className='text-light'>`</b>
                                        </div>
                                        <li><Link to="/faqs">FAQs</Link></li>
                                        <li><Link to="/contact">Contact Us</Link></li>
                                    </ul>
                                </div>
                                <div className="col-12 col-md-3 mt-3">
                                    <ul className="footer-link">
                                        <div className='mb-3'>
                                            <b>Explore</b>
                                        </div>
                                        <li><Link to="/about">About Us</Link></li>
                                        <li><Link to="/pricing">Pricing</Link></li>
                                    </ul>
                                </div>
                                <div className="col-12 col-md-3 mt-3">
                                    <ul className="footer-link">
                                        <div className='mb-3'>
                                            <b>Explore</b>
                                        </div>
                                        <li><Link to="/about">About Us</Link></li>
                                        <li><Link to="/pricing">Pricing</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </footer> */}



            <footer className="footer">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-md-12 col-lg-4">
                            <div className="mb-3">
                                <img src={Logo} alt="" className="img-fluid mx-auto logo-size" />
                            </div>
                            <p>Collect reviews and boost your business today</p>
                            <ul className='social-media float-left'>
                                <li><a href='https://www.facebook.com/p/Tap-Review-61550209096451/'><img src={Facebook} alt="Facebook" /></a></li>
                                <li><a href='/https://www.instagram.com/tap_reiew_uk/'><img src={Instagram} alt="Instagram" /></a></li>
                                <li><a href='/'><img src={Twitter} alt="Twitter" /></a></li>
                                <li><a href='/'><img src={Indeed} alt="Indeed" /></a></li>
                                <li><a href='/'><img src={Tiktok} alt="Tiktok" /></a></li>
                            </ul>
                        </div>
                        <div className='col-md-12 col-lg-5'></div>
                        <div className="col-md-12 col-lg-3 mt-4 mt-md-0">
                            <div className="row mt-4 mt-md-0" >
                                <div className="col-12">
                                    <ul className="footer-link">
                                        <div className='mb-3'>
                                            <b>Explore</b>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-12 col-md-6">
                                    <ul className="footer-link">
                                        <li><Link to="/about">About Us</Link></li>
                                        <li><Link to="/pricing">Pricing</Link></li>
                                    </ul>
                                </div>
                                <div className="col-12 col-md-6">
                                    <ul className="footer-link">
                                        <li><Link to="/faqs">FAQs</Link></li>
                                        <li><Link to="/contact">Contact Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </footer>
        </>
    );
}