import React, { useState, useEffect, useRef } from 'react';
import { useHistory, withRouter, Link } from "react-router-dom";

import { toast } from 'react-toastify';
import { post } from '../helpers/api_helper';
import { img_view } from '../helpers/Helper';
import { POST_GET_PROFILE, GET_REVIEW } from '../helpers/url_helper';

import Logo from '../assets/images/logo.svg';
import Facebook_Icon from '../assets/images/Facebook.png';
import Instagram_Icon from '../assets/images/Instagram.png';
import Twitter_Icon from '../assets/images/Twitter.png';
import Linkedin_Icon from '../assets/images/Linkedin.svg';

import Loader from "../component/Loader";

const Services = props => {
    let history = useHistory();

    const [ShowLoadMore, seShowLoadMore] = useState(false);
    const [FilterData, SetFilterData] = useState({ profileId: 0, limit: 12, page: 0, search: '' });
    const [UserInfo, SetUserInfo] = useState({});
    const [ReviewList, setReviewList] = useState([]);
    const [ButtonDisabled, SetButtonDisabled] = useState(false);

    const [isLoader, setLoader] = useState(0);
    const [scroll, setScroll] = useState(false)

    useEffect(() => {
        async function get_detaile() {
            window.addEventListener("scroll", () => {
                setScroll(window.scrollY > 100)
            })

            var res_data = await post(POST_GET_PROFILE, { 'slug': props.match.params.slug });
            if (res_data.status) {
                SetUserInfo(res_data.data.user_info);

                FilterData.profileId = res_data.data.user_info.profileId;
                SetFilterData(inputs => ({ ...FilterData, ['profileId']: res_data.data.user_info.profileId }));
                await get_revies();
                setLoader(1);
            } else {
                toast.error(res_data.message);
                history.push("/");
            }
        }
        get_detaile();
    }, [props.match.params.slug])

    const get_revies = async (load_more = 0) => {
        SetButtonDisabled(true);
        FilterData.page = (load_more > 0) ? FilterData.page : 0;
        var res_data = await post(GET_REVIEW, FilterData);
        if (res_data.status) {
            if (load_more > 0) {
                setReviewList([...ReviewList, ...res_data.data.review_list]);
            } else {
                setReviewList(res_data.data.review_list);
            }
            seShowLoadMore(res_data.data.load_more);
            SetFilterData(inputs => ({ ...FilterData, ['page']: res_data.data.page }));
            SetButtonDisabled(false);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    const load_more = async () => {
        get_revies(1);
    }

    return (
        <>
            {!isLoader && <Loader />}
            <header className={`miniwebsite-header ${scroll ? "fix-header" : ""}`}>
                <div className="heade">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-light p-0">
                            <button className="navbar-toggler navbar-toggler-right collapsed mr-3" type="button" data-toggle="collapse"
                                data-target="#Navigation" aria-controls="Navigation" aria-expanded="false"
                                aria-label="Toggle navigation"><i className="fa fa-bars" aria-hidden="true"></i></button>
                            <div className='mobile-logo'>
                                <Link className="navbar-brand" to={`/${UserInfo.url_slug}`}>
                                    {!UserInfo.logo && <img src={Logo} alt="Tap Review" className='logo' />}
                                    {UserInfo.logo && <img src={img_view(UserInfo.logo)} alt="Tap Review" className='logo' />}
                                </Link>
                            </div>
                            <div className="form-inline my-2 my-lg-0">
                                <a href={`/${UserInfo.url_slug}/review`} target="_blank" className='mobile-btn c-btn-blue pt-2 pb-2 radius-5 pointer btn-block pr-3 pl-3 btn-sm'>Leave a Review</a>
                            </div>
                            <div className="collapse navbar-collapse" id="Navigation">
                                <ul className="navbar-nav pt-3 pt-lg-0 ml-auto">
                                    {(UserInfo.services && UserInfo.services?.length > 0) && <li className={`nav-item`}><Link to={`/${UserInfo.url_slug}`} className="text-black">Services</Link></li>}
                                    {ReviewList.length > 0 && <li className={`nav-item`}><Link to={`/${UserInfo.url_slug}`} className="text-black">Reviews</Link></li>}
                                    {(UserInfo.faq && UserInfo.faq?.length > 0) && <li className={`nav-item`}><Link to={`/${UserInfo.url_slug}`} className="text-black">FAQ's</Link></li>}
                                    {UserInfo.ShowContactForm > 0 && <li className={`nav-item`}><Link to={`/${UserInfo.url_slug}`} className="text-black">Contact</Link></li>}
                                    <li><a href={`/${UserInfo.url_slug}/review`} target="_blank" className='c-btn-blue'>Leave a Review</a></li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>

            {ReviewList.length > 0 && <section className='section pt-0 pb-0 review gb_vadadi'>
                {UserInfo.aboutUsImage && <div className='bg_image' style={{ 'background-image': "url('" + img_view(UserInfo.aboutUsImage) + "')" }}></div>}
                <div className='pt-5 pb-5' style={{'backgroundColor': '#ffffff91'}}>
                    <div className="container">
                        <div className='row'>
                            <div className='col-sm-12 text-center mb-2'>
                                <h2 className='font-w-700 text-capitalize'>{UserInfo.businessName} Reviews</h2>
                            </div>
                        </div>
                        <div className='row mb-5 justify-content-md-center'>
                            <div className='col-md-12 col-lg-4'>
                                <div className='text-center mb-3'>
                                    <h3 className='font-w-700'><i className="fa fa-star mr-2" style={{ 'fontSize': '40px', 'color': 'rgb(255, 215, 0)' }} aria-hidden="true"></i> {UserInfo.average_rating}</h3>
                                    <h5 className=''>Overall Rating</h5>
                                </div>
                                {UserInfo.all_over_review && Object.entries(UserInfo.all_over_review).map(([key, review]) => (
                                    <div className='row mb-1' key={key}>
                                        <div className='col-sm-12'>
                                            <h6 className='pull-left '>{review.title}</h6>
                                            <h6 className='pull-right '>{review.review_count}</h6>
                                        </div>
                                        <div className='col-sm-12'>
                                            <div class="progress">
                                                <div class="progress-bar bg-warning" role="progressbar" style={{ 'width': `${review.pre}%` }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='row mb-5 justify-content-md-center'>
                            {ReviewList && Object.entries(ReviewList).map(([key, review]) => (
                                <div className='col-md-12 col-lg-12 mb-3' key={key}>
                                    <div className='review_box'>
                                        <div className="d-flex align-items-center mb-2">
                                            <div className="ml-2">
                                                <h5 className="mb-0">{review.name}</h5>
                                            </div>
                                        </div>
                                        <div className='star mb-2'>
                                            {[...Array(review.rating)].map((x, i) =>
                                                <i className="fa fa-star mr-2" style={{ 'fontSize': '25px', 'color': 'rgb(255, 215, 0)' }} aria-hidden="true"></i>
                                            )}
                                            {[...Array(5 - review.rating)].map((x, i) =>
                                                <i className="fa fa-star mr-2" style={{ 'fontSize': '25px', 'color': '#808080' }} aria-hidden="true"></i>
                                            )}
                                            <small className='pull-right'>{review.date}</small>
                                        </div>
                                        <div>
                                            <p style={{ whiteSpace: "pre-wrap" }}>{review.review}</p>
                                        </div>
                                        {review.reply &&
                                            <div className='row gb_gray pl-2 pr-2 pt-3 radius-10'>
                                                <div className='col-lg-6 mb-2'><b><i className="fa fa-reply mr-1" style={{ 'transform': 'rotate(180deg)' }} aria-hidden="true"></i> Reply from {UserInfo.businessName}</b></div>
                                                <div className='col-lg-6 mb-2 text-sm-right'><b>{review.replyDate}</b></div>
                                                <div className='col-sm-12'>
                                                    <p className='pl-4' style={{ whiteSpace: "pre-wrap" }}>{review.reply}</p>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                            ))}
                        </div>
                        {ShowLoadMore && <div className='row'>
                            <div className="col-sm-12 text-center mt-3">
                                <button onClick={load_more} disabled={ButtonDisabled} className="c-btn-dark ml-3">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>}Load More...</button>
                            </div>
                        </div>}
                    </div>
                </div>
            </section>}

            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-4">
                            <div className="mb-3">
                                {UserInfo.logo && <img src={img_view(UserInfo.logo)} alt="Tap Review" className='logo mb-3' />}
                                {UserInfo.showFooterAddress <= 0 && <div className='mb-2'>
                                    {UserInfo.addressLine1 && <p className='mb-0' style={{ whiteSpace: "pre-wrap" }} >{UserInfo.addressLine1}</p>}
                                    {UserInfo.addressLine2 && <p className='mb-0' style={{ whiteSpace: "pre-wrap" }} >{UserInfo.addressLine2}</p>}
                                    {(UserInfo.town || UserInfo.country || UserInfo.postCode) && <p className='mb-0'><span className='mr-2'>{UserInfo.town}</span> <span className='mr-2'>{UserInfo.country}</span> <span className='mr-2'>{UserInfo.postCode}</span></p>}
                                </div>}
                                <div className='mt-4 mb-4'>
                                    {UserInfo.facebookLink && <a href={UserInfo.facebookLink} target='_blank' className='mr-4'>
                                        <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 1H9C7.67392 1 6.40215 1.52678 5.46447 2.46447C4.52678 3.40215 4 4.67392 4 6V9H1V13H4V21H8V13H11L12 9H8V6C8 5.73478 8.10536 5.48043 8.29289 5.29289C8.48043 5.10536 8.73478 5 9 5H12V1Z" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </a>}
                                    {UserInfo.linkedinLink && <a href={UserInfo.linkedinLink} target='_blank' className='mr-4'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16 8C17.5913 8 19.1174 8.63214 20.2426 9.75736C21.3679 10.8826 22 12.4087 22 14V21H18V14C18 13.4696 17.7893 12.9609 17.4142 12.5858C17.0391 12.2107 16.5304 12 16 12C15.4696 12 14.9609 12.2107 14.5858 12.5858C14.2107 12.9609 14 13.4696 14 14V21H10L10 14C10 12.4087 10.6321 10.8826 11.7574 9.75736C12.8826 8.63214 14.4087 8 16 8V8Z" stroke="#232F61" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M6 9H2L2 21H6L6 9Z" stroke="#232F61" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6Z" stroke="#232F61" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </a>}
                                    {UserInfo.instagramLink && <a href={UserInfo.instagramLink} target='_blank' className='mr-4'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M15.9997 11.3698C16.1231 12.2021 15.981 13.052 15.5935 13.7988C15.206 14.5456 14.5929 15.1512 13.8413 15.5295C13.0898 15.9077 12.2382 16.0394 11.4075 15.9057C10.5768 15.7721 9.80947 15.3799 9.21455 14.785C8.61962 14.1901 8.22744 13.4227 8.09377 12.592C7.96011 11.7614 8.09177 10.9097 8.47003 10.1582C8.84829 9.40667 9.45389 8.79355 10.2007 8.40605C10.9475 8.01856 11.7975 7.8764 12.6297 7.99981C13.4786 8.1257 14.2646 8.52128 14.8714 9.12812C15.4782 9.73496 15.8738 10.5209 15.9997 11.3698Z" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M17.5 6.5H17.51" stroke="#202044" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </a>}
                                    {UserInfo.twitterLink && <a href={UserInfo.twitterLink} target='_blank' className='mr-4'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M22 4.00002C22 4.00002 21.3 6.10002 20 7.40002C21.6 17.4 10.6 24.7 2 19C4.2 19.1 6.4 18.4 8 17C3 15.5 0.5 9.60002 3 5.00002C5.2 7.60002 8.6 9.10002 12 9.00002C11.1 4.80002 16 2.40002 19 5.20002C20.1 5.20002 22 4.00002 22 4.00002Z" stroke="#202044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </a>}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-5">
                            <div className="row">
                                <div className="col-5">
                                    <h5>Company</h5>
                                    <ul className="footer-link">
                                        <li><Link to={`/${UserInfo.url_slug}`} >Services</Link></li>
                                        <li><Link to={`/${UserInfo.url_slug}`} >Reviews</Link></li>
                                    </ul>
                                </div>
                                <div className="col-7">
                                    <h5>Company</h5>
                                    <ul className="footer-link">
                                        <li><Link to={`/${UserInfo.url_slug}`} >About</Link></li>
                                        <li><Link to={`/${UserInfo.url_slug}`}>FAQ's</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-4">
                            {/* {(UserInfo.openingHours && UserInfo.openingHours.length > 0) && <>
                                {Object.entries(UserInfo.openingHours).map(([key, time]) => (
                                    <p key={key} className='mb-0'><span style={{ "minWidth": "100px", "display": "inline-block" }}>{time.day}</span> &nbsp; &nbsp; {time.openTime} - {time.closeTime}</p>
                                ))}
                            </>} */}
                        </div>

                    </div>
                </div>
            </footer>
        </>
    );
}
export default withRouter(Services)
