import React, {useState} from 'react';
import { withRouter } from "react-router-dom";

const Home = props => {
    const [faqsOpen, setFaqsOpen] = useState();
    const faqOpn = (index) => {
        setFaqsOpen(index);
    };
    
    return (
        <>

            <section className="section gb_blue">
                <div className="container">
                    <div className='row mt-5'>
                        <div className='col-sm-12 text-center'>
                            <h2 className='text-white mb-0'>Frequently Asked Questions</h2>
                        </div>
                    </div>
                    <div className='row pt-5 mb-5'>
                        <div className='col-sm-12'>
                            <iframe width="100%" height="500px" style={{ 'borderRadius': '30px' }} src="https://www.youtube.com/embed/86N8OEgMEg0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>

                    <div className='row pt-3'>
                        <div className='col-sm-12'>
                            <div className='faqs mb-3'>
                                <div className={"faq " + (faqsOpen === 1 ? "open" : "")}>
                                    <div className='faq-question' onClick={faqOpn.bind(this,1)}><h5 className='text-white'>How do I use the card?</h5></div>
                                    <div className='faq-answer'>
                                        <p className='text-white'>Got your Tap Review card? Cool! Just hand it over to your customer, and they'll tap it with their phone. Boom, they're on your page ready to drop a review or check out what you do. Need to see how click here! </p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className='faqs'>
                                <div className={"faq " + (faqsOpen === 2 ? "open" : "")}>
                                    <div className='faq-question' onClick={faqOpn.bind(this,2)}><h5 className='text-white'>How do I create my site?</h5></div>
                                    <div className='faq-answer'>
                                        <p className='text-white'>Easy-peasy. Once you're in with your account, you'll see steps to set up your page. Just pop in your details, some cool pics, and what you offer. No tech wizardry needed – promise!</p>
                                    </div>
                                </div>
                            </div>

                            <div className='faqs'>
                                <div className={"faq " + (faqsOpen === 3 ? "open" : "")}>
                                    <div className='faq-question' onClick={faqOpn.bind(this,3)}><h5 className='text-white'>Who uses tap review?</h5></div>
                                    <div className='faq-answer'>
                                        <p className='text-white'>Anyone and everyone! Whether you're fixing sinks, serving coffee, or giving fab makeovers – if you want feedback and a quick spot online, Tap Review's for you.</p>
                                    </div>
                                </div>
                            </div>

                            <div className='faqs'>
                                <div className={"faq " + (faqsOpen === 4 ? "open" : "")}>
                                    <div className='faq-question' onClick={faqOpn.bind(this,4)}><h5 className='text-white'>Where can I post my review link?</h5></div>
                                    <div className='faq-answer'>
                                        <p className='text-white'>Anywhere you fancy! Chuck it on your Facebook, Instagram, or Twitter. Slip it into your emails or even print it on your flyers. The more folks see it, the more feedback you'll get.</p>
                                    </div>
                                </div>
                            </div>

                            <div className='faqs'>
                                <div className={"faq " + (faqsOpen === 5 ? "open" : "")}>
                                    <div className='faq-question' onClick={faqOpn.bind(this,5)}><h5 className='text-white'>How can I get more clients?</h5></div>
                                    <div className='faq-answer'>
                                        <p className='text-white'>Rack up those positive vibes with reviews! Ask your happy peeps to leave feedback and share your page link. The more thumbs-ups you get, the more peeps will wanna check you out. Use all the cool features on Tap Review to show off what you can do!</p>
                                    </div>
                                </div>
                            </div>

                            <div className='faqs'>
                                <div className={"faq " + (faqsOpen === 6 ? "open" : "")}>
                                    <div className='faq-question' onClick={faqOpn.bind(this,6)}><h5 className='text-white'>How does my lead inbox work?</h5></div>
                                    <div className='faq-answer'>
                                        <p className='text-white'>Think of your lead inbox as your chatroom for business. Whenever someone's curious about what you do and drops a message on your page, it pops up there. It's like getting a heads-up every time someone's interested.</p>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}
export default withRouter(Home)