import React, { useState, useEffect } from 'react';
import { useHistory, Link, withRouter } from "react-router-dom";

import { post } from '../../helpers/api_helper';
import { GET_BUSINESS_PROFILE_DETAILES } from '../../helpers/url_helper';
import { toast } from 'react-toastify';

import Loader from "../../component/Loader";

const Review = props => {
    const [isLoader, setLoader] = useState(0);
    const [WidgetUrl1, setWidgetUrl1] = useState('');
    const [Widget1, setWidget1] = useState('');

    const [WidgetUrl2, setWidgetUrl2] = useState('');
    const [WidgetU2, setWidget2] = useState('');

    useEffect(() => {
        async function get_detaile() {
            await get_profile_info();
            setLoader(1);
        }
        get_detaile();
    }, [])

    const get_profile_info = async () => {
        var res_data = await post(GET_BUSINESS_PROFILE_DETAILES, {});
        if (res_data.status) {
            var url = window.location.origin + '/' + res_data.data.detail.url_slug + '/widget?t=1';
            setWidgetUrl1(url);
            setWidget1(`<iframe src="${url}" height="107px" frameBorder="0"></iframe>`);

            var url = window.location.origin + '/' + res_data.data.detail.url_slug + '/widget?t=2';
            setWidgetUrl2(url);
            setWidget2(`<iframe src="${url}" height="175px" frameBorder="0"></iframe>`)

            setLoader(1);
        } else {
            toast.error(res_data.message);
        }
    }

    const copyToClipboard = async (Widget1) => {
        try {
            await navigator.clipboard.writeText(Widget1);
            toast('Copied!');
        } catch (err) {
            toast.error('Failed to copy!');
        }
    };



    return (
        <>
            {!isLoader && <Loader />}
            <section className='section'>
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-sm-6">
                            <h3 className="mb-0">Widget</h3>
                        </div>
                        <div className="col-sm-6 text-right">
                            <Link className="btn c-btn-blue" to="/dashboard"><i className="fa fa-long-arrow-left mr-2 text-white" aria-hidden="true"></i> Back To Dashboard</Link>
                        </div>
                    </div>

                    <div className="row ">
                        <div className='col-sm-6 mb-3'>
                            <textarea className='form-control' disabled rows={3} value={Widget1}>
                            </textarea>
                            <p>Copy this cell and paste onto your page, in Text(HTML) view.</p>
                            <button className='btn btn-sm c-btn-blue-outline radius-10c-btn-block' onClick={() => { copyToClipboard(Widget1) }} style={{ "position": "absolute", "top": "5px", "right": "20px" }}>
                                <i className="fa fa-clone" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div className='col-sm-6 mb-3'>
                            <iframe src={WidgetUrl1} height={'107px'} frameBorder="0"></iframe>
                        </div>
                    </div>

                    <div className="row ">
                        <div className='col-sm-6 mb-3'>
                            <textarea className='form-control' disabled rows={3} value={WidgetU2}>
                            </textarea>
                            <p>Copy this cell and paste onto your page, in Text(HTML) view.</p>
                            <button className='btn btn-sm c-btn-blue-outline radius-10c-btn-block' onClick={() => { copyToClipboard(WidgetU2) }} style={{ "position": "absolute", "top": "5px", "right": "20px" }}>
                                <i className="fa fa-clone" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div className='col-sm-6 mb-3'>
                            <iframe src={WidgetUrl2} height={'175px'} width={'190px'} frameBorder="0"></iframe>
                        </div>
                    </div>
                </div>
            </section >
        </>
    );
}
export default withRouter(Review)

