import React, {useEffect } from 'react';
import { useHistory, withRouter, Link } from "react-router-dom";
import { post } from '../../helpers/api_helper';
import { POST_ACCOUNT_VERIFY } from '../../helpers/url_helper';
import { getToken } from '../../helpers/Helper';
import { toast } from 'react-toastify';


import check from "../../assets/images/check.png";

const Contact = props => {

    let history = useHistory();
    if (getToken()) {
        history.push("/dashboard");
    }

    useEffect(() => {
        async function get_detaile() {
            if(props.match.params.id){
                var res_data = await post(POST_ACCOUNT_VERIFY, {id : props.match.params.id});
                if(!res_data.status){
                    toast.error(res_data.message); 
                    history.push("/"); 
                }
            }else{
                history.push("/"); 
            }
        }
        get_detaile();
    }, [])


    return (
        <>
         <section className='section'>
                <div className="container">
                    <div className='row mb-5 mt-5'>
                        <div className='col-sm-12 text-center'>
                            <img className='mb-5' src={check} />
                            <h2 className='mb-4'>Account verified successfully.</h2>
                            <p>Congratulations, your account has been successfully created.</p>
                            <Link to="/login" className="c-btn-light radius-10 pr-5 mt-5 pl-5 mb-3"> LOG IN</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default withRouter(Contact)

