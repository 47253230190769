import React from "react"

const Loader = props => {
    return (
        <React.Fragment>
            <div className="full_screen_loader">
                <div role="status" className="ms-2 spinner-border text-dark">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Loader
